
import React, { useEffect, useRef, useState } from 'react';

const GooglePlaceWidget = ({ apiKey, apiUrl, formData, setFormData }) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
            if (existingScript) {
                setIsScriptLoaded(true);
                return;
            }

            const script = document.createElement('script');
            script.src = `${apiUrl}api/js?key=${apiKey}&libraries=places`;
            script.async = true;
            script.onload = () => setIsScriptLoaded(true);
            script.onerror = () => console.error("Error loading Google Maps API.");
            document.head.appendChild(script);
        };

        loadGoogleMapsScript();

        return () => {
            const scripts = document.querySelectorAll(`script[src*="maps.googleapis.com"]`);
            scripts.forEach((script) => script.remove());
        };
    }, [apiKey]);

    useEffect(() => {
        if (isScriptLoaded && window.google) {
            const options = {
                fields: ['address_components', 'geometry', 'icon', 'name'],
                types: ['address'],
            };

            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
            autoCompleteRef.current.addListener('place_changed', handlePlaceChanged);
        }
    }, [isScriptLoaded]);

    const handlePlaceChanged = () => {
        const place = autoCompleteRef.current.getPlace();
        if (!place.geometry) return;

        let streetNumber = "";
        let route = "";
        let city = '';
        let state = '';
        let zipCode = '';
        let address_2 = '';
        place.address_components.forEach((component) => {
            if (component.types.includes('street_number')) {
                streetNumber = component.long_name;
            } else if (component.types.includes('route')) {
                route = component.long_name;
            } else if (component.types.includes('locality')) {
                city = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
                state = component.short_name;
            } else if (component.types.includes('postal_code')) {
                zipCode = component.long_name;
            } else if (component.types.includes('subpremise')) {
                address_2 = component.long_name; 
            } else if (component.types.includes('neighborhood')) {
                address_2 = component.long_name; 
            } else if (component.types.includes('sublocality') || component.types.includes('sublocality_level_1')) {
                address_2 = component.long_name; 
            }

        });

        setFormData({
            ...formData,
            address: [streetNumber, route].filter(Boolean).join(" "),
            address2: address_2,
            city: city,
            state: state,
            zip_code: zipCode,
        });
    };

    const handleChange = (field) => (e) => {
        setFormData({
            ...formData,
            [field]: e.target.value,
        });
    };

    const textStyle = {
        color: 'var(--text-tertiary, #4A5365)',
        fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
        fontSize: 'var(--Font-Size-text-xs, 12px)',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'var(--Line-Height-text-xs, 16px)',
    };

    const placeholderStyle = {
        display: 'flex',
        // padding: 'var(--Spacing-md, 8px) var(--Spacing-lg, 12px)',
        padding: '8px 16px 8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: 'var(--Radius-sm, 6px)',
        border: '1px solid var(--border-primary, #EAECF0)',
        background: '#FFF',
        marginTop: 0,
    };


    return (
        <div className='google_place_widget'>
            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                <div style={{ flex: 1 }}>
                    <label style={textStyle}>Address Line 1 *</label>
                    <input
                        type="text"
                        value={formData.address || ''}
                        onChange={handleChange('address')}
                        style={placeholderStyle}
                        placeholder="Street Address"
                        ref={inputRef}
                        className="form-control"
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <label style={textStyle}>Address Line 2 </label>
                    <input
                        type="text"
                        value={formData.address2 || ''}
                        onChange={handleChange('address2')}
                        style={placeholderStyle}
                        placeholder="Apartment, Suite, etc."
                        className="form-control"
                    />
                </div>
            </div>

            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                <div style={{ flex: 1 }}>
                    <label style={textStyle}>City *</label>
                    <input
                        type="text"
                        value={formData.city || ''}
                        onChange={handleChange('city')}
                        style={placeholderStyle}
                        placeholder="City"
                        className="form-control"
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <label style={textStyle}>State *</label>
                    <input
                        type="text"
                        value={formData.state || ''}
                        onChange={handleChange('state')}
                        style={placeholderStyle}
                        placeholder="State"
                        className="form-control"
                    />
                </div>
            </div>

            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                <div className="col-6 pt-2" style={{ marginTop: '16px' }}>
                    <label style={textStyle}>Zip Code *</label>
                    <input
                        type="text"
                        value={formData.zip_code || ''}
                        onChange={handleChange('zip_code')}
                        style={placeholderStyle}
                        placeholder="Zip Code"
                        className="form-control"
                    />
                </div>
            </div>
        </div>
    );
};

export default GooglePlaceWidget;
