import React, { useEffect } from 'react';

const ConductiveWidget = ({ jwtToken }) => {
    useEffect(() => {
        // Ensure the initializer script is loaded
        const script = document.createElement('script');
        script.src = 'https://sandbox.conductiv.co/initializer.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const initProps = {
                jwtToken, 
                onEvent: ({ type, reason }) => {
                    if (type === 'CLOSE') {
                        console.log("closed", reason, type);
                    }
                    console.log("type", type);
                    console.log("reason", reason);
                },
                testing: true
            };
            window.Initializer.init(initProps);
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            
        </div>
    );
};

export default ConductiveWidget;