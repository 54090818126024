import { render } from "react-dom";
import React, {useEffect} from 'react';
import TabComponent from './components/Pagebuilder/tabs';
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams} from 'react-router-dom';
import JsonFormBuilder from './components/Wizard/jsonformbuilder';
import AppEditForm from "./components/Wizard/AppEditForm";
import TabVersionComponent from "./components/Pagebuilder/tab_versions";
import PreviewWizard from "./components/Wizard/previewWizard";
import LoginForm from "./components/Auth/Login/LoginForm";
import SignupForm from "./components/Auth/Signup/SignupForm";
import EmailVerificationOTPForm from "./components/Auth/EmailVerificationOTP/EmailVerificationOTPForm";
import MobileVerificationOTPForm from "./components/Auth/MobileVerificationOTP/MobileVerificationOTPForm"
import Verify2faOTPForm from "./components/Auth/Verify2faOTP/Verify2faOTPForm";
import SetPasswordForm from "./components/Auth/SetPassword/SetPasswordForm";
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import Plans from "./components/Auth/Plans/Plans";
import PaymentPage from "./components/Auth/Payment/PaymentPage";
import { PlanProvider } from './context/PlanContext';


function App() {
  var currentStep = window.currentStepLabel

  // current step for admin app edit flow
  var editAppCurrentStep = window?.non_widget_tabs?.[0]?.title || "";

  if (window.currentStepLabel?.length < 1) {
    currentStep = window.template_labels?.[0]
  }

  function ResetPasswordPage() {
    const { token } = useParams();
    const navigate = useNavigate();
    const resetPasswordError = window.__RESET_PASSWORD_ERROR__;

    useEffect(() => {
      if (resetPasswordError) {
        navigate('/login', { replace: true, state: { resetPasswordError } });
      }
    }, [resetPasswordError, navigate]);

    return resetPasswordError ? null : <ResetPassword token={token} />;
  }

  return (
      <PlanProvider>
        <Router>
          <Routes>
            {/* Login route */}
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup/v1/verify_2fa_otp" element={<Verify2faOTPForm />} />
            <Route path="/signup/v1/forgot_password" element={<ForgotPassword />} />

            <Route path="/signup/v1/reset_password/:token" element={<ResetPasswordPage />} />

            <Route path="/signup" element={<SignupForm />} />
            <Route path="/email_otp" element={<EmailVerificationOTPForm />} />
            <Route path="/verify_mobile_signup" element={<MobileVerificationOTPForm />} />
            <Route path="/signup/v1/set_password" element={<SetPasswordForm />} />
            <Route path="/signup/v1/plans/" element={<Plans />} />
            <Route path="/signup/v1/payment_method/" element={<PaymentPage />} />

            {/* if no label in URL, take to first tab. */}
            <Route key={window.template_labels?.[0]} path={`/application/product/${window.product_referral}_${window.tenant_id}/`}
                  element={<JsonFormBuilder label={window.template_labels?.[0]} session={null}/>}/>
            <Route key={currentStep} path={`/application/${window.product_referral}/${window.appSessionID}/`}
                  element={<JsonFormBuilder label={currentStep} session={window.appSessionID}/>}/>

            {/* app edit flow */}
            <Route key={editAppCurrentStep} path={`/application/edit/${window.product_referral}/${window.appSessionID}/`}
                  element={<AppEditForm label={editAppCurrentStep} session={window.appSessionID}/>}/>

            <Route key={currentStep} path={`/application/preview/${window.product_referral}/`}
                  element={<PreviewWizard label={currentStep}/>}/>
            <Route key={window.version} path={`/page_builder/pagebuilder_version_preview/${window.product_referral}/${window.version}/`}
            element={<TabVersionComponent/>}/>
          </Routes>
        </Router>
      </PlanProvider>
  );
}
  var root = document.getElementById('form-step-1');
    if (root != null)
    {
      render(<App/>, root);
    }

  var tab_component = document.getElementById('tab-component')
  if (tab_component != null)
    {
      render(<TabComponent/>, tab_component)
      // render(<Variables/>, personal_info)
    }


export default App;
