import CryptoJS from 'crypto-js';

// Function to generate a secret key
export const generateKey = () => {
  let hexKey = sessionStorage.getItem('secretKey');

  if (!hexKey) {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    hexKey = Array.from(array).map(byte => byte.toString(16).padStart(2, '0')).join('');
    sessionStorage.setItem('secretKey', hexKey);
  }
  return hexKey;
};

const secretKey = generateKey();


// Encrypt data
export const encryptData = (data) => {
  try {
    const stringifiedData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(stringifiedData, secretKey).toString();
    return encryptedData;
  } catch (error) {
    console.error("Error during encryption:", error);
    return null;
  }
};

// Decrypt data
export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData ? JSON.parse(decryptedData) : null;
  } catch (error) {
    console.error("Error during decryption:", error);
    return null;
  }
};

// Save to sessionStorage
export const saveToSessionStorage = (key, data) => {
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      sessionStorage.setItem(key, encryptedData);
    }
  } catch (error) {
    console.error("Error while saving to sessionStorage:", error);
  }
};

// Get from sessionStorage
export const getFromSessionStorage = (key) => {
  try {
    const encryptedData = sessionStorage.getItem(key);
    return encryptedData ? decryptData(encryptedData) : null;
  } catch (error) {
    console.error("Error while retrieving from sessionStorage:", error);
    return null;
  }
};

// Save to localStorage
export const saveToLocalStorage = (key, data) => {
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      localStorage.setItem(key, encryptedData);
    }
  } catch (error) {
    console.error("Error while saving to localStorage:", error);
  }
};

// Get from localStorage
export const getFromLocalStorage = (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    return encryptedData ? decryptData(encryptedData) : null;
  } catch (error) {
    console.error("Error while retrieving from localStorage:", error);
    return null;
  }
};

// Create DB Router Cookie For Test Mode
export const createDBRouterCookieForTestMode = (test_mode) => {
  let test_mode_val = false
  if (test_mode === true) {
      test_mode_val = true
  }
  let today = new Date();
  let expire = new Date();
  expire.setTime(today.getTime() + 3600000 * 24 * 15);

  document.cookie = `test_mode=${test_mode_val};path=/;expires=${expire.toUTCString()}`;
}