import {useState, useEffect} from 'react';
import * as React from "react";
import axios from 'axios';
import validator from 'validator';
import LoadingSpinner from './loading_spinner';
import { loaddata } from '../../utils/constants';
import { getTestModeCookieValue } from '../../utils/global_function';
import { Alert } from 'react-bootstrap';
import BuilderForm from "./BuilderForm";
import PaymentMethodWidget from "./Widgets/paymentMethod"
import EnerveeLoanOffer from "./Widgets/enerveeLoanOffer";
import InvoiceWidget from './Widgets/invoice';
import ConductiveWidget from './Widgets/conductive';
import GoolgeGeoLocationWidget from './Widgets/google_geo_location';
import GooglePlaceWidget from './Widgets/google_place';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()

function JsonFormBuilder({label, session}) {
    const [builder_schema, setBuilderSchema] = useState({})
    const [formData, setFormData] = useState({});
    const [requiredfields, setRequiredFields] = useState([]);
    const [offerwidget, setOfferWidget] = useState("")
    const [enerveeLoanOffer, setEnerveeLoanOffer] = useState(null)
    const [enerveeLoanOfferWidget, setEnerveeLoanOfferWidget] = useState(false)
    const [PrequalificationOffer, setPrequalificationOffer] = useState("")
    const [agreementwidget, setAgreementWidget] = useState(false)
    const [reviewwidget, setReviewWidget] = useState(false)
    const [approvewidget, setApproveWidget] = useState(false)
    const [declinewidget, setDeclineWidget] = useState(false)
    const [withdrawnWidget, setWithdrawnWidget] = useState(false)
    const [bookedwidget, setbookedwidget] = useState(false)
    const [ischeckbox, setCheckbox] = useState(false)
    const [controls, setControls] = useState([]);
    const [Topcontrols, setTopControls] = useState([]);
    const [documentControl, setDocumentControl] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [flinkwidget, setFlinkWidget] = useState(false);
    const [loanoffers, setLoanOffers] = useState(false);
    const [multiOffers, setMultiOffers] = useState(false);
    const [multiagreements, setMultiAgreements] = useState(false);
    const [prove, setProve] = useState(false);
    const [proveTermsURL, setproveTermsURL] = useState("#");
    const [plaidcrabase, setPlaidCRABase] = useState(false);
    const [plaidcraincome, setPlaidCRAIncome] = useState(false);
    const [populateForm, setPopulateForm] = useState(false);
    const [decisionlogic, setDecisionLogic] = useState(false);
    const [dlRequestCode, setDLRequestCode] = useState("");
    const [paymentMethodWidget, setPaymentMethodWidget] = useState(false);
    const [paymentMethodWidgetData, setPaymentMethodWidgetData] = useState({});
    const [invoiceWidget, setInvoiceWidget] = useState(false);
    const [conductiveWidget, setConductiveWidget] = useState(false);
    const [googleGeoLocationWidget, setGoogleGeoLocationWidget] = useState(false);
    const [googlePlaceWidget, setGooglePlaceWidget] = useState(false);

    const [showForm, setShowForm] = useState(true);




    const [mobileOtpWidget, setMobileOtpWidget] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verifyMobileNumberInput, setverifyMobileNumberInput] = useState('');
    const [plaidcrapartner, setPlaidCRAPartner] = useState(false);
    const [paymentSchedule, setPaymentSchedule] = useState(false);
    const [fieldStates, setFieldStates] = useState([]);
    const [manualProcessingStep, setManualProcessingStep] = useState(false);
    const [chirpVerification, setChirpVerification] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [scrollRequired, setScrollRequired] = useState(false)
    const [controlFormData, setControlFormData] = useState({});
    const [scrolledToBottom, setscrolledToBottom] = useState(null)
    // pay_frequency, pay_date1, pay_date2, pay_week_day1, pay_week_day2
    const [payroll, setPayrollWidget] = useState(false);
    const [payFrequency, setPayFrequency] = useState("");
    const [payDate1, setPayDate1] = useState("");
    const [payDate2, setPayDate2] = useState("");
    const [payWeekDay1, setPayWeekDay1] = useState("");
    const [payWeekDay2, setPayWeekDay2] = useState("");
    const [monthlyPaidStatus, setMonthlyPaidStatus] = useState(false);
    const [is_first_step, setIsFirstStep] = useState(false);
    const applicant_redirect_status = window.applicant_redirect_status === "True" ? true : false;

    // eslint-disable-next-line
    const [isMobile, setIsMobile] = useState(false)

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/get_schema/?product_referral=${window.product_referral}&label=${label}&tenant_code=${window.tenant_code}&session=${window.appSessionID}&test_mode=${test_mode_val}`,
                {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                });


            // check manual processing step and update the state
            var manual_processing_step = response.data.results.template_tab_version.manual_processing_step

            setIsFirstStep(response.data.results.template_tab_version.is_first_tab === 1)
            setManualProcessingStep(manual_processing_step)

            var resp = response.data.results.template_tab_version.builder_schema[0]

            if (resp.widgets) {
                if ("plaid_asset" in resp.widgets)
                {
                    window.plaid_asset(window.appSessionID, window.tenant_code, window.product_type, label, window.product_referral);
                }
                if ("plaid_auth" in resp.widgets)
                {
                    window.plaid_auth(window.appSessionID, window.tenant_code, window.product_type, response.data.results.engine[0].next_step, window.product_referral);
                }
                if ("flinks_and_welspot" in resp.widgets)
                {
                    window.flinks_connect(window.appSessionID, window.tenant_code, window.product_type, response.data.results.engine[0].next_step, window.product_referral);
                    setFlinkWidget(true)
                }
                if ("payment_schedule_widget" in resp.widgets)
                {
                    setPaymentSchedule(true)
                }
                if ("offer_agreement" in resp.widgets) {
                    setOfferWidget(resp.widgets.offer_agreement)
                }
                if ("prequalification_offer" in resp.widgets) {
                    setPrequalificationOffer(resp.widgets.prequalification_offer)
                }
                if ("agreement" in resp.widgets) {
                    setAgreementWidget(true)
                }
                if ("review" in resp.widgets) {
                    setReviewWidget(true)
                }
                if ("resubmit" in resp.widgets) {
                    setReviewWidget(true)
                }
                if ("approve" in resp.widgets && window.product === "10001") {
                    window.location.href = `/applicant/add_debitcard/${window.appSessionID}/`
                }
                else if ("approve" in resp.widgets)
                {
                    setApproveWidget(true)
                }
                if ("decline" in resp.widgets) {
                    setDeclineWidget(true)
                }
                if ("booked" in resp.widgets) {
                    setbookedwidget(true)
                }
                if ("loan_offers" in resp.widgets)
                {
                    setLoanOffers(true)
                }
                if ("enervee_loan_offer_widget" in resp.widgets) {
                    setEnerveeLoanOfferWidget(true)
                }
                if ("payment_method_widget" in resp.widgets) {
                    setPaymentMethodWidget(true)
                }
                if ("multi_offers" in resp.widgets)
                {
                    setMultiOffers(true)
                }
                if ("mobile_otp" in resp.widgets)
                {
                    setMobileOtpWidget(true)
                }
                if ("multi_agreements" in resp.widgets)
                {
                    setMultiAgreements(resp.widgets.multi_agreements)
                }
                if ("payroll_selection_widget" in resp.widgets)
                {
                    setPayrollWidget(true)
                }
                if ("invoice_widget" in resp.widgets)
                {
                    setInvoiceWidget(true)
                }
                if ("conductive_widget" in resp.widgets) {
                    setConductiveWidget(true)
                }
                if ("google_geo_location_widget" in resp.widgets) {
                    setGoogleGeoLocationWidget(true)
                }
                if ("google_place_widget" in resp.widgets) {
                    setGooglePlaceWidget(true)
                }
                if ("plaid_cra_base" in resp.widgets)
                {
                    window.plaid_cra_link(window.appSessionID, response.data.results.engine[0].next_step, window.product_referral);
                    console.log('plaid cra base true')
                    setPlaidCRABase(true)
                }
                if ("plaid_cra_income" in resp.widgets)
                {
                    window.plaid_cra_income_link(window.appSessionID, response.data.results.engine[0].next_step, window.product_referral);
                    console.log('plaid cra income true')
                    setPlaidCRAIncome(true)
                }
                if ("plaid_cra_partner" in resp.widgets)
                {
                    window.plaid_cra_partner_link(window.appSessionID, response.data.results.engine[0].next_step, window.product_referral);
                    console.log('plaid cra Partner true')
                    setPlaidCRAPartner(true)
                }
                if ("chirp_verification" in resp.widgets)
                {
                    window.chirp_request_token_util(window.appSessionID, response.data.results.engine[0].next_step, window.product_referral);
                    console.log('Chirp verification widget true')
                    setChirpVerification(true)
                }
                if ("prove" in resp.widgets)
                {
                    console.log('prove auth url call', resp.widgets['prove'])
                    setProve(true)
                    setproveTermsURL(resp.widgets['prove']['terms_url'])
                }
                if ("decision_logic" in resp.widgets)
                {
                    const response = await axios.get(`/provider/dl_request_code_api/${window.appSessionID}/`,
                    {
                        headers: {
                            Authorization: "Basic " + ApiCredentials
                        },
                    });
                    setDecisionLogic(true)
                    setDLRequestCode(response.data['dl_request_token'])
                }

            }
            let request_doc_ids = []
            if (response.data.results.template_tab_version.controls) {
                setControls(response.data.results.template_tab_version.controls)

                let template_page_control = response.data.results.template_tab_version.controls
                for (let index = 0; index<template_page_control.length; index++)
                {
                    if(template_page_control[index]["request_doc_id"] && template_page_control[index]["request_doc_id"] !== "")
                    {
                        request_doc_ids.push(template_page_control[index]["request_doc_id"])
                    }
                }
            }
            if (response.data.results.template_tab_version.Topcontrols) {
                setTopControls(response.data.results.template_tab_version.Topcontrols)
            }

            axios.get(`/api/v1/get_document_group/?request_doc_ids=${JSON.stringify(request_doc_ids)}&test_mode=${test_mode_val}`,
                {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                // setLoading(false);
                setDocumentControl(response.data.results)

            }).catch(function (error) {
                return false
            });
            setBuilderSchema(response.data.results.template_tab_version.builder_schema[0])
            prePopulateForm(response.data.results.template_tab_version.builder_schema[0])


            // Handle the response data here
        } catch (error) {
            console.log("Error fetching data:", error);
            // window.show_alert(error.response.data.message)
        }
    };

    

    function moveToSpecificStep(stepLabel){
        if(stepLabel) {
            axios.get(`/page_builder/move_to_specific_step/${window.appSessionID}/?step_label=${stepLabel}`,
                {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                // setLoading(false);
                window.location.reload()

            }).catch(function (error) {
                return false
            });
        }
    }


    const handleVerificationCode = (event) => {
        if (event.target.value.length <= 6)
        {
            setVerificationCode(event.target.value);
        }
      };


      function formatPhoneNumber(value) {
        // Remove non-digit characters
        const input = value.replace(/\D/g, "");

        // Format input according to (123) 456 - 7890 pattern
        const areaCode = input.slice(0, 3);
        const firstPart = input.slice(3, 6);
        const secondPart = input.slice(6, 10);

        let formattedNumber = "";
        if (areaCode) {
          formattedNumber += `(${areaCode}`;
        }
        if (firstPart) {
          formattedNumber += `) ${firstPart}`;
        }
        if (secondPart) {
          formattedNumber += ` - ${secondPart}`;
        }

        return formattedNumber;
    }


    // handle mobile otp widget input
    function editMobileNumber(event)
    {
        const formattedValue = formatPhoneNumber(event.target.value);
        setverifyMobileNumberInput(formattedValue);
    }


    function update_UI_elements()
    {
        setTimeout(() => {
            try {

                var mobile_resize_element = document.querySelectorAll('.css-kxu0dz');
                if (window.innerWidth < 720) {
                    Array.from(mobile_resize_element).forEach(function(element) {
                        element.style.setProperty('margin-bottom', '20px', 'important');
                    });
                }
                else {
                    Array.from(mobile_resize_element).forEach(function(element) {
                        element.style.setProperty('margin-bottom', '10px', 'important');
                    });
                }


                var elements = document.querySelectorAll('.MuiIconButton-root');

                // Convert the NodeList to an array and iterate through each element to remove it
                Array.from(elements).forEach(function(element) {
                    // Check the next sibling element for the presence of data-testid="CalendarIcon" attribute
                    var nextSibling = element.children[0];
                    if (nextSibling && nextSibling.getAttribute('data-testid') === 'CalendarIcon') {
                        return;
                    }
                    else if (!element.classList.contains("MuiAutocomplete-popupIndicator")) {
                        element.style.display = 'none';

                    }
                });

                document.getElementById("#/properties/password").querySelector(".MuiFormHelperText-root").style.display = "none";
                var paragraphElement = document.createElement("p");

                // Set the text content of the element for password
                paragraphElement.textContent = "Password must be at least 8 characters, with uppercase, lowercase, number, and special character.";
                paragraphElement.id = "paragraph-hint"
                paragraphElement.classList.add("mb-5");

                if (!document.getElementById("paragraph-hint"))
                {
                    document.getElementById("#/properties/password").appendChild(paragraphElement)
                }
            }
            catch {
                console.log("cross mark not found")
            }

        }, 1);

        setTimeout(() => {
            try {
                // customize field validation error message
                var error_msg_labels = document.querySelectorAll(".MuiFormHelperText-root");


                // Loop through each element
                error_msg_labels.forEach((helperText) => {
                    const parent = helperText.parentElement;
                    const formInputLabel = parent.querySelector('label');
                    const formInputDiv = parent.querySelector('.css-12rx5qu')
                    formInputDiv.classList.remove('Mui-error')
                    formInputLabel.classList.remove('Mui-error')

                    if (!helperText.innerText.includes("must"))
                    {
                        helperText.style.visibility = 'hidden'
                        helperText.style.height = '0px'
                    }
                });

                // Loop through each element
                error_msg_labels.forEach((helperText) => {
                    // Select the parent element
                    const parent = helperText.parentElement;
                    const inputElement = parent.querySelector('input');

                    inputElement.addEventListener('focus', function() {
                        // Perform some action on focus
                        helperText.style.visibility = 'visible'
                    });

                    inputElement.addEventListener('blur', function() {
                        // Perform some action on defocus (blur)
                        helperText.style.visibility = 'visible';
                    });

                    // Add an event listener for the input event (fires when the value changes)
                    inputElement.addEventListener('input', function() {
                        // Perform some action on input
                        helperText.style.visibility = 'visible'
                    });

                    // Select the first child of the parent and get its innerText
                    var firstChildText = parent.firstChild.innerText;
                    firstChildText = firstChildText.replace(/\*/g, '');

                    if (helperText.textContent === "is a required property")
                    {
                        helperText.textContent = firstChildText + "is a required field"
                    }
                });
            }
            catch {
                console.log("required field not found in this page")
            }

        }, 1);
    }



    // Call fetchData on component mount
    useEffect(() => {
        fetchData();
        try {
            document.getElementById('#/properties/password-input').type = 'password';
        } catch {
            console.log('password input not found')
        }
        if (label === "review" || window.appStatusInt === 5) {
            setShowForm(false)
            setReviewWidget(true)
        }
        else if (label === "approve" && window.product === "10001")
        {
            window.location.href = `/applicant/add_debitcard/${window.appSessionID}/`
        }
         else if (label === "approve") {
            setShowForm(false)
            setApproveWidget(true)
        }
        else if (label === "decline") {
            setShowForm(false)
            setDeclineWidget(true)
        }
        else if (label === "booked") {
            setbookedwidget(true)
        }
        else if (label === "withdraw") {
            setWithdrawnWidget(true)
        }

        update_UI_elements()

        var welspotData = {}
        if (populateForm === false && window.product_type === "10001")
            {
                welspotData = createWelspotData()
                setPopulateForm(true)
                setFormData(welspotData)
            }
    // eslint-disable-next-line
    }, []);

    function prePopulateForm(schema) {
        if(!schema.fields){
            return false
        }
        // get all fields that have prePopulate enabled.
        const prePopulateIds = schema.fields.filter(field => field.prePopulate === "true" || (window.back_btn_status === "True" && !is_first_step)).map(field => field.id);
        //create formData for all these fields based on app.data
        let filteredData = prePopulateIds.reduce((obj, id) => {
            if (window.app_data?.[id]) {
                obj[id] = window.app_data[id];
            }
            return obj;
        }, {});
        if (Object.keys(filteredData).length > 0) {
            setFormData(filteredData)
        }
    }

    function scrollToIframeBottom(e, id) {
        e.preventDefault();
        setscrolledToBottom(true)
        var iframe = null
        if (id) {
            iframe = document.getElementById(id);
        } else {
            iframe = document.querySelector('iframe');
        }
        if (iframe) {
            iframe.contentWindow.postMessage("scrollToBottom", "*");
        }
        validateForm()
        document.getElementById("continue_submit_btn").removeAttribute('disabled')

    }


    useEffect(() => {
        /* eslint-disable-next-line  */
        let fieldStates = []
        controls.map(({type, id, required, request_doc_id }) => {
            if (type === "document_upload")
                
                {
                    const matchingDocuments = documentControl.filter(docItem => docItem.request_doc_id === request_doc_id);

                    const newFieldStates = matchingDocuments.map(({ id, name, description }, docIndex) => {
                    const spanRef = document.querySelector(`span[name="request_doc_id${id}"]`);
                    return {
                        id,
                        isFieldRequired: required === 'true' && (!spanRef || !spanRef.innerText),
                        UploadedfileName: spanRef.innerText,
                        UploadAnimation: false,
                    };
                })
                fieldStates.push(...newFieldStates)
            }
        });
        setFieldStates(fieldStates);

    }, [documentControl, controls]);


    function load_data(event)
    {
        event.preventDefault();
        setButtonClicked(true);
        // Store the matched key-value pairs in this object
        var matchedValues = {};
        var fieldsMap = {};

        // Create a map of builder_schema.fields for quick lookup
        if (builder_schema && builder_schema.fields) {
            builder_schema.fields.forEach(field => {
                fieldsMap[field.id] = field;
            });
        }

        // Loop through loaddata and match keys directly with fieldsMap

        var newFormErrors = formErrors
        for (const key in loaddata) {
            if (fieldsMap[key]) {
                matchedValues[key] = loaddata[key];
                // remove the populated fields from formErrors
                const { [key]: removed, ...updatedFormErrors } = newFormErrors;
                newFormErrors = updatedFormErrors

            }
        }
        setFormErrors(newFormErrors);

        setFormData(matchedValues);

    }

    useEffect(() => {
        var elements = document.getElementsByClassName("check-control-iframe");
        var myFunction = function () {
            var attribute = this.getAttribute("data-doc-id");
            alert(attribute);
        };

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', myFunction, false);
        }

    }, []);

    const handleBackButtonClick = async () => {
        document.getElementById('spinner_div').style.display = 'block'
        document.getElementById('form_div').style.display = 'none'

        let submit_buttons = document.querySelectorAll('.hide_submit_button');
        // Loop through each element and perform an operation
        submit_buttons.forEach(function(element) {
            element.style.display = 'none';
        });

        let postData = new FormData();
        postData.append("session", session);

        axios.post("/application/handle_back_button/",
            postData,
            {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            }
        ).then(function (response) {
            window.location.reload();

        }).catch(function (error) {
            window.show_alert(error.response.data.message);
            document.getElementById('spinner_div').style.display = 'none'
            document.getElementById('form_div').style.display = 'block'
            // Loop through each element and perform an operation
            submit_buttons.forEach(function(element) {
                element.style.display = 'block';
            });
        });
    };


    function submitform(e) {
        if (document.getElementById("withdraw_application_button")){
            document.getElementById("withdraw_application_button").style.display = 'none';
        }

        e.preventDefault();
        setShowErrors(true);

        if(!validateForm()){
            return false;
        }

        if (offerwidget && (!document.getElementById("terms-checkbox1").value)) {
            window.show_alert("Please fill all the required fields");
            return false;
        }

        let uploadValidated = true;

        fieldStates.forEach(state => {
            if (state.isFieldRequired && !state.UploadedfileName) {
            uploadValidated = false;
            }
        });
        if(!uploadValidated){
            window.show_alert('Please upload the required documents with an asterisk (*) next to them.');
            return false;
        }

        if(controls){
            var form = document.getElementById('checkbox_controls');
            if(form) {
                var inputs = form.getElementsByTagName('input');
                var is_checked = true;
                for (var x = 0; x < inputs.length; x++) {
                    if (inputs[x].type === 'checkbox' && inputs[x].required && !inputs[x].checked) {
                        is_checked = false;
                        break;
                    }
                }
                if(!is_checked){
                    window.show_alert("Please select all the required checkbox")
                    return false;
                }
            }
            var scrollControl = document.querySelector(".scroll_to_bottom")
            if(scrollControl){
                if(scrollControl.getAttribute("scroll-required") === "true" && !scrolledToBottom){
                    window.show_alert("Please click on scroll down button to move forward")
                    return false;
                }
            }
        }
        if (agreementwidget && !document.getElementById("agreement_checkbox").checked) {
            window.show_alert("Please fill all the required fields");
            setCheckbox(true);
            return;
        } else {
            setCheckbox(false);
        }
        //todo: required fields for builder schema
        // if (schema.length > 0 && schema["required"].length > 0 && !formData) {
        //     window.show_alert("Please fill all the required fields");
        //     return false;
        // }

        if (googleGeoLocationWidget) {
            if (!formData['city'] || !formData['state'] || !formData['zip_code']) {
                window.show_alert("Please fill all the required fields");
                return;
            }
        }

        if (googlePlaceWidget) {
            if (!formData['city'] || !formData['state'] || !formData['zip_code'] || !formData['address']) {
                window.show_alert("Please fill all the required fields");
                return;
            }
        }


        if (formData) {
            try {
                var variable_data = []
                for (let key in formData)
                {
                    if (typeof formData[key] === 'object' && formData[key] !== null) {
                        for (let variable in formData[key])
                        {
                            variable_data.push(variable)
                        }
                    }
                    else {
                        variable_data.push(key)
                    }
                }
                const hasNonEmptyKey =  requiredfields.every(field => variable_data.includes(field));

                if(!hasNonEmptyKey)
                {
                    window.show_alert("Please fill all the required fields");
                    return;
                }
            } catch (error) {
                window.show_alert("Please fix all the below errors");
                return;
            }
        }
        else if(!formData && requiredfields.length > 0)
        {
            window.show_alert("Please fill all the required fields");
            return;
        }


        const selectedOffer = document.querySelector('.selected');
        // Initialize selectedValue as null
        let loan_offer_data = null;

        if (loanoffers && selectedOffer)
        {
            // Retrieve the value of the selected radio button using dataset
            loan_offer_data = JSON.parse(selectedOffer.dataset.loanDetails);
        }
        else if (loanoffers && !selectedOffer) {
            window.show_alert("Please select a loan offer")
            return false
        }


        let selected_offers = []

        if (multiOffers)
        {
            document.querySelectorAll(".selectedoffer").forEach((item) => {

                // Find the iframe element inside the parent of parent
                const selected_offers_data = item.getAttribute("data-loan-details");

                // Check if the iframe exists and log its source (src)
                if (selected_offers_data) {
                    selected_offers.push(selected_offers_data)
                }
            });
            if(selected_offers.length < 1){
                 window.show_alert("Please select at least one offer")
                return false
            }
        }
        if(enerveeLoanOffer){
            selected_offers.push(enerveeLoanOffer)
        }

        try {
            var filled_form_data = {}
            if (formData)
            {
                for (let key in formData)
                {
                    console.log(typeof formData[key], formData[key])
                    if (typeof formData[key] === 'object' && formData[key] !== null) {
                        for (let variable in formData[key])
                        {
                           filled_form_data[variable] = formData[key][variable]
                        }
                    }
                    else {
                        filled_form_data[key] = formData[key]
                    }
                }
            }
            var postData = new FormData();
            var data_doc_ids = []
            const anchorsWithDataDocId = document.querySelectorAll('a[data-doc-id]');
            if (paymentMethodWidget) {
                filled_form_data['account_id'] = paymentMethodWidgetData?.account_id
                filled_form_data['payment_instrument_id'] = paymentMethodWidgetData?.payment_instrument_id || ''
            }


            // Loop through the selected elements
            anchorsWithDataDocId.forEach(anchor => {
            // Access the data-doc-id attribute value for each anchor
            data_doc_ids.push(anchor.getAttribute('data-doc-id'));
            });
            postData.append("data", JSON.stringify(filled_form_data))
            postData.append("tenant_code", window.tenant_code);
            postData.append("consent_data", JSON.stringify(controlFormData));
            postData.append("product_referral", window.product_referral);
            postData.append("tenant_id", window.tenant_id);
            postData.append("label", label);
            postData.append("session", session);
            postData.append("product_type", window.product_type)
            postData.append('blackbox_string',document.getElementById("bb").value);
            postData.append("pagebuilder_version",window.pagebuilder_version)
            postData.append('data_doc_ids',JSON.stringify(data_doc_ids))
            postData.append('loan_offer_data',JSON.stringify(loan_offer_data))
            postData.append('selected_offers_data', JSON.stringify(selected_offers))
            postData.append('pagebuilder_tenant_id', localStorage.getItem("pagebuilder_tenant_id"));
            if ( (offerwidget || multiagreements) && document.getElementById("terms-checkbox1") && document.getElementById("terms-checkbox1").value) {
                postData.append("offer_sign_input", document.getElementById("terms-checkbox1").value);
            }
            postData.append("test_mode",test_mode_val)
            console.log(controlFormData)
            // setLoading(true);
            document.getElementById('spinner_div').style.display = 'block'
            document.getElementById('form_div').style.display = 'none'
            let submit_buttons = document.querySelectorAll('.hide_submit_button');
            // Loop through each element and perform an operation
            submit_buttons.forEach(function(element) {
                element.style.display = 'none';
            });

            const testMode = getTestModeCookieValue() === 'true' || getTestModeCookieValue() === true;
            const pageBuildurl = testMode ? "/application/page_build_ajax/?test_mode=1" : "/application/page_build_ajax/";

            //todo : api call to save tab
            axios.post(pageBuildurl,
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                // setLoading(false);
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'
                submit_buttons.forEach(function(element) {
                    element.style.display = 'block';
                });
                if(window.self !== window.top && window.parent) {
                    window.sendPostMessage("formSubmission", response.data)
                }

                if (response.data.code === 1) {
                    localStorage.setItem("session_id", response.data.session)

                    localStorage.setItem("pagebuilder_tenant_id", response.data.pagebuilder_tenant_id)
                    localStorage.setItem("product_code",response.data.product_referral)
                    const redUrl = testMode ? `/application/${window.product_referral}/${response.data.session}/?test_mode=1` : `/application/${window.product_referral}/${response.data.session}/`;

                    window.location.href = redUrl;
                    // window.show_success('Form saved successfully.')
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg);
                    localStorage.clear();
                    return false
                }
                // handle welspot redirects if flinks is bypassed, LEND-2170
                else if (response.data.code === "bypass_flinks_welpost_review") {
                    window.app_review(response.data.session);
                } else if (response.data.code === "bypass_flinks_welpost_decline") {
                    window.app_decline(response.data.session, true);
                }

            }).catch(function (error) {
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'
                submit_buttons.forEach(function(element) {
                    element.style.display = 'block';
                });

                // setLoading(false);
                window.show_alert("Something went wrong. Please try again");
                return false
            });

        } catch (error) {
            console.log("Error fetching data:", error);
            return false
        }

    }

    function generateRandomId() {
        // This is a simple example, and you might want to use a more robust random number generation method
        return Math.random().toString(36).substring(7);
    }



    function upload_request_doc(event)
    {
        const changedElement = event.target;

        const fileId = Number(event.target.id);
        const fileName = event.target.files[0]?.name;

        // Update the fieldStates
        const updatedFieldStates = fieldStates.map(state =>
        state.id === fileId
            ? { ...state, isFieldRequired: false, UploadedfileName: fileName, UploadAnimation: true }
            : { ...state, UploadAnimation: false }
        );

        setFieldStates(updatedFieldStates);

        let label = changedElement.parentNode.querySelector("label")

        var formData = new FormData()
        formData.append('files', event.target.files[0]);
        formData.append('app_doc_id', label.getAttribute("data-id"))
        formData.append('test_mode', test_mode_val)

        try {

            axios.post(`/application/doc_upload_ajax/${session}/`,
            formData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {

                const updatedFieldStates = fieldStates.map(state =>
                    state.id === fileId
                        ? { ...state, isFieldRequired: false, UploadedfileName: fileName, UploadAnimation: false }
                        : state
                );

                setFieldStates(updatedFieldStates);

                if (response.data.code === 1) {
                    window.show_success("Uploaded successfully")
                } else if (response.data.code < 0) {
                    event.target.value = null
                    window.show_alert(response.data.msg);
                    // localStorage.clear();
                }
            }).catch(function (error) {
                window.show_alert("Something went wrong. Please try again");
                return false
            });

        } catch (error) {
            window.show_alert("Something went wrong. Please try again");
            return false
        }
    }


    function submitapproveform(e) {
        e.preventDefault();

        try {
            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('product_referral', window.product_referral)
            postData.append('test_mode', test_mode_val)

            //todo : api call to save tab
            axios.post("/application/mail_card_submit/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                if (response.data.code === 1) {
                    localStorage.setItem("session_id", response.data.session)
                    // set default redirect
                    var applicant_redirect = `/applicant/dashboard/` + response.data.session + '/';
                    // if there's external redirect configured, use that else default
                    if(response.data.applicant_redirect_url && response.data.applicant_redirect_url.length){
                        applicant_redirect = response.data.applicant_redirect_url
                    }
                    console.log('redd', applicant_redirect)
                    window.location.href = applicant_redirect
                    window.show_success('Form saved successfully.')
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg);
                    localStorage.clear();
                }

            }).catch(function (error) {

                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        }

    }


    function loginSubTenantPortal(e)
    {
        e.preventDefault();

        try {
            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('product_referral', window.product_referral)
            postData.append('test_mode', test_mode_val)

            //todo : api call to save tab
            axios.post("/application/login_subtenant_portal/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                if (response.data.code === 1) {
                    localStorage.setItem("session_id", response.data.session)
                    // set default redirect
                    var redirect_url = `/login`;
                    window.location.href = redirect_url
                    window.show_success('Form saved successfully.')
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg);
                    localStorage.clear();
                }

            }).catch(function (error) {
                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }


    function submitPayroll(e) {
        e.preventDefault();

        try {

            const validations = {
                "weekly": {
                  condition: payWeekDay1 === "",
                  message: "Please Select a week day"
                },
                "bi-weekly": {
                  condition: payWeekDay2 === "",
                  message: "Please Select a week day"
                },
                "monthly": {
                  condition: monthlyPaidStatus && (payDate1 === "" || payDate1 < 1 || payDate1 > 31),
                  message: "Please Select a valid date"
                },
                "semi-monthly": {
                  condition: payDate1 === "" || payDate1 < 1 || payDate1 > 31 || payDate2 === "" || payDate2 < 1 || payDate2 > 31,
                  message: "Please Select a valid date"
                }
            };

            if (validations[payFrequency]?.condition) {
                window.show_alert(validations[payFrequency].message);
                return false;
            }

            let filledFormData = {}
            if (formData)
            {
                for (let key in formData)
                {
                    console.log(typeof formData[key], formData[key])
                    if (typeof formData[key] === 'object' && formData[key] !== null) {
                        for (let variable in formData[key])
                        {
                           filledFormData[variable] = formData[key][variable]
                        }
                    }
                    else {
                        filledFormData[key] = formData[key]
                    }
                }
            }

            document.getElementById('spinner_div').style.display = 'block'
            document.getElementById('form_div').style.display = 'none'

            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('form_data', JSON.stringify(filledFormData));
            postData.append('pay_frequency', payFrequency);
            postData.append('pay_date1', payDate1);
            postData.append('pay_date2', payDate2);
            postData.append('pay_week_day1', payWeekDay1);
            postData.append('pay_week_day2', payWeekDay2);
            postData.append('product_referral', window.product_referral);
            postData.append('test_mode', test_mode_val);

            //todo : api call to save tab
            axios.post("/application/submit_payroll_details/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'
                if (response.data.code === 1) {
                    localStorage.setItem("session_id", response.data.session)
                    window.show_success('Form saved successfully.', 1)
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg);
                    localStorage.clear();
                }

            }).catch(function (error) {
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'
                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            document.getElementById('spinner_div').style.display = 'none'
            document.getElementById('form_div').style.display = 'block'
            console.log("Error fetching data:", error);
        }

    }


    function check_sms_otp(e) {
        e.preventDefault();

        try {
            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('otp', verificationCode)
            postData.append('test_mode', test_mode_val)
            postData.append('product_referral', window.product_referral)

            document.getElementById('spinner_div').style.display = 'block'
            document.getElementById('form_div').style.display = 'none'

            const testMode = test_mode_val === 'true' || test_mode_val === true;

            //todo : api call to save tab
            axios.post("/application/verify_sms_otp/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'

                if (response.data.code === 1) {
                    const redUrl = testMode ? `/application/${window.product_referral}/${window.appSessionID}/?test_mode=1` : `/application/${window.product_referral}/${window.appSessionID}/`;
                    window.location.href = redUrl;
                    window.show_success('Verified successfully.')
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg, 6000);
                }

            }).catch(function (error) {
                document.getElementById('spinner_div').style.display = 'none'
                document.getElementById('form_div').style.display = 'block'
                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        }

    }


    function edit_phone_number(e) {
        e.preventDefault();

        try {
            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('phone_number', verifyMobileNumberInput)
            postData.append('test_mode', test_mode_val)

            //todo : api call to save tab
            axios.post("/application/edit_phone_number/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                if (response.data.code === 1) {
                    window.show_success('SMS sent successfully.', 1)
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg, 6000);
                }

            }).catch(function (error) {

                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        }

    }


    function resend_sms_otp(e) {
        e.preventDefault();

        try {
            var postData = new FormData();
            postData.append("session", window.appSessionID);
            postData.append('test_mode', test_mode_val)

            //todo : api call to save tab
            axios.post("/application/resend_sms_otp/",
                postData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                if (response.data.code === 1) {
                    window.show_success('SMS sent successfully.')
                } else if (response.data.code < 0) {
                    window.show_alert(response.data.msg, 6000);
                }

            }).catch(function (error) {

                window.show_alert("Something went wrong. Please try again");
            });

        } catch (error) {
            console.log("Error fetching data:", error);
        }

    }


    const isEmpty = (obj) => {
        return Object.entries(obj).length === 0;
    };

    function declinealloffers(e)
    {
        e.preventDefault();
        var session_id = window.appSessionID
        window.app_decline(session_id)
    }

    // function to withdraw application
    function withdrawApplication(e)
    {
        e.preventDefault();
        var session_id = window.appSessionID
        window.withdraw_application(session_id)
    }

    function selectCard(cardNumber, event) {
        event.preventDefault();
        // Remove 'selected' class from all cards
        document.querySelectorAll('.card').forEach(card => {
            card.classList.remove('selected');
        });

        // Add 'selected' class to the clicked card
        document.querySelector(`#Loanoffers:nth-child(${cardNumber}) .card`).classList.add('selected');
    }

    function amortizationData(event)
    {
        event.preventDefault()
    }

    function selectofferAgreement(event, offer_type) {
        event.preventDefault();

        if(offer_type && offer_type === 'loan') {
            Array.from(document.querySelectorAll('.personal_loan_div .selectedoffer')).forEach((el) => {
                el.classList.remove('selectedoffer');
                el.innerHTML = 'Select';
            });
        }
        else if(offer_type && offer_type === 'credit_card') {
            Array.from(document.querySelectorAll('.credit_card_div .selectedoffer')).forEach((el) => {
                el.classList.remove('selectedoffer');
                el.innerHTML = 'Select';
            });
        }
        // Check if the button has the selectedoffer class
        if (event.target.classList.contains('selectedoffer')) {
            // If it has, remove the selectedoffer class
            event.target.classList.remove('selectedoffer');
        } else {
            // If it doesn't have, add the selectedoffer class
            event.target.classList.add('selectedoffer');
            event.target.innerHTML = 'Selected <i style="color:green" class="fas fa-check-circle ms-1"></i>'
        }
    }

    function proveWidgetLoaded() {
        var iFrameID = document.getElementById('prove_widget_iframe');
        if (iFrameID) {
            const iframeDocument = iFrameID.contentWindow.document;
            var step1Form = iframeDocument.getElementById('step-1-form');
            var stepWaitingForm = iframeDocument.getElementById('step-waiting-form');
            var step2Form = iframeDocument.getElementById('step-2-form');

            if (step1Form && !step1Form.classList.contains("d-none")) iFrameID.height = '580px';
            if (stepWaitingForm && !stepWaitingForm.classList.contains("d-none")) iFrameID.height = '250px';
            if (step2Form && !step2Form.classList.contains("d-none") && !stepWaitingForm.classList.contains("d-none")) {
                iFrameID.height = '900px';
            }
            else if (step2Form && !step2Form.classList.contains("d-none")) {
                iFrameID.height = '700px';
            }
        }
    }

    // all conditions for form validations to be written in this function.
    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if(builder_schema && builder_schema.fields) {
            builder_schema.fields.forEach(field => {
                /* eslint-disable-next-line  */
                if (field.required && (formData[field.id] == null || formData[field.id] === '')) {
                    errors[field.id] = 'This field is required';
                    isValid = false;
                } else if ((formData[field.id] !== null || formData[field.id] !== '')) {
                    // for number field, min and max.
                    /* eslint-disable-next-line  */
                    if (field.type == 'number') {
                        if (parseInt(formData[field.id]) < parseInt(field.minimum)) {
                            errors[field.id] = 'Minimum value should be ' + field.minimum;
                            isValid = false;
                        } else if (parseInt(formData[field.id]) > parseInt(field.maximum)) {
                            errors[field.id] = 'Maximum value should be ' + field.maximum;
                            isValid = false;
                        }
                    };
                }
                if(field.type =="string" && field.display_type =="4"){
                    if (formData[field.id] && !validator.isEmail(formData[field.id])) {
                        errors[field.id] = 'Enter valid email';
                        isValid = false;
                        setShowErrors(true)
                    }else{
                        errors[field.id] = '';
                    };
                }
            });

        }
        setFormErrors(errors);
        if(scrollRequired){
            if(!scrolledToBottom) {
                document.getElementById("continue_submit_btn").setAttribute('disabled', true)
            }
            else{
                document.getElementById("continue_submit_btn").removeAttribute('disabled')

            }
        }
        return isValid;

    };


    const createWelspotData = () => {
        let welspotData = {
            patient_first_name: window.app_data?.first_name || '',
            patient_last_name: window.app_data?.last_name || '',
            patient_relation: "Self",
            patient_email: window.app_data?.email || '',
            patient_date_of_birth: window.app_data?.birth_date || '',
            patient_ssn: window.app_data?.ssn || '',
            patient_phone_number: window.app_data?.mobile || '',
            patient_address: window.app_data?.address || '',
            patient_address_2: window.app_data?.address_2 || '',
            patient_city: window.app_data?.city || '',
            patient_state: window.app_data?.state || '',
            patient_zip_code: window.app_data?.zip_code || '',
        };
        return welspotData
    };

    useEffect(() => {
      validateForm()

    /* eslint-disable-next-line  */
    }, [formData, scrollRequired]);

    function handleControlChange(e) {

        var value = true;
        setControlFormData((prevData) => ({
            ...prevData,
            [e.target.id]: value,
        }));
    }


    function handleChange(e, value) {
        const fileInputValues = {};
        document.querySelectorAll('.file_name').forEach((input) => {
            fileInputValues[input.getAttribute("name")] = input.innerText;
          });

        if (e.target.type === "number")
        {
            value =  parseFloat(value);
        }

        setFormData((prevData) => ({
            ...prevData,
            [e.target.id]: value,
        }));

        var welspotData = {}
        var relationData = {}
        if (e.target.id === "patient_relation" && window.product === "10001") {
            if (value && populateForm === false) {
                if (value === "Self") {
                    // fetch welspot data
                    welspotData = createWelspotData()
                }
                setPopulateForm(true)
                setFormData(welspotData)
            } else if (populateForm === true) {
                if (value !== "Self") {
                    relationData = {
                        patient_relation: value,
                    };
                    setPopulateForm(false)
                    setFormData(relationData)
                }
            }
        }

        var required_data = []
        setRequiredFields(required_data)

        var continue_btn = document.getElementById("continue_submit_btn")
        if (e.errors && e.errors.length > 0 && !offerwidget && !multiagreements && builder_schema && builder_schema.fields.length > 0) {
            if (continue_btn)
            {
                continue_btn.setAttribute("disabled",true)
            }
        } else {
            if (continue_btn)
            {
                continue_btn.removeAttribute("disabled",true)
            }
        }
        validateForm()

        try {
            Object.keys(fileInputValues).forEach((fieldName) => {
                const inputValue = fileInputValues[fieldName];
                document.querySelector(`span[name=${fieldName}]`).innerText = inputValue;
              });
        } catch (error) {
            console.log('Something went wrong')
        }
    }


    // handle payroll widget
    function handlePayFrequency(event)
    {
        setPayFrequency(event.target.value)
    }

    function handleWeekDay(event, param)
    {
        if (param === "weekly")
        {
            setPayWeekDay1(event.target.value)
            setPayWeekDay2('')
        }
        else{
            setPayWeekDay2(event.target.value)
            setPayWeekDay1('')
        }
    }

    function handlePayDate1(event)
    {
        setPayDate1(event.target.value)
    }

    function handlePayDate2(event)
    {
        setPayDate2(event.target.value)
    }


    function handleMonthlyPaidStatus(event)
    {
        if (event.target.value === "1")
        {
            setMonthlyPaidStatus(true)
        }
        else {
            setMonthlyPaidStatus(false)
        }
    }


    return (
        <>

                        <div className="modal fade" id="modal_edit_number" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content p-3">
                                <div className="modal-header">
                                    <h4 className="modal-title">Update Mobile Number</h4>
                                    <button type="button" className="btn-close fs-3 text-dark fs-3"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div
                                        className="w-100 doc-group-select">

                                        <form id="add_document_form">

                                            <div className="input-group mt-4">
                                                <label htmlFor="documents" className="mb-2" style={{"display": "block"}}>Mobile Number</label>

                                                <input type="text"
                                                    value={verifyMobileNumberInput}
                                                    onChange={editMobileNumber}
                                                    className="form-control p-2 border rounded verification_code"
                                                    id="verify_mobile_number_id" aria-label="OTP Input Field, required field"
                                                    placeholder="(XXX) XXX - XXXX" name="verify_mobile_number_id"/>
                                            </div>

                                            <div className="mt-4 d-flex justify-content-between">
                                                <button type="button" className="btn btn-secondary btn-secondary-sm"
                                                        data-bs-dismiss="modal">Close
                                                </button>
                                                <button type="button" className="btn btn-primary btn-primary-sm" onClick={edit_phone_number}
                                                        id="send_otp">Send OTP
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
            <div id="spinner_div" style={{display: 'none'}}><LoadingSpinner/></div>
            {showForm ?
            <div id="form_div" className="App">
                {parseInt(window.product) === 6 && !isEmpty(builder_schema) ? <button onClick={load_data} className='btn btn-primary btn-primary-sm me-4 load-data' style={{float:'right',border:'1px solid'}}>Load data</button> : ""}

                {Topcontrols.length > 0 ? (
                    <div className="col-12 checkbox-controls" id="label_controls">
                        {/* eslint-disable-next-line */}
                        {Topcontrols.map(({id, name, text, type, required, label_font_size, label_alignment}, index) => {
                            if (type === "label" || type === "toplabel") {
                                return (
                                    <div key={index} className={`row w-100 mx-auto ${mobileOtpWidget ? "ms-3" : ""}`}>
                                        <div className="p-0" style={{marginBottom: "20px",textAlign:label_alignment}}>
                                            {index === 0 ? (
                                                <h3
                                                    name="label_control"
                                                    style={{fontSize: label_font_size + "px", lineHeight: "inherit"}}
                                                    id={'label_control' + index}
                                                    type="label"
                                                    data-text={text}
                                                    label-font-size={label_font_size}
                                                    label-alignment={label_alignment}
                                                    required={required}
                                                    className="col-auto m-0 drag-drop-element"
                                                    htmlFor={'label_control' + index}
                                                    dangerouslySetInnerHTML={{__html: text}}
                                                />
                                            ) : (
                                                <p
                                                    name="label_control"
                                                    style={{fontSize: label_font_size + "px", lineHeight: "inherit", fontWeight:900}}
                                                    id={'label_control' + index}
                                                    type="label"
                                                    data-text={text}
                                                    label-font-size={label_font_size}
                                                    label-alignment={label_alignment}
                                                    required={required}
                                                    className="col-auto m-0 drag-drop-element"
                                                    htmlFor={'label_control' + index}
                                                    dangerouslySetInnerHTML={{__html: text}}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            else  if (type === "rich_text_editor" || type === "rich_text_editor_top_control") {
                                return (
                                    <div className='rich-text-editor-control mt-3' key={index} dangerouslySetInnerHTML={{ __html: text }}
                                    />
                                );
                            }
                            return null; // Return null for other cases
                        })}

                    </div>
                ) : null}

                {['review', 'decline', 'approve', 'booked'].indexOf(label) < 0 && manualProcessingStep === false && builder_schema && builder_schema.fields  ?
                     <BuilderForm builderSchema={builder_schema} onChange={handleChange} showErrors={showErrors} setFormErrors={setFormErrors} formErrors={formErrors} formData={formData} loadDataBtnStatus={buttonClicked}></BuilderForm>
                    : <div></div>}

                {
                    offerwidget.length > 0 ?
                        <div>
                            <div className="w-100 my-3">
                                <div className="col-12">
                                    {/* eslint-disable-next-line  */}
                                    {/* <a style={{ float: 'right', marginRight: '15px' }} href="#" onClick={(e) => window.downloadPDF(offerwidget, 'offer.pdf')}>Download</a> */}
                                    <iframe title="offer_widget_title" id="" className="shadow my-2" style={{height: "55vh", width: "100%", border: "1px solid #ccc"}}
                                            src={"/application/offer_pdf_display/" + localStorage.getItem('product_code') + '/1/' + window.appSessionID + ((test_mode_val === true || test_mode_val === "true") ? "&test_mode=1" : "")}></iframe>
                                </div>
                            </div>
                            <div className="row g-mb-20 mt-2">

                                <div className="form-field name-div form-group w-50">
                                    <label className="form-label" htmlFor='terms-checkbox1'>Enter your name for
                                        signature &nbsp;<span>*</span></label>
                                    <input type="text" className="form-control signup"
                                           placeholder=""
                                           name="terms-checkbox1" id="terms-checkbox1"></input>
                                </div>
                            </div>
                        </div> : <div></div>
                }

                {
                    PrequalificationOffer.length > 0 ?
                        <div>
                            <div className="row my-3">
                                <div className="col-12">
                                    <iframe title="offer_widget_title" id="" className="shadow" height="500px" width="100%"
                                            src={"/application/offer_pdf_display/" + localStorage.getItem('product_code') + '/3/' + window.appSessionID + ((test_mode_val === true || test_mode_val === "true") ? "&test_mode=1" : "")}></iframe>
                                </div>
                            </div>
                        </div> : <div></div>
                }

{
    multiOffers ? (
        <div>
            {(window.multi_offer.credit_card.length === 0 && window.multi_offer.personal_loan.length === 0) ? (
                <Alert variant="light" className="text-center py-5">
                    <div className="max-width-400 mx-auto p-4 border rounded">
                        <h3>No offers available</h3>
                        <p className="text-muted">Unfortunately, we do not have any offers available for you.</p>
                    </div>
                </Alert>
            ) : (
                <div>
                    {window.multi_offer.credit_card && window.multi_offer.credit_card.length > 0 ? (
                        <div className='container border p-0 pb-5 credit_card_div'>
                            <div className="modal-header p-3 px-5" style={{"background": "#ebeef4"}}><h4>Credit Card</h4></div>
                            <div className="row px-5" style={{textAlign: 'initial'}}>
                                {window.multi_offer.credit_card.map((data, index) => (
                                    <div className="col-md-6 mb-4" id="MultiLoanOffer" key={index}>
                                        <div className="border rounded mt-lg-5 border-primary" data-loan-details={JSON.stringify(data)}>
                                            <div className="card-body mt-2">
                                                <div className="text-bold text-center fs-4 text-primary" style={{fontWeight: 'bold', fontSize: 'larger'}}>
                                                    Credit Card Offer {index + 1}
                                                </div>
                                                <div className="mt-3 text-dark mb-lg-4">
                                                    <div className='col-lg-12'>
                                                        <strong style={{float: 'left'}}>Credit Line: </strong>
                                                        <span className="ms-2">${data.credit_line ? data.credit_line : 0}</span>
                                                    </div>
                                                    {data.apr !== undefined && (
                                                        <div className='col-lg-12'>
                                                            <strong style={{float: 'left'}}>APR: </strong>
                                                            <span className="ms-2">{data.apr ? Number(data.apr * 100).toFixed(2) : 0}%</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex mt-4">
                                                <button
                                                    style={{float: 'right', border: '1px solid'}}
                                                    className="btn btn-primary btn-primary-sm w-90 mx-auto text-capitalize"
                                                    type="button" data-loan-details={JSON.stringify(data)} onClick={(event) => selectofferAgreement(event, 'credit_card')}
                                                >
                                                    Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {window.multi_offer.personal_loan && window.multi_offer.personal_loan.length > 0 ? (
                        <div className='container border p-0 pb-5 personal_loan_div'>
                            <div className="modal-header p-3 px-5" style={{"background": "#ebeef4"}}><h4>Personal Loan</h4></div>
                            <div className="row px-5" style={{textAlign: 'initial'}}>
                                {window.multi_offer.personal_loan.map((data, index) => {
                                    if (data["is_loan_amount"]) {
                                        return null;
                                    }

                                    let columnSize;
                                    if (window.multi_offer.personal_loan.length === 1) {
                                        columnSize = "col-md-6 mb-4";
                                    } else if (window.multi_offer.personal_loan.length === 2) {
                                        columnSize = "col-md-6 mb-4";
                                    } else {
                                        columnSize = "col-md-4 mb-4";
                                    }

                                    return (
                                        <div className={columnSize} id="MultiLoanOffer" key={index}>
                                            <div className="border rounded mt-lg-5 border-primary" data-loan-details={JSON.stringify(data)}>
                                                <div className="card-body mt-2">
                                                    <div className="text-bold text-center fs-4 text-primary" style={{fontWeight: 'bold', fontSize: 'larger'}}>
                                                        Loan Offer {index + 1}
                                                    </div>
                                                    <div className="mt-3 text-dark mb-lg-4">
                                                        <div className='col-lg-12'>
                                                            <strong style={{float: 'left'}}>Approved Amount: </strong>
                                                            <span className="ms-2">${data.approved_amount ? data.approved_amount : 0}</span>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <strong style={{float: 'left'}}>APR: </strong>
                                                            <span className="ms-2">{(data.apr)  ? (data.apr* 100).toFixed(2) : 0}%</span>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <strong style={{float: 'left'}}>Loan Term: </strong>
                                                            <span className="ms-2">{data.loan_term ? data.loan_term : 0} months</span>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <strong style={{float: 'left'}}>Monthly Payment: </strong>
                                                            <span className="ms-2">
                                                                ${isNaN(parseFloat(data.monthly_payment))
                                                                    ? '0.00'
                                                                    : parseFloat(data.monthly_payment).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-4">
                                                    <button
                                                        style={{float: 'right', border: '1px solid'}}
                                                        className="btn btn-primary btn-primary-sm w-90 mx-auto text-capitalize"
                                                        data-loan-details={JSON.stringify(data)}
                                                        onClick={(event) => selectofferAgreement(event, 'loan')}
                                                    >
                                                        Select
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    ) : (
        <div></div>
    )
}


                {
                    window.app_data && window.app_data.multi_agreements && typeof window.app_data.multi_agreements === 'object' && window.app_data.multi_agreements !== null && multiagreements ?
                        <div>
                            <div className="row mt-3">
                            {
                                Object.keys(window.app_data.multi_agreements).map(key => (
                                    <React.Fragment key={key}>
                                        <h5>{Number(key) === 1 ? "Credit Card" : "Personal Loan"}</h5>
                                        <div className="col-lg-12 mb-3">
                                            <iframe title={`offer_widget_${key}`} className="shadow" height="500px" width="100%"
                                                    src={window.app_data.multi_agreements[key]}></iframe>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                            </div>

                            <div className="row g-mb-20 mt-2">

                                <div className="input-group name-div mb-4 mt-5 form-group w-50">
                                    <label className="form-label" htmlFor='terms-checkbox1'>Enter your name for
                                        signature &nbsp;<span>*</span></label>
                                    <input type="text" className="form-control multisteps-form__input signup"
                                           placeholder=""
                                           name="terms-checkbox1" id="terms-checkbox1"></input>
                                </div>
                            </div>
                        </div> : null
                }


                {
                    agreementwidget ? <>
                        <div className="d-flex justify-content-center align-items-baseline mt-3 ms-5">
                            <div className="col-1 z-index-1">
                                <input type="checkbox" id="agreement_checkbox" placeholder=""
                                       className='mt-n1 me-3 drag-drop-element'></input>
                            </div>
                            <div className="col-3 ms-n5">
                                <p>{window.agreement_text}</p>
                            </div>
                            <div className="col-6 ms-n3 d-flex justify-content-between" style={{marginTop: "-3px"}}>
                                {window.consent1_url ? <a className='text-primary'
                                                          href={window.consent1_url}>{window.consent1_title}</a> : <></>}
                                {window.consent2_url ? <a className='text-primary'
                                                          href={window.consent2_url}>{window.consent2_title}</a> : <></>}
                                {window.consent3_url ? <a className='text-primary'
                                                          href={window.consent3_url}>{window.consent3_title}</a> : <></>}
                                {window.consent4_url ? <a className='text-primary'
                                                          href={window.consent4_url}>{window.consent4_title}</a> : <></>}
                                {window.consent5_url ? <a className='text-primary'
                                                          href={window.consent5_url}>{window.consent5_title}</a> : <></>}
                            </div>
                        </div>
                        {ischeckbox ?
                            <div className='mt-n3 mb-5'><span className='text-danger'>Please accept the terms and conditions</span>
                            </div> : <span></span>}</> : <div></div>
                }

                {
                    manualProcessingStep ?
                        <div class="col-12 mx-auto">
                            <div class="">
                                <div class="d-flex justify-content-center">
                                    <form id="form-step-9" class="form-step" data-process-status="Income"
                                          data-process-percent="100">

                                        <header className='text-center'>
                                            <i className="fa fa-clock fs-18 mr-3" aria-hidden="true"
                                               style={{color: " #f2ca16", fontSize: "5rem"}}></i>
                                            <h2 class="header-title text-center mt-4">In Process</h2>
                                        </header>
                                        <div class="row mb-5 text-center d-flex mx-auto">
                                        Your application is being processed. Thank you for your patience.
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <div></div>
                }
                {
                    plaidcrabase ?
                    <div class="col-12 mx-auto">
                    <div class="border rounded">
                        <div class="d-flex justify-content-center">
                        <form>

                                <header>
                                    <h2 class="header-title text-center">Plaid</h2>
                                </header>
                                <div class="row mb-5 text-center">
                                    Calling Plaid Income provider...
                                </div>
                            </form>
                        </div>
                    </div>
                    </div> : <div></div>
                }
                {
                    plaidcraincome ?
                    <div class="col-12 mx-auto">
                    <div class="border rounded">
                        <div class="d-flex justify-content-center">
                        <form>

                                <header>
                                    <h2 class="header-title text-center">Plaid</h2>
                                </header>
                                <div class="row mb-5 text-center">
                                    Calling Plaid provider...
                                </div>
                            </form>
                        </div>
                    </div>
                    </div> : <div></div>
                }
                {
                    plaidcrapartner ?
                    <div class="col-12 mx-auto">
                    <div class="border rounded">
                        <div class="d-flex justify-content-center">
                        <form>

                                <header>
                                    <h2 class="header-title text-center">Plaid</h2>
                                </header>
                                <div class="row mb-5 text-center">
                                    Calling Plaid provider...
                                </div>
                            </form>
                        </div>
                    </div>
                    </div> : <div></div>
                }
                {
                    decisionlogic ?
                    <div class="col-12 mx-auto">
                    <div class="border rounded">
                        <div class="d-flex justify-content-center">
                        <form>

                                <header>
                                    {/* <h2 class="header-title text-center">Decision Logic</h2> */}
                                </header>
                                <div class="row mb-5 text-center">
                                    <div id="widgetHeight" class="widget_section" style={{height:"614px"}}>
                                        <widget-ui id="widget3" request_code={dlRequestCode} widget_mode="LIVE"></widget-ui>
                                        <div className="text-center"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div> : <div></div>
                }
                {
                    withdrawnWidget ?
                        <div class="col-12 mx-auto">
                            <div class="">
                                <div class="d-flex justify-content-center">
                                    <form id="form-step-9" class="form-step" data-process-status="Income"
                                          data-process-percent="100">

                                        <header className='text-center'>
                                            <i className="fa fa-circle-xmark fs-18 mr-3" aria-hidden="true"
                                               style={{"color": "red", "font-size": "5rem"}}></i>

                                            <h2 class="header-title text-center mt-4">Withdrawn</h2>
                                        </header>
                                        <div class="row mb-5 text-center d-flex mx-auto">
                                            {window.withdraw_text.length > 0 ? window.withdraw_text : 'Sorry! Your application is Withdrawn.'}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <div></div>
                }
                {
                    bookedwidget ?
                        <div class="col-12 mx-auto">
                            <div class="">
                                <div class="d-flex justify-content-center">
                                    <form id="form-step-9" class="form-step" data-process-status="Income"
                                          data-process-percent="100">

                                        <header className='text-center'>
                                        <i className="fa fa-clock fs-18 mr-3" aria-hidden="true"
                                               style={{"color": " #f2ca16", "font-size": "5rem"}}></i>

                                            <h2 class="header-title text-center mt-4 d-flex mx-auto">Booked</h2>
                                        </header>
                                        <div class="row mb-5 text-center">
                                        Your application is Booked
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <div></div>
                }
                {
                    prove ?
                        <div class="mx-auto">
                            <div class="">
                                <div class="d-flex justify-content-center">
                                        <div class="row mb-1 text-center d-flex mx-auto w-100 h-100">
                                            <iframe title="prove_widget" id="prove_widget_iframe" className="" width="100%"  style={{ padding: "0" }}
                                                    onLoad={proveWidgetLoaded}
                                                    src={"/provider/prove_widget/" + window.appSessionID + '?terms_url=' + proveTermsURL + '&product_code=' + localStorage.getItem('product_code') + ((test_mode_val === true || test_mode_val === "true") ? "&test_mode=1" : "")}></iframe>
                                        </div>
                                </div>
                            </div>
                        </div> : <div></div>
                }
                {
                    payroll ?
                        <div class="col-12 mx-auto">
                            <div class="mb-3">
                                <label for="mainSelection" className="d-block">How often do you get paid?</label>
                                <select id="mainSelection" className="border rounded w-75 p-2" onChange={handlePayFrequency}>
                                    <option value="">-- Select an Option --</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="bi-weekly">Bi-Weekly</option>
                                    <option value="semi-monthly">Semi-monthly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </div>

                            <div class={`mb-3 mt-5 ${payFrequency === 'weekly' ? "" : 'd-none'}`}>
                                <label for="mainSelection" class="">What day of the week are you normally paid?</label>
                                <select id="mainSelection" class="border rounded w-75 p-2" onChange={(e) => handleWeekDay(e, payFrequency)}>
                                    <option value="">-- Select an Option --</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </select>
                            </div>

                            <div class={`mb-3 mt-5 ${payFrequency === 'bi-weekly' ? "" : 'd-none'}`}>
                                <label for="mainSelection" class="">What day of the week are you normally paid?</label>
                                <select id="mainSelection" class="border rounded w-75 p-2" onChange={(e) => handleWeekDay(e, payFrequency)}>
                                    <option value="">-- Select an Option --</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </select>
                            </div>

                            <div class={`mb-3 mt-5 ${payFrequency === 'semi-monthly' ? "" : 'd-none'}`}>
                                <label for="mainSelection" class="">What dates of the month are you normally paid?</label>
                                <div className='row mt-2'>
                                    <div className="col-md-6">
                                        <div className='mb-3'>
                                            <label className=''>First Date</label>
                                            <select className='form-control rounded border p-2 mt-0' onChange={handlePayDate1}>
                                            <option value="">-- Select an Option --</option>
                                                {Array.from({ length: 31 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='mb-3'>
                                            <label className=''>Second Date</label>
                                            <select className='form-control rounded border p-2 mt-0' onChange={handlePayDate2}>
                                            <option value="">-- Select an Option --</option>
                                                {Array.from({ length: 31 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class={`mb-3 mt-5 ${payFrequency === 'monthly' ? "" : 'd-none'}`}>
                                <label for="mainSelection" class="">Do you get paid on a specific date?</label>
                                <div className='row mt-2 mb-3 ms-1 w-75'>
                                    <select className='form-control rounded border p-2 mt-0' onChange={handleMonthlyPaidStatus}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>

                            <div class={`mb-3 mt-5 ${monthlyPaidStatus ? "" : 'd-none'}`}>
                                <label for="mainSelection" class="">What date are you normally paid?</label>
                                <div className='row mt-2'>
                                    <div className='mb-3'>
                                        <label className=''>Pay Date</label>
                                        <select className='form-control rounded border p-2 mt-0' onChange={handlePayDate1}>
                                        <option value="">-- Select an Option --</option>
                                            {Array.from({ length: 31 }, (_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                {i + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div> : <div></div>
                }
                {
                    mobileOtpWidget ?
                    <div className="row">
                        <div className={`col-12 mx-auto card_body_class ${mobileOtpWidget ? "" : "mb-5"}`}>
                            <div className="">
                                <div className="">
                                    <form id="form-step-13">
                                        <div className="row mt-3">
                                            <div className="col-md-12 col-12">
                                                <p className="email_body">Please verify your Mobile. We’ve sent {window.mobile_num ? window.mobile_num.slice(0, -4) + "*" : "XXXX"} an OTP. Please enter the code below:</p>
                                            </div>
                                        </div>
                                        <div className="row g-mb-20">
                                            <div className="col-sm-12 w-100 input-group input-group-static">
                                                <label className=" d-none email_label">Email</label>
                                                <p className="email_id" data-bs-toggle="modal"
                                        data-bs-target="#modal_edit_number"  style={{color:'#6f4bd1',cursor:'pointer'}}>Edit Number</p>
                                            </div>
                                        </div>
                                        <div className="row g-mb-20 code_verify">
                                            <div className="form-field col-sm-4">
                                                <label className="form-label mt-n3">6-digit code &nbsp;<span>*</span></label>
                                                <input type="number"
                                                    value={verificationCode}
                                                    onChange={handleVerificationCode}
                                                    className="form-control p-2 border rounded verification_code"
                                                    id="verification_code" aria-label="OTP Input Field, required field"
                                                    placeholder="" name="verification_code"/>
                                            </div>
                                        </div>
                                        </form>
                                            <div className={`footer mt-n3 d-flex w-lg-100 ${mobileOtpWidget ? "" : "justify-content-center"}`} style={{fontSize: "0.85rem"}}>
                                                <strong>Didn't get the code? <span style={{cursor:'pointer',color:'#6f4bd1'}} onClick={resend_sms_otp} class="text-info font-weight-bold resend-otp">Resend OTP</span></strong>
                                            </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div></div>
                }
                {
                    loanoffers ?
                        <div className='container'>
                        <div class="row" style={{textAlign:'initial'}}>


                        {window.loan_offers['Loan Details'] ? Object.keys(window.loan_offers['Loan Details'][0]).map((offerKey, index) => {

                        const offerDetails = window.loan_offers['Loan Details'][0] && window.loan_offers['Loan Details'][0][offerKey] ? window.loan_offers['Loan Details'][0][offerKey]['Loan Details'] : {};

                        const loan_details = window.loan_offers['Loan Details'][0][offerKey] ?  (window.loan_offers['Loan Details'][0][offerKey]) : {}


                        let columnSize;
                        if (Object.keys(window.loan_offers['Loan Details'][0]).length === 1)
                        {
                            columnSize = "col-md-12 mb-4";
                        }
                        else if (Object.keys(window.loan_offers['Loan Details'][0]).length === 2) {
                            columnSize = "col-md-6 mb-4";
                        }
                        else {
                            columnSize = "col-md-4 mb-4";
                        }

                        return (
                            <>

                            <div class= {columnSize} id="Loanoffers" key={index}>
                            <div class="border rounded mt-lg-5 border-primary" data-loan-details={window.loan_offers['Loan Details'][0][offerKey] ?  JSON.stringify(window.loan_offers['Loan Details'][0][offerKey]) : {}}>
                                        <div class="card-body mt-2">
                                            <div class="text-bold fs-4 text-primary" style={{fontWeight:'bold',fontSize:'larger'}}>
                                                Loan Offer {index+1}
                                            </div>
                                            <div class="mt-3 text-dark mb-lg-4">
                                                <div className='col-lg-12 d-flex justify-content-between'><strong style={{float:'left'}}>Term Length </strong><span className="ms-2">{offerDetails['Term Length']} months</span></div>


                                                <div className='col-lg-12 d-flex justify-content-between'>
                                                    <strong style={{float:'left'}}>Procedure Amount</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Procedure Amount"] : "$0.00"} </span>
                                                </div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>Amount Financed</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Financed Amount"] : "$0.00"} </span>
                                                </div>

                                                <div className='col-lg-12 d-flex justify-content-between'><strong style={{float:'left'}}>Annual Percentage Rate</strong><span className="ms-2">{offerDetails['Interest Rate'][Object.keys(offerDetails['Interest Rate'])[0]]}</span></div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>Total Payments</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Scheduled Payment Details"]["Payment Count"] : "0"} </span>
                                                </div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>One-Time Convenience Fee</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Convenience Fee"] : "$0.00"} </span>
                                                </div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>Down Payment</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Down Payment Amount"] : "$0.00"} </span>
                                                </div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>1st Loan Payment</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Loan Payment Amount"] : "$0.00"} </span>
                                                </div>

                                                <div className="col-lg-12 d-flex justify-content-between">
                                                <strong style={{float:'left'}}>Amount to Pay</strong> <span className="ms-2" style={{fontWeight:'100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Total Initial Cost"] : "$0.00"} </span>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="d-flex mt-4">
                                                <button style={{float:'right',border:'1px solid'}} class="btn btn-primary btn-primary-sm w-90 mx-auto text-capitalize mb-3" onClick={(event) => selectCard(index+1,event)}>Select</button>
                                            </div>


                            </div>
                        </div>
                            </>
                        );
                        }): ''}
                        </div></div> : <div></div>
                }
                {
                    enerveeLoanOfferWidget ?
                        <EnerveeLoanOffer loanData={window.enervee_amortise_loan_data} setEnerveeLoanOffer={setEnerveeLoanOffer} submitForm={submitform}></EnerveeLoanOffer> : <div></div>
                }
                     {
                    paymentMethodWidget ?
                        <PaymentMethodWidget moveToSpecificStep={moveToSpecificStep} submitForm={submitform} providerResult={window.enervee_provider_result} setPaymentMethodWidgetData={setPaymentMethodWidgetData} />: <div></div>
                }
                {
                    invoiceWidget && window.invoice_data.length > 0 ?
                        <InvoiceWidget invoiceData={window.invoice_data} />: <div></div>
                }
                {
                    conductiveWidget ?
                        <ConductiveWidget jwtToken={window.create_token} /> : <div></div>
                }
                {
                    googleGeoLocationWidget ?
                   
                         <GoolgeGeoLocationWidget
                            apiKey={window.create_token}
                            apiUrl = {window.google_geo_location_url} 
                            setFormData={setFormData}
                            formData={formData}
                        />: <div></div>
                }
                {
                    googlePlaceWidget ?

                        <GooglePlaceWidget
                            apiKey={window.create_token}
                            apiUrl={window.google_place_url}
                            setFormData={setFormData}
                            formData={formData}
                        /> : <div></div>
                }
                

{
  paymentSchedule && window.amortization_provider_result_data ? (
    <>
    <div className='container'>
      <div className="row" style={{ textAlign: 'initial' }}>
        <>
          <div className="col-md-6 mb-4 mx-auto" id="Loanoffers">
            <div className="border rounded mt-lg-5 border-primary" data-loan-details={JSON.stringify(window.amortization_provider_result_data)}>
              <div className="card-body mt-2">
                <div className="text-bold text-center fs-4 text-primary" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                  Offer
                </div>
                <div className="mt-3 text-dark mb-lg-4">
                  <div className='col-lg-12'>
                    <strong style={{ float: 'left' }}>Loan amount : </strong>
                    <span className="ms-2">{window.amortization_provider_result_data.amount}</span>
                  </div>
                  <div className='col-lg-12'>
                    <strong style={{ float: 'left' }}>Fee : </strong>
                    <span className="ms-2" style={{ fontWeight: '100' }}> {window.amortization_provider_result_data.fee} </span>
                  </div>
                  <div className="col-lg-12 text-left">
                    <strong style={{ float: 'left' }}>Periodic payment : </strong>
                    <span className="ms-2" style={{ fontWeight: '100' }}> {window.amortization_provider_result_data.periodic_payment.toFixed(2)} </span>
                  </div>
                  <div className='col-lg-12'>
                    <strong style={{ float: 'left' }}>APR : </strong>
                    <span className="ms-2">{(window.amortization_provider_result_data.apr * 100).toFixed(2)}</span>
                  </div>
                  <div className="col-lg-12 text-left">
                    <strong style={{ float: 'left' }}>Total payments : </strong>
                    <span className="ms-2" style={{ fontWeight: '100' }}> {window.amortization_provider_result_data.total_payments.toFixed(2)} </span>
                  </div>
                  <div className="col-lg-12 text-left">
                    <strong style={{ float: 'left' }}>Total interests : </strong>
                    <span className="ms-2" style={{ fontWeight: '100' }}> {window.amortization_provider_result_data.total_interests.toFixed(2)} </span>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-4">
                <button style={{ float: 'right', border: '1px solid' }} className="btn btn-primary btn-primary-sm w-90 mx-auto text-capitalize" onClick={(event)=>amortizationData(event)} data-bs-toggle="modal"
                                        data-bs-target="#modal_add_document">View Amortization data</button>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>



<div className="modal fade" id="modal_add_document" tabIndex="-1" role="dialog" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content p-3">
        <div className="modal-header">
            <h4 className="modal-title">Amortization data</h4>
            <button type="button" className="btn-close fs-3 text-dark fs-3"
                    data-bs-dismiss="modal"
                    aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
      <div className="card-body">
        { window.amortization_provider_result_data.amortization.length > 0 ? (
          window.amortization_provider_result_data.amortization.map((data, index) => (
            <div key={index} className="mb-3 history-timeline">
              <div className="pt-1 first_block">
                <h6
                  className="text-dark app_history font-weight-bold mb-0"
                  data-toggle="collapse"
                  data-target={`#app_history${index}`}
                  aria-controls={`app_history${index}`}
                >
                  Data {index+1}
                  <i
                    className="fa-solid fa-angle-down ms-5 cursor-pointer offer_table_icon"
                    style={{ color: '#667085' }}
                  ></i>
                </h6>
                <div
                  className="collapse multi-collapse offer_table_result"
                  id={`app_history${index}`}
                >
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">Period:</span>{' '}
                    {data.period}
                  </p>
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">
                      Periodic Payment:
                    </span>{' '}
                    {data.periodic_payment}
                  </p>
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">Interest:</span>{' '}
                    {data.interest}
                  </p>
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">
                      Cumulative Interest:
                    </span>{' '}
                    {data.cumulative_interests}
                  </p>
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">Principal:</span>{' '}
                    {data.principal}
                  </p>
                  <p className="text-sm text-dark mt-3 mb-2">
                    <span className="text-bold text-dark">
                      Balance:
                    </span>{' '}
                    {data.balance}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <span className="text-bold text-dark">
              Amortization Data Not Found
            </span>
          </div>
        )}
      </div>
    </div>
  </div>
</div>
</div>
</>
  ) : ( window.amortization_provider_result_data === 'None' && paymentSchedule ?
    <div>
      <Alert variant="light" className="text-center py-5">
        <div className="max-width-400 mx-auto p-4 border rounded">
          <h3>No offers available</h3>
          <p className="text-muted">Unfortunately, we do not have any offers available for you.</p>
        </div>
      </Alert>
    </div> : <div></div>
  )
}


                {
                    controls.length > 0 ?
                        <div className = {`col-12 mx-auto checkbox-controls ${mobileOtpWidget ? "col-lg-8": "mb-2 col-lg-12"}`} id="checkbox_controls">
                            {   /* eslint-disable-next-line */
                                controls.map(({id, name, text, type, required,label_font_size, request_doc_id, label_alignment}, index) => {

                                    const uniqueId = generateRandomId();
                                    if (type === "document_upload") {
                                        const matchingDocuments = documentControl.filter(docItem => docItem.request_doc_id === request_doc_id);

                                        return (
                                          <div className="row mt-3 mb-3 document_upload_control" key={uniqueId}>
                                            <div className="row mt-3 justify-content-center">
                                              {matchingDocuments.map(({ id, name, description }, docIndex) => {

                                                  const fieldState = fieldStates.filter(state => state !== undefined && state !== null)
                                                                                        .find(state => state.id === Number(id));

                                                return (
                                                  <div className="row mt-3" key={`${uniqueId}_${docIndex}`}>
                                                    <div class="row">
                                            <div class="col-8 ps-4 text-start">
                                                <label data-id={id} for={id} class={fieldState?.UploadAnimation ? "uploading" : ""}>
                                                    <div class="text-dark text-bold">
                                                        {fieldState?.UploadAnimation}
                                                        {name}
                                                        {required === 'true' ? <span className='ms-2' style={{"color":"red"}}>*</span>:''}
                                                        <span class="">
                                                            <i class="fa-solid fa-circle-question doc-help ms-2" data-bs-toggle="tooltip" data-bs-placement="right" title={description} ></i>
                                                        </span>
                                                        <span hidden class="">
                                                            <i class="fa-regular fa-circle-check text-success ms-2"></i>
                                                        </span>
                                                    </div>
                                                    <div class="upload_complete">
                                                        <span className="file_name" name={"request_doc_id" + id.toString()}>{fieldState?.UploadedfileName}</span>
                                                    </div>
                                                    <div class="file-upload-progress">
                                                        <div class="file-upload-progress-value"></div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="col-4 text-end">
                                                <label data-doc-id={id} for={id} className="upload-btn">
                                                   <p style={{color: "#6f4bd1", textDecoration: "underline", fontSize: "1rem", fontWeight: "bold", marginBottom: 0, padding: "0.2em 0.4em", cursor: "pointer"}}>Upload</p>
                                                </label>
                                            </div>
                                            <input type="file" onChange={upload_request_doc} hidden data-doc-id={id} id={id} required={required === 'true'? true : false}/>

                                            {fieldState?.isFieldRequired && (
                                                    <p className="text-danger document_required_msg" style={{marginTop: "-1em"}}>
                                                        This is a required field
                                                    </p>
                                            )}

                                            <hr class="bg-dark"/>
                                        </div>


                                                </div>
                                )})}
                                            </div>
                                          </div>
                                        );
                                      }
                                    else if (type === "document_display") {

                                        return (
                                          <div className="row mt-3 document_upload_control">
                                              <div className="row mb-2 mb-md-5 justify-content-center d-flex mx-auto w-100">
                                                  <iframe title="doc_display" id="doc_display_iframe" className="p-0"
                                                          width="70%" height="60vh"
                                                          src={"/page_builder/control_consent_doc_iframe/" + request_doc_id}></iframe>

                                              </div>
                                          </div>
                                        );
                                    }
                                    else if (type === "scroll_to_bottom") {
                                        if(required && !scrollRequired)
                                        {
                                            setScrollRequired(true)
                                        }
                                        return (
                                          <div className="row mt-3">
                                              <div className="row justify-content-center d-flex mx-auto w-100 text-center ">
                                                {/* eslint-disable-next-line  */}
                                                  <a href="#" id="scroll_to_bottom" className="mx-auto scroll_to_bottom" scroll-required={required}
                                                     onClick={(e) => scrollToIframeBottom(e, null)}><i className="fa-regular fa-circle-down me-2"></i>{text}
                                                  </a>
                                              </div>
                                          </div>
                                        );
                                    }
                                    else if (type === "checkbox") {
                                        return (
                                            <div className="mt-3 ms-2">
                                                <label className='row' style={{textAlign: 'left'}}>
                                                    <input type="checkbox" id={id} placeholder="" onChange={handleControlChange}
                                                            name={'checkbox_control_' + index} required={required} data-text={text}
                                                            className='drag-drop-element col-1' style={{"height": "20px", "margin-top":"3px"}}></input>
                                                    <span className="col-10 ms-1 p-0" style={{fontSize: "0.9rem"}} dangerouslySetInnerHTML={{__html: text}}
                                                           htmlFor={'checkbox_control_' + index}></span>
                                                    </label>


                                                </div>
                                        );
                                    }
                                    else if (type === "label")
                                    {
                                        return (
                                            <div className="my-1"  style={{textAlign:label_alignment}}>
                                                <span
                                                name="label_control"
                                                style={{fontSize:label_font_size + "px",textAlign:'left', fontWeight: mobileOtpWidget? 900: 'normal'}}
                                                id={'label_control' + index}
                                                type="label"
                                                data-text={text}
                                                label-font-size={label_font_size}
                                                label-alignment={label_alignment}
                                                required={required}
                                                className="col-lg-10 m-0 drag-drop-element" htmlFor={'label_control' + index} dangerouslySetInnerHTML={{__html: text}} ></span>

                                            </div>
                                        );
                                    }
                                    else  if (type === "rich_text_editor" || type === "rich_text_editor_control") {
                                        return (
                                            <div className='rich-text-editor-control mt-3' key={index} dangerouslySetInnerHTML={{ __html: text }}
                                            />
                                        );
                                    }


                                })}
                        </div>
                        :
                        <div></div>
                }
                </div>
                : <></>}

            {
                reviewwidget ?
                    <div className="col-12 mx-auto">
                        <div class="">
                            <div class="d-flex justify-content-center">
                                <form id="form-step-9" class="form-step" data-process-status="Income"
                                      data-process-percent="100">

                                    <header className='text-center'>
                                        <i className="fa fa-clock fs-18 mr-3" aria-hidden="true"
                                           style={{"color": " #f2ca16", "font-size": "5rem"}}></i>
                                        <h2 class="header-title text-center mt-4">In Review</h2>
                                    </header>
                                    <div class="row mb-5 text-center d-flex mx-auto">
                                        {window.review_text.length > 0 ? window.review_text : 'Your application is in review. We will get back to you.'}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : <div></div>
            }
            {
                declinewidget ?
                    <div className="col-12 mx-auto">
                        <div class="">
                            <div class="d-flex justify-content-center">
                                <form id="form-step-9" class="form-step" data-process-status="Income"
                                      data-process-percent="100">

                                    <header className='text-center'>
                                        <i className="fa fa-circle-xmark fs-18 mr-3" aria-hidden="true"
                                           style={{"color": "red", "font-size": "5rem"}}></i>

                                        <h2 class="header-title text-center mt-4">Declined</h2>
                                    </header>
                                    <div class="row mb-5 text-center d-flex mx-auto">
                                        {window.decline_text.length > 0 ? window.decline_text : 'Sorry! Your application is declined.'}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : <div></div>
            }
            {
                approvewidget ?
                    <div class="col-12 mx-auto">
                        <div class="">
                            <div class="d-flex justify-content-center">
                                <form id="form-step-9" class="form-step" data-process-status="Income"
                                      data-process-percent="100">

                                    <header className='text-center'>
                                        <i className="fa fa-check-circle fs-18 mr-3" aria-hidden="true"
                                           style={{"color": "green", "font-size": "5rem"}}></i>
                                        <h2 class="header-title text-center mt-4">Congrats!</h2>
                                    </header>
                                    <div class="row mb-5 text-center d-flex mx-auto">
                                        {window.approve_text.length > 0 ? window.approve_text : 'Your application is in review. We will get back to you.'}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : <div></div>
            }
            {
                flinkwidget ? <div></div> :
                    window.multi_offer.credit_card.length === 0 && window.multi_offer.personal_loan.length === 0 && multiOffers ?
                        <div></div> :
                        prove ? <div></div> :
                            decisionlogic ? <div></div> :
                                plaidcrabase ? <div></div> :
                                    plaidcraincome ? <div></div> :
                                        plaidcrapartner ? <div></div> :
                                            chirpVerification ? <div></div> :
                                                manualProcessingStep ? <div></div> :
                                                    paymentMethodWidget ? <div></div> :
                                                        enerveeLoanOfferWidget ? <div></div> :
                                                        window.appStatusInt === 5 ? <div></div>:
                                                        ((['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0) && (loanoffers || multiOffers)) ?
                                                            <div className='d-flex mx-auto mt-5' style={{
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                {
                                                                    window.back_btn_status === "True" && !is_first_step ?
                                                                        <button
                                                                            className="bg-dark text-white border rounded mx-auto mt-3 mt-md-5 hide_submit_button"
                                                                            id="back_btn"
                                                                            type="button" onClick={handleBackButtonClick}>
                                                                            Back
                                                                        </button>
                                                                        : ""
                                                                }
                                                                <button className='btn btn-error btn-error-sm hide_submit_button'
                                                                        onClick={declinealloffers}>Decline All
                                                                </button>
                                                                <button className="btn btn-primary btn-primary-sm ms-3 hide_submit_button"
                                                                        type="submit" onClick={submitform}>
                                                                    Continue
                                                                </button>
                                                            </div>
                                                            :
                                                            ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 && (PrequalificationOffer) ?
                                                                <div className='text-center form-control'>
                                                                    {
                                                                        window.back_btn_status === "True" && !is_first_step ?
                                                                            <button
                                                                                className="bg-dark text-white border rounded mx-auto mt-3 mt-md-5 hide_submit_button"
                                                                                id="back_btn"
                                                                                type="button" onClick={handleBackButtonClick}>
                                                                                Back
                                                                            </button>
                                                                            : ""
                                                                    }
                                                                    <div className='w-100 d-flex justify-content-center align-items-center'>
                                                                        <button
                                                                            className="btn btn-primary btn-primary-sm ms-1 me-2 hide_submit_button"
                                                                            id="continue_submit_btn" type="submit"
                                                                            onClick={submitform}>
                                                                            Continue
                                                                        </button>
                                                                        <button className='btn btn-error btn-error-sm hide_submit_button'
                                                                                onClick={withdrawApplication}>Withdraw
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 && (payroll) ?
                                                                    <div className='d-flex justify-content-between'>
                                                                        {
                                                                            window.back_btn_status === "True" && !is_first_step ?
                                                                                <button
                                                                                    className="bg-dark text-white border rounded mt-3 mt-md-5 ms-3 hide_submit_button"
                                                                                    id="back_btn"
                                                                                    type="button" onClick={handleBackButtonClick}>
                                                                                    Back
                                                                                </button>
                                                                                : ""
                                                                        }
                                                                        <button disabled={payFrequency === ''}
                                                                                className={`btn btn-primary btn-primary-sm hide_submit_button ${window.back_btn_status === "True" && !is_first_step ? "" : "mx-auto"} mt-3 mt-md-5`}
                                                                                id="submit_payroll_btn" type="submit"
                                                                                onClick={submitPayroll}>
                                                                            Continue
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 && mobileOtpWidget ?
                                                                        <div className='d-flex justify-content-between'>
                                                                            {
                                                                                window.back_btn_status === "True" && !is_first_step ?
                                                                                    <button
                                                                                        className="bg-dark text-white border rounded ms-5 mt-3 mt-md-5 hide_submit_button"
                                                                                        id="back_btn"
                                                                                        type="button" onClick={handleBackButtonClick}>
                                                                                        Back
                                                                                    </button>
                                                                                    : ""
                                                                            }
                                                                            <button
                                                                                className={`btn btn-primary btn-primary-sm hide_submit_button ${window.back_btn_status === "True" && !is_first_step ? "" : "mx-auto"} mt-3 mt-md-5`}
                                                                                id="continue_submit_btn" type="submit"
                                                                                onClick={check_sms_otp}>
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 && googleGeoLocationWidget ?
                                                                            <div className='d-flex justify-content-between'>
                                                                                {
                                                                                    window.back_btn_status === "True" && !is_first_step ?
                                                                                        <button
                                                                                            className="bg-dark text-white border rounded ms-5 mt-3 mt-md-5 hide_submit_button"
                                                                                            id="back_btn"
                                                                                            type="button" onClick={handleBackButtonClick}>
                                                                                            Back
                                                                                        </button>
                                                                                        : ""
                                                                                }
                                                                                <button
                                                                                    className={`btn btn-primary btn-primary-sm hide_submit_button ${window.back_btn_status === "True" && !is_first_step ? "" : "mx-auto"} mt-3 mt-md-5`}
                                                                                    id="continue_submit_btn" type="submit"
                                                                                            onClick={submitform}>
                                                                                    Continue
                                                                                </button>
                                                                            </div>
                                                                        :
                                                                        ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 && googlePlaceWidget ?
                                                                        <div className='d-flex justify-content-between'>
                                                                            {
                                                                                window.back_btn_status === "True" && !is_first_step ?
                                                                                    <button
                                                                                        className="bg-dark text-white border rounded ms-5 mt-3 mt-md-5 hide_submit_button"
                                                                                        id="back_btn"
                                                                                        type="button" onClick={handleBackButtonClick}>
                                                                                        Back
                                                                                    </button>
                                                                                    : ""
                                                                            }
                                                                            <button
                                                                                className={`btn btn-primary btn-primary-sm hide_submit_button ${window.back_btn_status === "True" && !is_first_step ? "" : "mx-auto"} mt-3 mt-md-5`}
                                                                                id="continue_submit_btn" type="submit"
                                                                                onClick={submitform}>
                                                                                Continue
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        ['review', 'decline', 'approve', 'booked', 'withdraw', 'resubmit'].indexOf(label) < 0 ?
                                                                            <div className='d-flex justify-content-between'>

                                                                                {
                                                                                    window.back_btn_status === "True" && !is_first_step ?
                                                                                        <button
                                                                                            className="bg-dark text-white border rounded ms-4 mt-3 mt-md-5 hide_submit_button"
                                                                                            id="back_btn"
                                                                                            type="button" onClick={handleBackButtonClick}>
                                                                                            Back
                                                                                        </button>
                                                                                        : ""
                                                                                }
                                                                                <button
                                                                                    className={`btn btn-primary btn-primary-sm hide_submit_button ${window.back_btn_status === "True" && !is_first_step ? "" : "mx-auto"} mt-3 mt-md-5`}
                                                                                    id="continue_submit_btn"
                                                                                    type="submit" onClick={submitform}>
                                                                                    Continue
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            (

                                                                                ['approve', 'booked'].indexOf(label) >= 0 && window.product !== "10003" && window.show_portal === "True" && window.dunmor_subtenant_check === "True" ? (
                                                                                        <div className='d-flex'>
                                                                                            <button
                                                                                                className="btn btn-primary btn-primary-sm mx-auto hide_submit_button"
                                                                                                type="button"
                                                                                                onClick={loginSubTenantPortal}>
                                                                                                Go to Portal
                                                                                            </button>
                                                                                        </div>
                                                                                    ) :
                                                                                    ['approve', 'booked'].indexOf(label) >= 0 && window.product !== "10003" && window.show_portal === "True" ? (
                                                                                        <div className='d-flex'>
                                                                                            {applicant_redirect_status ? <button
                                                                                                className="btn btn-primary btn-primary-sm mx-auto hide_submit_button"
                                                                                                type="button"
                                                                                                onClick={submitapproveform}>
                                                                                                Go to Application Portal
                                                                                            </button>:<></>}
                                                                                        </div>
                                                                                    ) : (['review', 'decline', 'resubmit'].indexOf(label) >= 0 && window.product === "10001" && window.show_portal === "True" ? (
                                                                                            <div className='d-flex'>
                                                                                                <button
                                                                                                    className="btn btn-primary btn-primary-sm mx-auto hide_submit_button"
                                                                                                    type="button"
                                                                                                    onClick={submitapproveform}>
                                                                                                    Go to Application
                                                                                                    Portal
                                                                                                </button>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div></div>
                                                                                        )
                                                                                    )
                                                                            )}

            <div className="modal fade come-from-modal right" id="modal_control_doc">
                <div className="modal-dialog" style={{height:"85vh"}}>
                    <div className="modal-content p-0 m-0" style={{height: "100%"}}>
                        <div className="modal-header border-0">
                            <span className="close ms-auto cursor-pointer" data-dismiss="modal" data-bs-dismiss="modal">&times;</span>
                        </div>


                        <iframe id="control_doc_iframe" title='modal_control_doc_iframe' src="" height="100%"
                                width="100%"></iframe>

                    </div>
                </div>
            </div>

        </>
    );


}

export default JsonFormBuilder;