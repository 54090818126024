import React from 'react';


const WizardStepper = ({currentLabel, fetchDataForStepper}) => {

    return (
        <>
        {/* <div className="container"> */}
                {/* <div className="mdl-card mdl-shadow--2dp"> */}
                    <div className="nav-wrapper position-relative mx-2">
                        <ul className="nav nav-fill" role="tablist">
                            {window.non_widget_tabs.map((item, index) => {
                                return (
                                    <li className='nav-item me-2'>
                                        <label id="" href='#' onClick={() => fetchDataForStepper(item.title)}
                                            className={"app-edit-nav-link mb-0 cursor-pointer " + (item.title === currentLabel ? "active" : "")}
                                            role='tab' data-bs-toggle="tab" aria-controls="cam1" aria-selected="true">
                               
                                            <div className="">{item.label}</div>
                                        </label>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                {/* </div> */}
            {/* </div> */}
        </>
    )
}

export default WizardStepper;