//dummy schema
export const dummy_schema = {
    "type": "object",
    "properties": {
      "first_name": {
        "type": "string"
      },
      "last_name": {
        "type": "text"
      },
      "address": {
        "type": "text"
      },
      "city": {
        "type": "text"
      }
    },
    "required": []
  }
  
  //Input Variables
  export const InputVariables = [
    {
      id: 'first_name',
      name: 'First Name',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'last_name',
      name: 'Last Name',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'address',
      name: 'Address',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'apt',
      name: 'Apt/Suite',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'city',
      name: 'City',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'state',
      name: 'State',
      value: [('Alabama (AL)'),
      ('Alaska (AK)'),
      ('Arizona (AZ)'),
      ('Arkansas (AR)'),
      ('California (CA)'),
      ('Colorado (CO)'),
      ('Connecticut (CT)'),
      ('Delaware (DE)'),
      ('District of columbia (DC)'),
      ('Florida (FL)'),
      ('Georgia (GA)'),
      ('Hawaii (HI)'),
      ('Idaho (ID)'),
      ('Illinois (IL)'),
      ('Indiana (IN)'),
      ('Iowa (IA)'),
      ('Kansas (KS)'),
      ('Kentucky (KY)'),
      ('Louisiana (LA)'),
      ('Maine (ME)'),
      ('Maryland (MD)'),
      ('Massachusetts (MA)'),
      ('Michigan (MI)'),
      ('Minnesota (MN)'),
      ('Mississippi (MS)'),
      ('Missouri (MO)'),
      ('Montana (MT)'),
      ('Nebraska (NE)'),
      ('Nevada (NV)'),
      ('New Hampshire (NH)'),
      ('New Jersey (NJ)'),
      ('New Mexico (NM)'),
      ('New York (NY)'),
      ('North Carolina (NC)'),
      ('North Dakota (ND)'),
      ('Ohio (OH)'),
      ('Oklahoma (OK)'),
      ('Oregon (OR)'),
      ('Pennsylvania (PA)'),
      ('Rhode Island (RI)'),
      ('South Carolina (SC)'),
      ('South Dakota (SD)'),
      ('Tennessee (TN)'),
      ('Texas (TX)'),
      ('Utah (UT)'),
      ('Vermont (VT)'),
      ('Virginia (VA)'),
      ('Washington (WA)'),
      ('West Virginia (WV)'),
      ('Wisconsin (WI)'),
      ('Wyoming (WY)'),
      ('Armed Forces (AA) '),
      ('Armed Forces (AE)'),
      ('Armed Forces (AP)')
      ],
      type: "select",
      required: false
    },
    {
      id: 'zip',
      name: 'Zip',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'Date_of_birth',
      name: 'Date of Birth',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'mobile',
      name: 'Mobile',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'ssn',
      name: 'SSN',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'email',
      name: 'Email',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'password',
      name: 'Password',
      value: [],
      type: "input",
      required: false
    },
    {
      id: 'co-applicant',
      name: 'CoApplicant',
      value: [],
      type: "checkbox",
      required: false 
    },
  ];
  export const Controls = [
    {
      id: 'checkbox_control',
      name: 'Checkbox',
      text: "Enter text for the checkbox",
      type: "checkbox",
      required: false
    },
    {
      id: 'label_control',
      name: 'Label',
      text: "Enter text for the label",
      type: "label",
      required: false
    },
    {
      id: 'document_upload',
      name: 'Upload document',
      text: "",
      type: "document_upload",
      required: false
    },
    {
      id: 'document_display',
      name: 'Display document',
      text: "",
      type: "document_display",
      required: false
      },
    {
      id: 'scroll_to_bottom',
      name: 'Scroll to bottom',
      text: "",
      type: "scroll_to_bottom",
      required: false
    },
    {
      id: 'rich_text_editor',
      name: 'Rich Text Editor',
      text: "",
      type: "rich_text_editor",
      required: false
    },
    ]

  export const loaddata = {
    "business_structure" : "LLC",
    "business_zip_code": "18106",
    "citizenship_status": "U.S. citizen",
    "first_name": "Lisa",
    "last_name": "Michel",
    "birth_date": "11/11/1990",
    "ssn": "666-64-8194",
    "mobile": "964-396-6054",
    "id_type": "Driver license",
    "id_number": "99900104",
    "issuing_state": "PA",
    "issuing_date": "11/11/2010",
    "expiration_date": "11/11/2040",
    "address": "1013 village round",
    "city": "Allentown",
    "state": "PA",
    "zip_code": "18106",
    "business_title": "Director",
    "business_legal_name": "TATA MOTORS",
    "business_phone_number": "964-396-6054",
    "business_email": "tata@fake.com",
    "business_address": "1013 VILLAGE ROUND",
    "business_state": "PA",
    "business_city": "ALLENTOWN",
    "business_established_date": "11/11/2014",
    "naics_code": "123456",
    "business_classification": "Accountant",
    "annual_revenue": "$0 - $200,000",
  }

  export const coborrower_schema = {
    "properties":{
      "coborrower_first_name":{
         "label":"CoBorrower first name",
         "type":"string"
      },
      "coborrower_last_name":{
         "label":"Coborrower last name",
         "type":"string"
      },
      "coborrower_email":{
         "label":"Coborrower email",
         "type":"string"
      },
      "coborrower_mobile":{
         "label":"CoBorrower mobile",
         "type":"string"
      },
      "coborrower_address":{
         "label":"coborrower address",
         "type":"string"
      },
      "coborrower_ssn":{
         "label":"Coborrower ssn",
         "type":"string"
      },
      "coborrower_state":{
         "label":"coborrower state",
         "type":"string"
      },
      "coborrower_city":{
         "label":"coborrower city",
         "type":"string"
      },
      "coborrower_zip_code":{
         "label":"coborrower zip code",
         "type":"string"
      }
   },
   "title":"CoBorrower",
   "type":"object"
  }


  export const coborrower_ui_schema = 
    {
      "type":"Group",
      "elements":[
         {
            "type":"HorizontalLayout",
            "elements":[
               {
                  "type":"Control",
                  "label":"CoBorrower first name",
                  "scope":"#/properties/CoBorrower/properties/coborrower_first_name",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                          "scope": "#/properties/has_coborrower",
                          "schema": {
                            enum: ["", "No"]
                          }
                    }
                  }
               },
               {
                  "type":"Control",
                  "label":"Coborrower last name",
                  "scope":"#/properties/CoBorrower/properties/coborrower_last_name",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               }
            ],
         },
         {
            "type":"HorizontalLayout",
            "elements":[
               {
                  "type":"Control",
                  "label":"Coborrower email",
                  "scope":"#/properties/CoBorrower/properties/coborrower_email",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               },
               {
                  "type":"Control",
                  "label":"CoBorrower mobile",
                  "scope":"#/properties/CoBorrower/properties/coborrower_mobile",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               }
            ]
         },
         {
            "type":"HorizontalLayout",
            "elements":[
               {
                  "type":"Control",
                  "label":"coborrower address",
                  "scope":"#/properties/CoBorrower/properties/coborrower_address",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               },
               {
                  "type":"Control",
                  "label":"Coborrower ssn",
                  "scope":"#/properties/CoBorrower/properties/coborrower_ssn",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               }
            ]
         },
         {
            "type":"HorizontalLayout",
            "elements":[
               {
                  "type":"Control",
                  "label":"coborrower state",
                  "scope":"#/properties/CoBorrower/properties/coborrower_state",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               },
               {
                  "type":"Control",
                  "label":"coborrower city",
                  "scope":"#/properties/CoBorrower/properties/coborrower_city",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               }
            ]
         },
         {
            "type":"HorizontalLayout",
            "elements":[
               {
                  "type":"Control",
                  "label":"coborrower zip code",
                  "scope":"#/properties/CoBorrower/properties/coborrower_zip_code",
                  "rule": {
                    "effect": "HIDE",
                    "condition": {
                      "scope": "#/properties/has_coborrower",
                      "schema": {
                        enum: ["", "No"]
                      }
                }
                  }
               }
            ]
         },
      ]
   }
   export const patientData = {
    "patient_relation" : "Self"
  }

  // Mapping object for widget ids
  export const widgetMapping = {
    "approve": "approve",
    "decline": "decline",
    "review": "review",
    "agreement": "agreement",
    "offer_agreement": "offer_agreement",
    "plaid_auth": "plaid_auth",
    "plaid_asset": "plaid_asset",
    "plaid_cra_partner": "plaid_cra_partner",
    "payment_schedule_widget": "payment_schedule_widget",
    "flinks_and_welspot": "flinks_and_welspot",
    "loan_offers": "loan_offers",
    "multi_offers": "multi_offers",
    "multi_agreements": "multi_agreements",
    "product_order_widget": "product_order_widget",
    "plaid_cra_base": "plaid_cra_base",
    "plaid_cra_income": "plaid_cra_income",
    "prequalification_offer": "prequalification_offer",
    "decision_logic": "decision_logic",
    "payment_screen": "payment_screen",
    "mobile_otp": "mobile_otp",
    "payroll_selection_widget": "payroll_selection_widget",
    "chirp_verification": "chirp_verification",
    "enervee_loan_offer_widget": "enervee_loan_offer_widget",
    "payment_method_widget": 'payment_method_widget',
    "invoice_widget": 'invoice_widget',
    "conductive_widget": 'conductive_widget',
    "google_geo_location_widget": 'google_geo_location_widget',
    "google_place_widget": 'google_place_widget',

  }