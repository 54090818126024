import React from 'react';
import './AuthBackground.css'

const AuthBackground = ({ children }) => {
  return (
    <div className="vw-100 vh-100 auth-background-container">
      <div className="vw-100 vh-100 auth-background-wrapper">
        <div className="top-shapes">
          <div className="bg-shape bg-shape-1"></div>
          <div className="bg-shape bg-shape-2"></div>
        </div>
        <div className="bg-shape bg-shape-3"></div>
      </div>
      <div className="auth-content-wrapper">
        {children}
      </div>
    </div>
  );
};

export default AuthBackground;
