import React, {useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';

import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import './ForgotPassword.css';


const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const { register, handleSubmit, formState: { errors } } = useForm();

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Manual client-side validation using react-hook-form errors
      const csrfToken = getCookie('csrftoken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        }
      };

      // Add necessary data to FormData
      const formData = {
        email: data.email,
      };

      // Submit the form data using axios
      const response = await axios.post('/signup/v1/forgot_password/', formData,
        config
      );

      // Handle response
      if (response.status === 200) {
        showNotification('Reset link sent to email.', 'success');
      }
    } catch (error) {
      if (error.response?.status === 500) {
        showNotification("Request failed. Please try again", 'error');
      }
      else if (error.response?.data.code === -1) {
        showNotification(error.response?.data.message || "Email address not found. Try again.", 'error');
        }else{
        showNotification('Request failed. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            {/* Form Card - Fotgot Password Form */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Let's reset your password</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8' }}>Enter your registered email address & we will send you a link to reset your password
                  </p>
                </div>
                <div className="">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="text-start" id="form-signup">
                      {/* Form Fields */}
                      {/* Email */}
                      <div className="input-group my-2 mb-4">
                        <input
                          type="email"
                          className={`form-control p-2 border rounded ${(errors.email) ? 'is-invalid' : ''}`}
                          placeholder="Email"
                          {...register('email', { required: 'Email is required', pattern: /^\S+@\S+$/i })}
                          onChange={handleInputChange}
                        />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                      </div>

                      {/* Submit Button */}
                      <div className="col-12 mt-lg-4 mt-4 d-flex justify-content-center align-items-center">
                        <button disabled={loading} type="submit" className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize text-center">
                          {loading ? "Resetting..." : "Reset Password"}
                        </button>
                      </div>

                      {/* Already have an account? */}
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default ForgotPassword;
