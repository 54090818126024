import idCard from "../../../../img/id-card.png";
import bgSignup from "../../../../img/bg-signup.svg";
import './IdCard.css';

const trimTextWithEllipsis = (text, maxLength) => {
  if (text?.length <= maxLength) {
      return text;
  }
  return text?.slice(0, maxLength - 3) + "...";
}

function formatPhoneNumber(phone) {
  return phone.replace(/\D/g, '');
}

const IdCard = ({ formData }) => {

  return (
    <div className="id-card-container">
      {/* ID Card */}
      {/* White Background */}
      {/* <div
        className="position-absolute"
        style={{
          backgroundColor: "#fff",
          top: 0,
          left: 0,
          right: "60%",
          bottom: 0,
          zIndex: -2,
        }}
      >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              width: "300px",
              background: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)",
              filter: "blur(12px)",
              transform: "translateX(25px)",
          }}
          ></div>
      </div> */}

      {/* Background Image */}
      <img
        src={bgSignup}
        alt="signup background"
        className="bg-signup position-fixed"
        style={{
          top: 0,
          left: 0,
          right: "50%",
          bottom: 0,
          zIndex: -1,
          objectFit: "cover",
          width: "50%",
          height: "100%",
        }}
      />

      <div
        className="id-card d-flex align-items-center justify-content-center position-relative"
      >
        <img
          src={idCard}
          alt="Badge"
          className="id-card-img"
        />
        {/* Company Name */}
        <div className="id-card-content position-absolute">
          <div className="fw-bold">
            {trimTextWithEllipsis(formData?.company_name, 15) || "Company name"}
          </div>

          {/* First and Last Name */}
          <div>
            <div
              className="fw-bold fs-4 position-absolute first-name"
              style={formData?.first_name ? { color: '#ddd'} : { color: '#898a8e'}}
            >
              {trimTextWithEllipsis(formData?.first_name, 15) || "First name"}
            </div>
            <div
              className="fw-bold fs-4 position-absolute last-name"
              style={formData?.last_name ? { color: '#ddd'} : { color: '#898a8e'}}
            >
              {trimTextWithEllipsis(formData?.last_name, 15) || "Last name"}
            </div>
          </div>

          {/* Contact Details */}
          <div>
            <div className="fs-6 pb-1">
              {trimTextWithEllipsis(formData?.email, 20) || "username@company.com"}
            </div>
            <div className="text-bold fs-6">
              {(formData?.country_code && formData?.mobile)
                ? `+${formatPhoneNumber(formData.country_code)} ${formatPhoneNumber(formData.mobile)}`
                : "+1 999-999-9999"}
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default IdCard;