import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { getTestModeCookieValue } from "../../../utils/global_function";
import Modal from "react-bootstrap/Modal";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()


const ConfigModal = (props) => {
    const [selectedVariables, setSelectedVariables] = useState(props?.productConfig ? props?.productConfig?.approve_variables : null);
    const [variableOptions, setVariableOptions] = useState([]);
    const [configBackButton, setConfigBackButton] = useState(window.config_back_button === "True" ? true : false);
    const [applicantRedirectStatus, setapplicantRedirectStatus] = useState(window.applicant_redirect_status === "True" ? true : false);
    const [applicantRedirectURL, setapplicantRedirectURL] = useState(window.applicant_redirect_url !== "None" ? window.applicant_redirect_url : '');
    const [scriptTagContent, setScriptTagContent] = useState(props?.productConfig ? props?.productConfig?.script_tag_content : "");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/api/v1/list_variables/?product=${window.product_type}&test_mode=${test_mode_val}&all_vars=true`, {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            });
            if (response.data.results) {
                const varOptions = response.data.results?.map((item) => ({
                    ...item,
                    label: item.name,
                    value: item.engine_name,
                }))
                setVariableOptions(varOptions)
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        const postData = {
            product: window.product_type,
            product_referral: window.product_referral,
            enable_back_button: configBackButton,
            script_tag_content: scriptTagContent,
            test_mode: test_mode_val,
            approve_variables: selectedVariables,
            applicantRedirectStatus: applicantRedirectStatus,
            applicantRedirectURL: applicantRedirectURL,
        };


        axios.post("/api/v1/save_product_config/",
            postData,
            {
                headers: {
                    Authorization: "Basic " + ApiCredentials
                },
            }
        ).then(function (response) {
            window.show_success("Product configuration saved successfully", 0);
            setIsLoading(false);
            props.onHide();

        }).catch(function (error) {
            setIsLoading(false);
            window.show_alert(error.response.data.message);
        });
    };


    const handleApplicantRedirectStatus = (e) => {
        setapplicantRedirectStatus(e.target.checked);
        setapplicantRedirectURL('');
            // props.onHide();
    };

    const handleScriptTagContent = (e) => {
        setScriptTagContent(e.target.value);
    }

    return (<>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Product configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='w-100 col-3 mb-2 mt-4' id="provider-select">
                    <p htmlFor="provider_select" className="h6 b strong">Variables required for approval
                    </p>
                    <small className="mb-3">At the time of manual application approval, values will need to be provided
                        for each selected variable.
                        These values will take priority and will be used to guide the subsequent application
                        workflow.</small>
                    <Select
                        value={selectedVariables}
                        onChange={setSelectedVariables}
                        options={variableOptions}
                        isMulti
                        className="react-select provider-select mt-3"
                    />
                    {applicantRedirectStatus ? <div className="form-check mt-3 ms-n4">
                        <label className="form-label" htmlFor="applicantRedirectUrl">
                            Applicant Redirect URL:
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="applicantRedirectUrl"
                            placeholder="https://example.com or {{variable_code}}"
                            value={applicantRedirectURL}
                            onChange={(e) => setapplicantRedirectURL(e.target.value)}
                        />
                        <p className="text-muted mx-1 mb-2 mt-2" style={{ fontSize: "13px" }}>
                            Provide a valid URL or {"{{Variable Code}}"} for Applicant external redirection
                        </p>
                    </div> : <></>}

                    <div className="form-check mt-3 ms-n4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="configBackButton"
                            checked={applicantRedirectStatus}
                            onChange={handleApplicantRedirectStatus}
                        />
                        <label className="form-check-label ms-3" htmlFor="configBackButton">
                            Applicant Redirect Status
                        </label>
                    </div>

                    <div className="form-check mt-3 ms-n4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="configBackButton"
                            checked={configBackButton}
                            onChange={(e) => setConfigBackButton(e.target.checked)}
                        />
                        <label className="form-check-label ms-3" htmlFor="configBackButton">
                            Config Back Button
                        </label>
                    </div>
                    <div>
                        <div className="form-check mt-3 ms-n4">
                            <p htmlFor="scriptTagContent" className="h6 b strong">Add Script Tag</p>
                            <div className='scriptTagContent'>
                                <small className="mb-3 strong">The following <code>&lt;script&gt;&lt;/script&gt;</code> tag will be appended to the <code>&lt;head&gt;</code> element.</small>
                                <textarea className='form-control border' id='scriptTagContent' cols={5} rows={2} value={scriptTagContent} onChange={handleScriptTagContent}>

                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <button className='btn btn-secondary btn-secondary-sm' style={{ boxShadow: "none" }}
                    onClick={props.onHide}>Close
                </button>
                <button className='btn btn-primary btn-primary-sm' type="submit"
                    onClick={handleSave}>
                    {isLoading ? (
                        <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                        ></span>) : null}
                    {isLoading ? 'Saving...' : 'Save config'}
                </button>
            </Modal.Footer>
        </Modal>
    </>);
};

export default ConfigModal;
