import {useState, useEffect} from 'react';
import * as React from "react";


function PaymentMethodWidget({providerResult, submitForm, moveToSpecificStep, setPaymentMethodWidgetData}){
    const [showPaymentMethods, setShowPaymentMethods] = useState(providerResult && providerResult['payment_instruments'] ? true : false)
    const paymentMethods = providerResult && providerResult['payment_instruments'] ? providerResult['payment_instruments'] : {} 
    const [swapPaymentMethod, setSwapPaymentMethod] = useState(false)
    const [savedPaymentMethods, setSavedPaymentMethods] = useState([])
    const [selectedMethod, setSelectedMethod] = useState(null)
    function addNewMethod(){
        setSwapPaymentMethod(true)
        setShowPaymentMethods(false)
    }
    function showSavedMethod(){
        setSwapPaymentMethod(false)
        setShowPaymentMethods(true)
    }
    useEffect(() => {
        getActivePaymentMethods()
    })

    function selectExistingPaymentMethod(accountId, payment_instrument_id) {
        if(selectedMethod !== accountId) {
            setSelectedMethod(accountId)
            setPaymentMethodWidgetData({
                    account_id: accountId ,
                    payment_instrument_id: payment_instrument_id
                })
        }
    }

    function getActivePaymentMethods() {
        if(paymentMethods && paymentMethods.length) {
            const activeAccounts = paymentMethods.filter(account => account.status === "active" && account.object === "bankAccount" && account.accountLink?.accountId)
                .map(account => ({
                    accountId: account?.accountLink?.accountId ,
                    accountLast4: account.accountNumberLastFour,
                    payment_instrument_id: account?.id
                }));
            setSavedPaymentMethods(activeAccounts|| null)
            if (activeAccounts.length > 0) {
              selectExistingPaymentMethod(activeAccounts[0].accountId, activeAccounts[0].payment_instrument_id);
            }
            else{
                setShowPaymentMethods(false)
            }
        }
    }

    return (
        <>
            <div className='payment-method-widget1 col-12'>
                {/*this is add account tab,displayed just before plaid and in swap payment method*/}
                <div className="add-payment-method" style={{textAlign: 'initial'}}>
                    <div className="mt-2 p-0">
                        <div>
                            <h4>Set up loan repayment</h4>
                            <p className="mb-3">Your monthly payment is
                                ${window.app_data?.selected_offer_data?.[0]?.monthly_payment || ''}</p>
                        </div>
                        <div className="mb-3 text-dark mb-lg-4 mt-2">
                            {showPaymentMethods ?
                                <p><span className="strong h5">Saved payment method</span>
                                    {/* eslint-disable-next-line  */}
                                    <a href="#" style={{"float": "right"}} className="my-4 my-md-0" onClick={addNewMethod}>Add
                                        payment method</a></p> : ''}

                            {swapPaymentMethod ? <p><span className="strong h5">Add payment method</span>
                                    {/* eslint-disable-next-line  */}
                                    <a href="#" className="my-4 my-md-0" style={{"float": "right"}} onClick={showSavedMethod}>Back to saved
                                        payment methods</a></p> : ''}

                            {showPaymentMethods && savedPaymentMethods && savedPaymentMethods.map((account, index) => (
                                <div key={index}>
                                    <div className="w-100 d-flex justify-content-center container h5 py-2 justify-content-between border mt-3 mb-4" onClick={() => selectExistingPaymentMethod(account.accountId, account.payment_instrument_id )}>
                                        <div className='col-1 py-2'
                                             style={{'align-items': 'center', 'display': 'flex'}}>
                                            <input
                                                type="radio"
                                                value="option1"
                                                checked={account.accountId === selectedMethod ? true : false}
                                                className="radio-input m-0"
                                                style={{"width": "30px", "height": "23px"}}
                                            />

                                        </div>
                                        <div className='col-10 col-lg-11  py-2'>
                                                <span>Bank account
                                        <p>XXX-{account.accountLast4}</p></span>
                                        </div>
                                    </div>

                                </div>
                            ))}
                            {!showPaymentMethods ?
                                <div
                                    className="col-12 d-flex container h5 py-2 border my-3">
                                    <div className='col-1 py-2' style={{'align-items': 'center', 'display': 'flex'}}>
                                        <input
                                            type="radio"
                                            value="option1"
                                            checked={true}
                                            className="radio-input m-0"
                                            style={{"width": "30px", "height": "23px"}}
                                        />

                                    </div>
                                    <div className='col-10 col-lg-11 py-2'>
                                                <span>Pay by bank account</span>
                                    </div>
                                </div>
                            :""}

                            <div className='row d-flex justify-content-between px-3 px-md-2'>
                                <input type="checkbox" className="w-5 mt-2" style={{"height": "23px"}} id="payment_method_checkbox"></input>
                                <span className="w-95" style={{fontSize: "0.85rem"}}>
                                                    By checking this box, I authorize Plaid to verify and establish my
                                                    Payment account, and it will be debited for monthly recurring and/or
                                                    one-time payments per the terms and conditions of my
                                                    pre-qualification.</span>
                            </div>

                            <div className="mt-4 text-center">
                                {showPaymentMethods ?
                                    <button className="btn btn-lg btn-primary" type="submit"
                                            id="continue_submit_btn"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                const checkbox = document.getElementById("payment_method_checkbox");
                                                if (checkbox.checked) {
                                                    submitForm(e);
                                                } else {
                                                    window.show_alert("Please check the box to proceed further.");
                                                    return false
                                                }
                                            }}>
                                        Set up loan repayment
                                    </button> :

                                    <button onClick={(e) => {
                                        e.preventDefault()
                                        const checkbox = document.getElementById("payment_method_checkbox");
                                        if (checkbox.checked) {
                                            moveToSpecificStep("loanrepayment");
                                        } else {
                                            window.show_alert("Please check the box to proceed further.");
                                            return false
                                        }
                                    }} id="continue_submit_btn"
                                            className="btn btn-lg btn-primary">Proceed to plaid</button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {/*show payment methods here if available*/}
            </div>
        </>

    )
}

export default PaymentMethodWidget;