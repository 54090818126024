import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import IdCard from '../ReusableComponents/IdCard/IdCard';
import './EmailVerificationOTPForm.css';
import { Link, useNavigate } from 'react-router-dom';

import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';

import { saveToSessionStorage, getFromSessionStorage} from '../Utils/utils';

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const EmailVerificationOTPForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [maskedEmail, setMaskedEmail] = useState('user****@lendapi.com');
  const [signupFormData, setSignupFormData] = useState(null);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  // Create references for each input to handle focus changes
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [emailOtpResendCount, setEmailOtpResendCount] = useState(0);
  const [emailResetCount, setEmailResetCount] = useState(0);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  // Get signup form data from sessionStorage
  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      const parsedData = savedFormData;
      setSignupFormData(parsedData);
    }
  }, []);

  useEffect(() => {
      const storedNewEmail = getFromSessionStorage("newEmail")
      if (storedNewEmail) {
        setMaskedEmail(maskEmail(storedNewEmail));
      } else if(signupFormData?.email) {
        setMaskedEmail(maskEmail(signupFormData.email));
      }
  }, [signupFormData]);

  useEffect(() => {
    const storedCount = getFromSessionStorage('emailOtpResendCount');
    if (storedCount) {
      setEmailOtpResendCount(parseInt(storedCount, 10));
    }
  }, []);

  useEffect(() => {
    const storedEmailResetCount = getFromSessionStorage('emailResetCount');
    if (storedEmailResetCount) {
      setEmailResetCount(parseInt(storedEmailResetCount, 10));
    }
  }, []);

  // Function to mask email
  const maskEmail = (email) => {
    const emailParts = email.split('@');
    const localPart = emailParts[0];
    const domainPart = emailParts[1];

    const maskedLocalPart = localPart.slice(0, 3) + '*****';
    return `${maskedLocalPart}@${domainPart}`;
  };

  // Set masked email when signupFormData is available
  useEffect(() => {
    if (signupFormData?.email) {
      setMaskedEmail(maskEmail(signupFormData.email));
    }
  }, [signupFormData]);

  const handleChangeEmail = () => {
    setIsChangingEmail(!isChangingEmail);
    reset();
  };

  const handleBack = () => {
    setIsChangingEmail(false);
    reset();
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
    setValue(e.target.name, e.target.value, { shouldValidate: true });
  };

  const updateEmail = async () => {

    setLoading(true);
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = getFromSessionStorage('session_id');

      const response = await axios.post(
        '/signup/v1/update_email/',
        {
          session_id: sessionId,
          email: newEmail,
          email_reset_count: emailResetCount
        },
        {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.code === 1) {
        showNotification('Email updated successfully.', 'success');
        saveToSessionStorage("newEmail", newEmail);
        setEmailResetCount(response.data.email_reset_count);
        saveToSessionStorage("emailResetCount", response.data.email_reset_count);
        setMaskedEmail(maskEmail(newEmail));
        setIsChangingEmail(false);

        const updatedSignupFormData = {
          ...signupFormData,
          email: newEmail,
        };
        setSignupFormData(updatedSignupFormData);

        saveToSessionStorage("signupFormData", updatedSignupFormData);

        const signupData = {
          session_id: sessionId,
          email_otp_resend_count: emailOtpResendCount,
        };

        try {
          const response = await axios.post('/signup/v1/resend_email_otp/', signupData, {
            headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json'
            }
          });

          if (response.data.code === 1) {
            setEmailOtpResendCount(response.data.email_otp_resend_count);
            saveToSessionStorage('emailOtpResendCount', response.data.email_otp_resend_count);
            showNotification(response.data.message, 'success');
          } else {
            showNotification(response.data.message, 'error');
          }
        } catch (error) {
          if (error.response && error.response.data) {
            showNotification(error.response.data.message, 'error');
          }
          else {
            showNotification('Error verifying OTP.', 'error');
          }
        }

      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        showNotification(error.response.data.message, 'error');
      }
      else {
        showNotification('Failed to update email. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };


  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input if available and input is not empty
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    const digits = pasteData.replace(/[^0-9]/g, '').split('').slice(0, otp.length);

    if (digits.length) {
      const newOtp = [...otp];
      digits.forEach((digit, idx) => {
        newOtp[idx] = digit;
      });

      setOtp(newOtp);

      // Focus the last digit that was filled
      inputRefs.current[Math.min(digits.length - 1, otp.length - 1)].focus();
    }
  };


  // Handle form submission
  const onSubmit = async () => {
    const otpCode = otp.join('');
    if (otpCode.length === 6) {
      setLoading(true);
      try {
        const csrfToken = getCookie('csrftoken');
        const sessionId = getFromSessionStorage('session_id');

        const emailOtpData = {
          otp: otpCode,
          session_id: sessionId
        };

        const emailOtpResponse = await axios.post('/signup/v1/verify_signup_email/', emailOtpData, {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json'
          }
        });

        if (emailOtpResponse.data.code === 1) {

          showNotification(emailOtpResponse.data.message, "success")

          const mobileOtpData = {
            session_id: sessionId
          };

          try {
            const mobileOtpResponse = await axios.post('/signup/v1/signup_mobile_otp/', mobileOtpData, {
              headers: {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
              },
            });

            if (mobileOtpResponse.data.status === "success") {
              navigate('/verify_mobile_signup', {
                state: { message: mobileOtpResponse.data.message, messageType: 'success' },
              });
            } else {
              navigate('/verify_mobile_signup', {
                state: { message: mobileOtpResponse.data.message, messageType: 'error' },
              });
            }
          } catch (mobileOtpError) {
            navigate('/verify_mobile_signup', {
              state: { message: mobileOtpError.response.data.message, messageType: 'error' },
            });
          }
        } else {
          showNotification(emailOtpResponse.data.message, 'error');
        }
      } catch (error) {
        if(error.response && error.response.data) {
          showNotification(error.response.data.message, 'error');
        } else {
          showNotification('Error verifying OTP.', 'error');
        }
      } finally {
        setLoading(false);
      }
    } else {
      showNotification('Please enter the correct email OTP code.', 'error');
    }
  };

  // Handle OTP resend
  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = getCookie('csrftoken');
      const sessionId = getFromSessionStorage('session_id');

      const signupData = {
        session_id: sessionId,
        email_otp_resend_count: emailOtpResendCount,
      };

      const response = await axios.post('/signup/v1/resend_email_otp/', signupData, {
        headers: {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.code === 1) {
        setEmailOtpResendCount(response.data.email_otp_resend_count);
        saveToSessionStorage('emailOtpResendCount', response.data.email_otp_resend_count);
        showNotification(response.data.message, 'success');
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        showNotification(error.response.data.message, 'error');
      }
      else {
        showNotification('Error verifying OTP.', 'error');
      }
    }
  };

  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">
            {/* Id Card */}
            <div className="d-flex justify-content-center align-items-center col-sm-8 col-lg-5 col-xl-4 col-11">
              <IdCard formData={signupFormData} />
            </div>

            {/* Form Card - Email Verification */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Email Verification</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8', fontSize: "0.9rem" }}>Quick verification to keep your account secure</p>
                </div>
                <div className="">
                  <div className="card-body">
                  {!isChangingEmail ? (
                    <form className="text-start text-dark" onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-group my-4 d-flex align-items-center flex-column">
                        <div className="col-12 d-flex justify-content-between">
                            <p className="mb-0" style={{ fontSize: "0.9rem" }}>Enter the otp sent on {maskedEmail}</p>
                          </div>
                        <div className="col-12 otp-inputs d-flex justify-content-between my-2">
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              ref={(el) => (inputRefs.current[index] = el)}
                              type="text"
                              inputMode='numeric'
                              className="otp-input form-control mx-1 p-2 text-center"
                              id={`digit${index + 1}`}
                              value={digit}
                              onChange={(e) => handleOtpChange(e, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onPaste={handlePaste}
                              aria-label="OTP input"
                              maxlength="1"
                              min="0"
                              max="9"
                            />
                          ))}
                        </div>
                        <div className="col-12 d-flex justify-content-between">
                          <button className="btn btn-link">
                            <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={resendOtp}>Resend</p>
                          </button>
                          <button className="btn btn-link">
                            <p className="mb-0" style={{ fontSize: "0.9rem" }} onClick={handleChangeEmail}>Change email</p>
                          </button>
                        </div>
                      </div>
                      <div className="text-center mt-lg-3">
                        <button
                          type="submit" disabled={loading || isChangingEmail}
                          className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize"
                        >
                          {loading ? "Verifying..." : "Continue"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form> ) : (
                      <>
                      <form className="text-start text-dark" onSubmit={handleSubmit(updateEmail)}>
                        <div className="input-group my-4 d-flex flex-column">
                              <div className="col-12 d-flex justify-content-between my-4 mb-0">
                                <input
                                maxLength="51"
                                type="email"
                                className={`form-control mb-1 p-2 border rounded ${(errors.email) ? 'is-invalid' : ''}`}
                                placeholder="Email"
                                {...register('email', {
                                  required: 'Email is required',
                                  maxLength: {
                                    value: 50,
                                    message: 'Email cannot exceed 50 characters.',
                                  },
                                  pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Enter a valid company email (e.g., example@lendapi.com)',
                                  },
                                })}
                                onChange={handleEmailChange}
                              />
                              </div>
                              {errors.email && <p className="text-danger text-start" style={{marginBottom: "0.5rem"}}>{errors.email.message}</p>}
                              <div className="col-12 d-flex justify-content-between ">
                                <p role="link" className="btn btn-link mb-0" style={{ fontSize: "0.9rem" }} onClick={handleBack}>Go back</p>
                                <button type="submit" className="btn btn-link" style={{
                                  textDecoration: "none",
                                  padding: "0",
                                  color: "#6f4bd1",
                                  fontWeight: "normal",
                                  cursor: "pointer",
                                }}>
                                  Update email
                                </button>
                              </div>
                          </div>
                          <div className="text-center mt-lg-3">
                            <button
                              type="submit" disabled={loading || isChangingEmail}
                              className="btn btn-primary-sm btn-primary w-100 my-3 mb-3 text-capitalize"
                            >
                              {loading ? "Verifying..." : "Continue"}
                            </button>
                          </div>
                          <div className="mt-2">
                            <p className="text-sm fw-normal text-center mb-0">
                              Already have an account?
                              <Link to="/login" className="text-info ms-1">
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </form>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default EmailVerificationOTPForm;

