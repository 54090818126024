import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { usePlanContext } from '../../../context/PlanContext';
import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import paymentCards from "../../../img/payment-cards.png";
import tick from "../../../img/tick.gif";
import './Plans.css';
import { saveToSessionStorage, getFromSessionStorage } from '../Utils/utils';

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};


const formattedNumber = (number) => (new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(number));


const EnterpriseCard = ({ plan, setLoading, showNotification, showConfirmationCard, setShowConfirmationCard }) => {
  const { register, handleSubmit } = useForm();
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [signupFormData, setSignupFormData] = useState(null);

  // Get signup form data from sessionStorage
  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      const parsedData = savedFormData;
      setSignupFormData(parsedData);
    }
  }, []);

  const onSubmit = async (data) => {
    const sessionId = getFromSessionStorage('session_id');

    const requestData = {
      session_id: sessionId,
      mobile: signupFormData?.mobile,
      total_application: data.applications,
      use_case: data.useCase,
      timeline: data.timeline,
    };

    try {
      setLoading(true);
      const csrfToken = getCookie('csrftoken');
      const response = await axios.post("/signup/v1/plan_inquiry/", requestData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      });

      if (response.data.status === "success") {
        showNotification(response.data.message, "success");
        setShowConfirmationCard(true);
      } else {
        showNotification("Something went wrong. Please try again.", "error");
      }
    } catch (error) {
      showNotification("An error occurred. Please try again later.", "error");
    } finally {
      setLoading(false);
      sessionStorage.clear();
    }
  };

  return (
    <>
        {!showDropdowns ? (
          <>
          <div className="px-6 pt-4 pb-2">
            <p className="my-2 fw-normal" style={{ fontSize: "1.2rem" }}>
              {plan.featuresHeading}
            </p>
            <div className="card-body p-0">
              <ul className="list-disc p-0">
                {plan.features.map((feature, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-2"
                  >
                    <i
                      className="fa-solid fa-circle-check"
                      style={{
                        color: "#00c661",
                        fontSize: "1rem",
                        marginRight: "0.5em",
                      }}
                    ></i>
                    <li
                      className="list-unstyled"
                      style={{ fontSize: "0.95rem" }}
                    >
                      {feature}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
            </div>
            <div className="d-flex justify-content-center h-line col-8 my-2 position-absolute" style={{border: "0.8px solid #ddd", bottom: "8em", marginLeft: "50%", transform: "translate(-50%)"}}></div>

            <div className="col-12 px-6 position-absolute" style={{bottom: "1em"}}>
              <div className="d-flex justify-content-between">
                <button type="button" onClick={() => setShowDropdowns(true)} className="btn btn-primary btn-primary-sm my-2 col-12">
                Tell us more
                </button>
              </div>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 pt-4 pb-2">
            <div className="mb-3">
              <label htmlFor="applications" className="form-label">
                Number of Applications (annually)
              </label>
              <select
                className="w-100 form-select"
                id="applications"
                {...register("applications", { required: true })}
              >
                <option value="5000-10000">5,000 - 10,000</option>
                <option value="10001-20000">10,001 - 20,000</option>
                <option value="20001-40000">20,001 - 40,000</option>
                <option value="40000+">40,000 +</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="useCase" className="form-label">
                Use Case
              </label>
              <select
                className="w-100 form-select"
                id="useCase"
                {...register("useCase", { required: true })}
              >
                <option value="loan origination">Loan Origination</option>
                <option value="customer onboarding">Customer Onboarding</option>
                <option value="risk assessment">Risk Assessment</option>
                <option value="compliance management">Compliance Management</option>
                <option value="data analytics">Data Analytics</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="timeline" className="form-label">
                Timeline
              </label>
              <select
                className="w-100 form-select"
                id="timeline"
                {...register("timeline", { required: true })}
              >
                <option value="immediately">Immediately</option>
                <option value="winth-1-month">Within 1 month</option>
                <option value="1-3-months">1 - 3 months</option>
                <option value="3-6-months">3 - 6 months</option>
                <option value="6-plus-months">6+ months</option>
              </select>
            </div>
            </div>
            {/* <div className="d-flex justify-content-center h-line col-8 my-2 position-absolute" style={{border: "0.8px solid #ddd", bottom: "8em", marginLeft: "50%", transform: "translate(-50%)"}}></div> */}

            <div className="col-12 px-6 position-absolute" style={{bottom: "1em"}}>
              <div className="d-flex justify-content-between">
                <button type="button" onClick={() => setShowDropdowns(false)} className="btn btn-secondary btn-secondary-sm my-2 col-5">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary btn-primary-sm my-2 col-5">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
    </>
  );
};


const EnterpriseConfirmationCard = () => {

  return (
    <CardComponent className="mt-0 plan-card responsive-plan-card position-relative">
      <div className="col-12">
        <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
          <h3 className="main-title text-center mb-0">Thanks for choosing LendAPI</h3>
          <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8' }}>
          We are excited to partner with you
          </p>
        </div>
        <div className="">
          <div className="card-body d-flex flex-column">
            <img src={tick} alt="Confirmation tick" style={{width: "250px", display: "block", margin: "auto"}}/>
            <p style={{fontSize: "0.9rem", textAlign: "center"}}>Our team will reach out to you  to discuss your specific requirements and guide you through the onboarding process. In the meantime, feel free to explore our resources and prepare any questions you might have.</p>
          </div>
        </div>
      </div>
    </CardComponent>
  )
}


const RenderPlanDetails = ({planKey, toggleAnualPayValue, handleGetStarted, setLoading, showNotification, showConfirmationCard, setShowConfirmationCard}) => {
  const {plansData} = usePlanContext();
  const plan = plansData[planKey];
  return (
    <div className="col-12">
      <div className="bg-white px-6 card-header m-0" style={{paddingTop: "1.8em", paddingBottom: "1.8em"}}>
        {plan.badge ? <div className='py-1 px-2 mb-2 d-flex align-items-center' style={{backgroundColor: "#fff4e0", borderRadius: "15px", display: "inline-block", maxWidth: "fit-content"}}><i
                        className={plan.logo}
                        style={{
                          color: "#ffa000",
                          fontSize: `${plansData[planKey].logo ? "1rem" : ""}`,
                          marginRight: `${plansData[planKey].logo ? "0.3em" : ""}`,
                        }}
                      ></i>
                        <p className="plan-badge mb-0" style={{fontSize: "0.8rem", color: "#ffa000"}}>{plan.badge}</p>
                      </div> : ""}
        <h3 className="main-title" style={{fontSize: "1.875rem"}}>{plan.title}</h3>
        <p className="my-2 fw-normal" style={{ opacity: '0.8', fontSize: "0.95rem" }}>
          {plan.description}
        </p>
      </div>

      {planKey === "enterprise" ? (
        <EnterpriseCard plan={plan} setLoading={setLoading} showNotification={showNotification} showConfirmationCard={showConfirmationCard} setShowConfirmationCard={setShowConfirmationCard}/>) :
        <>
          <div className="px-6 pt-4 pb-2">
            <p className="my-2 fw-normal" style={{fontSize: "1.2rem"}}>{plan.featuresHeading}</p>
            <div className="card-body p-0">
              <ul className="list-disc p-0">
                {plan.features.map((feature, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <i className="fa-solid fa-circle-check" style={{
                      color:"#00c661",
                      fontSize:"1rem",
                      marginRight:"0.5em",
                    }}></i>
                    <li className="list-unstyled" style={{fontSize: "0.95rem"}}>{feature}</li>
                  </div>
                ))}
              </ul>
            </div>
          </div>

          <div className="d-flex justify-content-center h-line col-8 my-2 position-absolute" style={{border: "0.8px solid #ddd", bottom: "8em", marginLeft: "50%", transform: "translate(-50%)"}}></div>

          <div className="col-12 px-6 position-absolute" style={{bottom: "1em"}}>
            {planKey !== "enterprise" && <div className='d-flex align-items-center gap-2 mb-2'>
              <h4 className="mb-0">{toggleAnualPayValue ? formattedNumber(plan.discountPrice) : formattedNumber(plan.actualPrice)}</h4>
              <div className='d-flex flex-column opacity-75 justify-content-center' style={{fontSize: "0.7rem", opacity: "0.8", height: "35px", maxHeight: "100%"}}>
                {toggleAnualPayValue ? <span style={{textDecoration: "line-through", textDecorationColor: "#ff5f5f", textDecorationThickness: "1.5px"}}>{formattedNumber(plan.actualPrice)}</span> : ""}
                <span>{toggleAnualPayValue ? "/ month, paid yearly" : "/ month"}</span>
              </div>
            </div>}
              <button type="submit" onClick={handleGetStarted} id="get_started" className="btn btn-primary btn-primary-sm my-2 col-12">Get Started</button>
          </div>
        </>
      }
    </div>
  );
};

const ToggleForm = ({ toggleAnualPayValue, setToggleAnualPayValue, selectedPlan, discountPrice, actualPrice, onSubmit }) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      toggleSwitch: toggleAnualPayValue,
    },
  });

  const [isFocused, setIsFocused] = useState(false);

  // Watch the toggle switch state
  const currentToggleValue = watch('toggleSwitch');

  // Update parent state whenever the form's toggle switch state changes
  useEffect(() => {
    setToggleAnualPayValue(currentToggleValue);
    saveToSessionStorage("toggleAnualPayValue", currentToggleValue);
  }, [currentToggleValue, setToggleAnualPayValue]);

  // Helper function for formatting numbers
  const formattedNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(number);
  };

  return (
    <div className='col-12 pay-toggle-form'>
      <form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column justify-content-center align-items-center' style={{
      visibility: selectedPlan === "enterprise" ? 'hidden' : 'visible',
      opacity: selectedPlan === "enterprise" ? 0 : 1,
      height: selectedPlan === "enterprise" ? 'auto' : 'auto',
      transition: 'opacity 0.3s ease',
      }}>
        <div className="form-check form-switch mb-2">
          <input
            className={`form-check-input ms-0 ${isFocused ? 'is-focused' : ''}`}
            type="checkbox"
            id="toggleSwitch"
            {...register('toggleSwitch')}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setValue('toggleSwitch', e.target.checked)}
          />
          <label className="form-check-label" htmlFor="toggleSwitch">
            Pay Annually
          </label>
        </div>

        <div className="p-2" style={{ backgroundColor: "#ecf9f4", color: "#009D60", border: "1px solid #baead7", fontSize: "0.9rem" }}>
          Save <span className='font-weight-bold'>{formattedNumber((actualPrice - discountPrice) * 12)}</span> when billed annually
        </div>
      </form>
    </div>
  );
};

const Plans = () => {
  const navigate = useNavigate();
  const {plansData, toggleAnualPayValue, setToggleAnualPayValue, selectedPlan, setSelectedPlan, setStripePublishableKey, setProductID, setPriceID, setCustomerId } = usePlanContext();

  const [showConfirmationCard, setShowConfirmationCard] = useState(false);

  const priceIDKey = toggleAnualPayValue ? 'annual_price_id' : 'monthly_price_id';

  const sessionId = getFromSessionStorage('session_id');

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const fetchPriceIds = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/signup/v1/price_ids/', {});
      const { stripe_publishable_key, products } = response.data;

      if (stripe_publishable_key && products) {
        saveToSessionStorage('stripePublishableKey', stripe_publishable_key);
        setStripePublishableKey(stripe_publishable_key);

        const productKey = selectedPlan;
        const selectedProduct = products[productKey];

        if (selectedProduct) {
          const product_id = selectedProduct.product_id;
          const price_id = selectedProduct[priceIDKey];

          if (product_id && price_id) {
            setProductID(product_id);
            setPriceID(price_id);
          }
        } else {
          showNotification('Selected product not found in products.', "error");
        }
      } else {
        showNotification('Stripe publishable key or products data missing in response.', "error");
      }
    } catch (error) {
      showNotification('Error fetching price data.', "error");
    } finally {
      setLoading(false);
    }
  };


  // Create customer with session_id
  const createCustomer = async (sessionId) => {
    try {
      const response = await axios.post('/signup/v1/create_customer/', { session_id: sessionId });
      const { customer_id, status } = response.data;
      if (status === 'success') {
        saveToSessionStorage('customer_id', customer_id);
        showNotification("Customer created successfully.", "success");
        setCustomerId(customer_id);
        return customer_id;
      } else {
        showNotification("Error creating customer. Please try again.", "error");
        return null;
      }
    } catch (error) {
      showNotification("Error creating customer. Please try again.", "error");
      return null;
    }
  };

  const handleGetStarted = async () => {
    setLoading(true);
    await fetchPriceIds();

    // Wait until productID and stripePublishableKey are set
      try {
        const customer_id = await createCustomer(sessionId);
        if (customer_id) {
          navigate("/signup/v1/payment_method/");
        }
      } catch (error) {
        showNotification('Error during the Get Started flow.', "error");
      } finally {
        setLoading(false);
      }
  };


  // Handle form submission
  const handleFormSubmit = (data) => {
    setToggleAnualPayValue(data.toggleSwitch);
  };

  const handleSelectedPlan = (key) => {
    setSelectedPlan(key);
    saveToSessionStorage("planKey", key);
  }


  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center p-4">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">
            {/* id Card */}
            <div className="pay-card col-12 col-md-6 d-flex align-items-center justify-content-center">
              <div className="img">
                <img src={paymentCards} alt="Payment Cards" />
              </div>
            </div>

            <div className='d-flex col-12 col-md-6 my-2 mb-md-4 flex-column align-items-center justify-content-center'>
              {/* Plans Tab */}
              {!showConfirmationCard && <div className="plans-tab">
                <div
                  className="d-flex justify-content-between align-items-center gap-1 col-12 p-1"
                  style={{ backgroundColor: "#f3f4f7", borderRadius: "12px" }}
                >
                  {Object.keys(plansData).map((planKey) => (
                    <button
                      key={planKey}
                      onClick={() => handleSelectedPlan(planKey)}
                      className={`d-flex align-items-center justify-content-center ${selectedPlan === planKey ? 'bg-white' : ''}`}
                      style={{
                        flex: "1 1 0",
                        borderRadius: "8px",
                        border: "none",
                        padding: "0.6em",
                        boxShadow: `${selectedPlan === planKey ? "0px 1px 1px 1px rgba(0,0,0,0.06)" : ""}`
                      }}
                    >
                      <i
                        className={plansData[planKey].logo}
                        style={{
                          color: `${selectedPlan === planKey ? "#ffa000" : "#f7cf8a"}`,
                          fontSize: `${plansData[planKey].logo ? "1.2em" : ""}`,
                          marginRight: `${plansData[planKey].logo ? "0.3em" : ""}`,
                        }}
                      ></i>
                      <span style={{opacity: `${selectedPlan === planKey ? '1' : '0.5'}`}}>{plansData[planKey].title}</span>
                    </button>
                    ))}
                </div>
              </div>
              }
              {/* Plan Details */}
              {!showConfirmationCard ? (
                <>
                  <CardComponent className="mt-0 plan-card responsive-plan-card position-relative">
                    <RenderPlanDetails planKey={selectedPlan} toggleAnualPayValue={toggleAnualPayValue} handleGetStarted={handleGetStarted} setLoading={setLoading} showNotification={showNotification} showConfirmationCard={showConfirmationCard} setShowConfirmationCard={setShowConfirmationCard}/>
                  </CardComponent>
                  <ToggleForm toggleAnualPayValue={toggleAnualPayValue} selectedPlan={selectedPlan} actualPrice={plansData[selectedPlan].actualPrice} discountPrice={plansData[selectedPlan].discountPrice} setToggleAnualPayValue={setToggleAnualPayValue} onSubmit={handleFormSubmit}/>
                </>) : <EnterpriseConfirmationCard />}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default Plans;
