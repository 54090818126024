import {coborrower_schema} from "../utils/constants";
import {coborrower_ui_schema} from "../utils/constants";
import {widgetMapping} from "../utils/constants";

// eslint-disable-next-line
function escapeSpecialChars(id) {
    // List of special characters in CSS selectors that need to be escaped
    const specialChars = ['!', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', '\\', ']', '^', '`', '{', '|', '}', '~', '"'];
    let escapedId = '';

    for (let char of id) {
        if (specialChars.includes(char)) {
            escapedId += '\\' + char;
        } else {
            escapedId += char;
        }
    }

    return escapedId;
}

export function remove_element_by_class(cls) {
    var elements = document.getElementsByClassName(cls);
    while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
    }
}


function isEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!isEqual(obj1[key], obj2[key])) return false;
    }
    return true;
}


export function createBuilderSchema(inputFields) {
    const builderSchema = {
        groups:[],
        fields: [],
        widgets: {},
        Topcontrols: [],
        controls: [],
        required:[]
    };

    // Iterate over inputs and process them
    inputFields.forEach((input) => {
        if (!check_widgets_and_controls(input)) {
            const {id, name, text, prove_terms_url} = input;

            if (widgetMapping[id]) {
                builderSchema.widgets[widgetMapping[id]] = "true";
                return;
            }

            if (id === "prove") {
                builderSchema.widgets["prove"] = {"active": "true", "terms_url": prove_terms_url};
                return;
            }

            if (name === "Toplabel" || name === "rich_text_editor_top_control") {
                builderSchema.Topcontrols.push(input);
                return;
            }

            if (name === "Checkbox" || name === "Label" || id === "scroll_to_bottom" || name === "rich_text_editor_control") {
                if (text && text.includes("href")) {
                    input["text"] = text.replace(/"/g, "'");
                }
                builderSchema.controls.push(input);
                return;
            }

        } else {
            builderSchema.fields.push(input)
            if (input["group_label"] && !builderSchema.groups.includes(input["group_label"])) {
                builderSchema.groups.push(input["group_label"])
            }
            if (input["required"]) {
                if (!builderSchema.required.includes(input["id"])) {
                    builderSchema.required.push(input["id"]);
                }
            }
        }

    });

    // remove duplicates
    builderSchema.groups = [...new Set(builderSchema.groups)];

    return builderSchema;
}


//convert input fields to json data
export function create_input_elements_data(inputIds, keyId) {
    var current_tab = document.getElementById("justify-tab-example-tabpane-" + keyId)
    // Initialize an array to store the input data
    const inputElementsData = [];

    // Iterate through the input IDs
    inputIds.forEach((id) => {
        // Get the input element by ID
        const inputElement = current_tab.querySelector("#" + id);

        // Extract attributes
        let type = inputElement.getAttribute('type');
        let display_type = inputElement.getAttribute('display_type')
        let required = inputElement.getAttribute('data-isrequired') === 'true';
        let max_length = null
        let check_control_text = null
        let min_range = null
        let max_range = null
        let group_label = null
        let input_label = null
        let prove_terms_url = "#"
        let dependentField = inputElement.getAttribute('dependent-field');
        let dependentFieldOption = inputElement.getAttribute('dependent-field-option');
        let helperText = inputElement.getAttribute('helper-text');
        let prePopulate = inputElement.getAttribute('prepopulate');
        let disableOnPrePopulate = inputElement.getAttribute('disableonprepopulate');
        let maskField = inputElement.getAttribute('mask-field') && inputElement.getAttribute('mask-field') === "true"
        let dateRange = inputElement.getAttribute('date-range');

        if (inputElement.hasAttribute("maxlength")) {
            max_length = inputElement.getAttribute("maxlength");
        }
        if (inputElement.hasAttribute("data-text")) {
            check_control_text = inputElement.getAttribute("data-text");
        }
        if (inputElement.hasAttribute("minimum")) {
            min_range = inputElement.getAttribute("minimum");
        }
        if (inputElement.hasAttribute("maximum")) {
            max_range = inputElement.getAttribute("maximum");
        }
        if (inputElement.hasAttribute("group_label")) {
            group_label = inputElement.getAttribute("group_label");
        }
        if (inputElement.hasAttribute("name")) {
            input_label = inputElement.getAttribute("name");
        } else {
            input_label = id;
        }

        if (inputElement.hasAttribute("data-terms-url")) {
            prove_terms_url = inputElement.getAttribute("data-terms-url");
        }


        // Create an object for the current input element
        let inputData = {};

        if (type === "list") {
            const optionTexts = [];
            for (let i = 0; i < inputElement.options.length; i++) {
                optionTexts.push({value: inputElement.options[i].value, displayValue: inputElement.options[i].text});
            }
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "enum": optionTexts,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText
            }
        }
        else if (type === "boolean") {
            const optionTexts = [];
            for (let i = 0; i < inputElement.options.length; i++) {
                optionTexts.push(inputElement.options[i].text);
            }
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "enum": optionTexts,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate":prePopulate,
                "disableOnPrePopulate":disableOnPrePopulate,
            }
        } else if (inputElement.hasAttribute("data-text") && inputElement.getAttribute("type") === "toplabel") {
            check_control_text = inputElement.getAttribute("data-text");
            let label_font_size = inputElement.hasAttribute("label-font-size") ? inputElement.getAttribute("label-font-size") : ""
            let request_doc_id = inputElement.hasAttribute("request_doc_id") ? inputElement.getAttribute("request_doc_id") : ""
            let label_alignment = inputElement.hasAttribute("label-alignment") ? inputElement.getAttribute("label-alignment") : ""
            inputData = {
                "id": id,
                "name": "Toplabel",
                "type": type,
                "required": required ? "true" : "false",
                "text": check_control_text,
                "label_font_size": label_font_size,
                "request_doc_id": request_doc_id,
                "group_label": group_label,
                "input_label": input_label,
                "label_alignment": label_alignment

            }
        }  else if (inputElement.hasAttribute("data-text") && (inputElement.getAttribute("type") === "rich_text_editor_control" || inputElement.getAttribute("type") === "rich_text_editor_top_control")) {
            let editor_text = inputElement.getAttribute("data-text");
            inputData = {
                "id": id,
                "name": inputElement.getAttribute("type"),
                "type": type,
                "required": required ? "true" : "false",
                "text": editor_text,
            }
        } 
        else if (inputElement.hasAttribute("data-text")) {
            check_control_text = inputElement.getAttribute("data-text");
            let label_font_size = inputElement.hasAttribute("label-font-size") ? inputElement.getAttribute("label-font-size") : ""
            let request_doc_id = inputElement.hasAttribute("request_doc_id") ? inputElement.getAttribute("request_doc_id") : ""
            let label_alignment = inputElement.hasAttribute("label-alignment") ? inputElement.getAttribute("label-alignment") : ""
            inputData = {
                "id": id,
                "name": "Checkbox",
                "type": type,
                "required": required ? "true" : "false",
                "text": check_control_text,
                "label_font_size": label_font_size,
                "request_doc_id": request_doc_id,
                "group_label": group_label,
                "input_label": input_label,
                "label_alignment": label_alignment
            }
        } else if (type === "document_display") {
            let consent_doc_library = inputElement.hasAttribute("consent_doc_library") ? inputElement.getAttribute("consent_doc_library") : ""
            inputData = {
                "id": id,
                "name": "Document display",
                "type": type,
                "consent_doc_library": consent_doc_library,
            }
        }
        else if (type === "scroll_to_bottom") {
            let consent_doc_library = inputElement.hasAttribute("consent_doc_library") ? inputElement.getAttribute("consent_doc_library") : ""
            inputData = {
                "id": id,
                "name": "Scroll to bottom",
                "type": type,
                "input_label": input_label,
                "required": required ? "true" : "false",
                "text": input_label,
                "consent_doc_library": consent_doc_library,
            }
        }
        else if (type === "checkbox") {
            type = "boolean"
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "group_label": group_label
            }
        } else if (type === "date") {
            var format = "date"
            inputData = {
                "id": id,
                "type": "date",
                "required": required,
                "format": format,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate":prePopulate,
                "disableOnPrePopulate":disableOnPrePopulate,
                "date_range": dateRange
            }
        } else if (type === "numeric" && max_length !== null) {
            inputData = {
                "id": id,
                "type": "number",
                "required": required,
                "maxLength": max_length,
                "group_label": group_label,
                "input_label": input_label,
                "helperText": helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "numeric" && max_range !== null && min_range !== null) {
            inputData = {
                "id": id,
                "type": "number",
                "required": required,
                "minimum": min_range,
                "maximum": max_range,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "numeric" && min_range !== null) {
            inputData = {
                "id": id,
                "type": "number",
                "required": required,
                "minimum": min_range,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "numeric" && max_range !== null) {
            inputData = {
                "id": id,
                "type": "number",
                "required": required,
                "maximum": max_range,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "numeric") {
            inputData = {
                "id": id,
                "type": "number",
                "required": required,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "string" && max_length !== null) {
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "maxLength": max_length,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "maskField":maskField,
                "display_type": display_type
            }
        } else if (type === "widget" && prove_terms_url !== null) {
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "maxLength": max_length,
                "group_label": group_label,
                "input_label": input_label,
                "prove_terms_url": prove_terms_url,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
            }
        } else {
            inputData = {
                "id": id,
                "type": type,
                "required": required,
                "group_label": group_label,
                "input_label": input_label,
                "helperText":helperText,
                "prePopulate": prePopulate,
                "disableOnPrePopulate": disableOnPrePopulate,
                "display_type": display_type
            }
        }

        if (dependentField)
        {
            inputData["dependentField"] = dependentField
            inputData["dependentFieldOption"] = [dependentFieldOption]
        }
        // Push the inputData object to the array
        inputElementsData.push(inputData);
    });
    return inputElementsData;
}


function check_widgets_and_controls(schema_input) {
    var check_array = ["approve", "decline", "review", "agreement", "offer_agreement", "plaid_auth", "plaid_asset", "flinks_and_welspot", "loan_offers", "multi_offers", "multi_agreements", "plaid_cra_base", "prove", "plaid_cra_income", "prequalification_offer", "mobile_otp", "decision_logic", "plaid_cra_partner", "payment_schedule_widget", "product_order_widget", "payroll_selection_widget", "chirp_verification", "enervee_loan_offer_widget", "payment_method_widget", "scroll_to_bottom", "invoice_widget", "conductive_widget", "google_geo_location_widget", "google_place_widget"]

    if (check_array.includes(schema_input['id'])) {
        return false
    } else if (schema_input["name"] === "Toplabel" || schema_input["name"] === "rich_text_editor_top_control") {
        return false;
    } else if (schema_input["name"] === "Checkbox" || schema_input["name"] === "Label" || schema_input["name"] === "rich_text_editor_control") {
        return false;
    }

    return true
}


//convert form to json schema
export function convertInputDataToSchema(inputs, db_schema) {
    const schema = {
        schema: {},
        widgets: {},
        Topcontrols: [],
        controls: []
    };

    let group_labels = []
    inputs.forEach((input) => {
        if (input["group_label"] && !group_labels.includes(input["group_label"])) {
            group_labels.push(input["group_label"])
        }
    })

    let result = {
        type: 'object',
        properties: {}
    };


    var dummy = {}
    var outer_required = []


    if (group_labels.length === 0) {
        inputs.forEach(item => {
            let schema_input = item;

            if (!check_widgets_and_controls(schema_input)) {
                return;
            }

            dummy[schema_input["id"]] = {};
            if ("enum" in schema_input) {
                dummy[schema_input["id"]]["enum"] = schema_input["enum"]
            }

            if ("maxLength" in schema_input) {
                dummy[schema_input["id"]]["maxLength"] = parseInt(schema_input["maxLength"])
            }
            if ("minimum" in schema_input) {
                dummy[schema_input["id"]]["minimum"] = parseInt(schema_input["minimum"])
            }
            if ("maximum" in schema_input) {
                dummy[schema_input["id"]]["maximum"] = parseInt(schema_input["maximum"])
            }
            if ("input_label" in schema_input) {
                dummy[schema_input["id"]]["label"] = schema_input["input_label"]
            } else {
                dummy[schema_input["id"]]["label"] = ""
            }

            if (schema_input["required"]) {
                if (!outer_required.includes(schema_input["id"])) {
                    outer_required.push(schema_input["id"]);
                }
            }
            dummy[schema_input["id"]]["type"] = schema_input["type"]
        })
    }

    group_labels.forEach(label => {
        // Iterate over each element in the dataArray
        let temp_result = {
            label: {
                properties: {},
                required: []
            }
        }
        inputs.forEach((item, index) => {
            if (item["group_label"] && label === item['group_label']) {
                temp_result.label["title"] = item["group_label"]

                let schema_input = item;

                if (!check_widgets_and_controls(schema_input)) {
                    return;
                }

                temp_result.label.properties[schema_input["id"]] = {};
                if ("enum" in schema_input) {
                    temp_result.label.properties[schema_input["id"]]["enum"] = schema_input["enum"]
                }

                if ("maxLength" in schema_input) {
                    temp_result.label.properties[schema_input["id"]]["maxLength"] = parseInt(schema_input["maxLength"])
                }
                if ("minimum" in schema_input) {
                    temp_result.label.properties[schema_input["id"]]["minimum"] = parseInt(schema_input["minimum"])
                }
                if ("maximum" in schema_input) {
                    temp_result.label.properties[schema_input["id"]]["maximum"] = parseInt(schema_input["maximum"])
                }
                if ("input_label" in schema_input) {
                    temp_result.label.properties[schema_input["id"]]["label"] = schema_input["input_label"]
                } else {
                    temp_result.label.properties[schema_input["id"]]["label"] = ""
                }
                temp_result.label.properties[schema_input["id"]]["type"] = schema_input["type"]

                if (schema_input["required"]) {
                    if (!temp_result.label.required.includes(schema_input["id"])) {
                        temp_result.label.required.push(schema_input["id"]);
                    }
                }

                temp_result.label["type"] = 'object'
                dummy[label] = temp_result.label

            } else if (!item["group_label"]) {
                let schema_input = item;

                if (!check_widgets_and_controls(schema_input)) {
                    return;
                }

                dummy[schema_input["id"]] = {};
                if ("enum" in schema_input) {
                    dummy[schema_input["id"]]["enum"] = schema_input["enum"]
                }

                if ("maxLength" in schema_input) {
                    dummy[schema_input["id"]]["maxLength"] = parseInt(schema_input["maxLength"])
                }
                if ("minimum" in schema_input) {
                    dummy[schema_input["id"]]["minimum"] = parseInt(schema_input["minimum"])
                }
                if ("maximum" in schema_input) {
                    dummy[schema_input["id"]]["maximum"] = parseInt(schema_input["maximum"])
                }
                if ("input_label" in schema_input) {
                    dummy[schema_input["id"]]["label"] = schema_input["input_label"]
                } else {
                    dummy[schema_input["id"]]["label"] = ""
                }

                if (schema_input["required"]) {
                    if (!outer_required.includes(schema_input["id"])) {
                        outer_required.push(schema_input["id"]);
                    }
                }
                dummy[schema_input["id"]]["type"] = schema_input["type"]
            }
        })
    })


    result.properties = dummy
    result["required"] = outer_required


    schema.schema = result
    let has_coborrower = false


    inputs.forEach((input) => {
        let schema_input = input;

        if (schema_input["id"] === "has_coborrower") {
            has_coborrower = true
        }

        if (schema_input["id"] === "has_coborrower" && !db_schema.schema.properties.hasOwnProperty('has_coborrower')) {
            schema.schema.properties["CoBorrower"] = coborrower_schema
        }

        if (schema_input["id"] === "approve") {
            schema.widgets["approve"] = "true";
            return;
        } else if (schema_input["id"] === "decline") {
            schema.widgets["decline"] = "true";
            return;
        } else if (schema_input["id"] === "review") {
            schema.widgets["review"] = "true";
            return;
        } else if (schema_input["id"] === "agreement") {
            schema.widgets["agreement"] = "true";
            return;
        } else if (schema_input["id"] === "offer_agreement") {
            schema.widgets["offer_agreement"] = "true";
            return;
        } else if (schema_input["id"] === "plaid_auth") {
            schema.widgets["plaid_auth"] = "true";
            return;
        } else if (schema_input["id"] === "plaid_asset") {
            schema.widgets["plaid_asset"] = "true";
            return;
        } else if (schema_input["id"] === "plaid_cra_partner") {
            schema.widgets["plaid_cra_partner"] = "true";
            return;
        } else if (schema_input["id"] === "payment_schedule_widget") {
            schema.widgets["payment_schedule_widget"] = "true";
            return;
        } else if (schema_input["id"] === "flinks_and_welspot") {
            schema.widgets["flinks_and_welspot"] = "true";
            return;
        } else if (schema_input["id"] === "loan_offers") {
            schema.widgets["loan_offers"] = "true";
            return;
        } else if (schema_input["id"] === "multi_offers") {
            schema.widgets["multi_offers"] = "true";
            return;
        } else if (schema_input["id"] === "multi_agreements") {
            schema.widgets["multi_agreements"] = "true";
            return;
        } else if (schema_input["id"] === "product_order_widget") {
            schema.widgets["product_order_widget"] = "true";
            return;
        } else if (schema_input["id"] === "prove") {
            schema.widgets["prove"] = {"active": "true", "terms_url": schema_input['prove_terms_url']};
            return;
        } else if (schema_input["id"] === "plaid_cra_base") {
            schema.widgets["plaid_cra_base"] = "true";
            return;
        } else if (schema_input["id"] === "plaid_cra_income") {
            schema.widgets["plaid_cra_income"] = "true";
            return;
        } else if (schema_input["id"] === "prequalification_offer") {
            schema.widgets["prequalification_offer"] = "true";
            return;
        } else if (schema_input["id"] === "decision_logic") {
            schema.widgets["decision_logic"] = "true";
            return;
        } else if (schema_input["id"] === "payment_screen") {
            schema.widgets["payment_screen"] = "true";
            return;
        } else if (schema_input["id"] === "mobile_otp") {
            schema.widgets["mobile_otp"] = "true";
            return;
        } else if (schema_input["id"] === "payroll_selection_widget") {
            schema.widgets["payroll_selection_widget"] = "true";
            return;
        } else if (schema_input["name"] === "Toplabel") {
            schema.Topcontrols.push(schema_input);
            return;
        }
        else if (schema_input["id"] === "chirp_verification") {
            schema.widgets["chirp_verification"] = "true";
            return;
        } 
        else if (schema_input["name"] === "Checkbox" || schema_input["name"] === "Label") {
            if (schema_input["text"] && schema_input["text"].includes("href")) {
                schema_input["text"] = schema_input["text"].replace(/"/g, "'");
            }
            schema.controls.push(schema_input);
            return;
        }
    });

    if (!has_coborrower) {
        delete schema.schema.properties["CoBorrower"]
    }

    return schema;
}


//for UI schema
export const createSchemaFromInputData = (inputData, db_schema) => {

    const schema = {
        type: 'VerticalLayout',
        elements: [],
    };

    const array = ["offer_agreement", "agreement", "approve", "decline", "review", "checkbox_control", "label_control", "plaid_auth", "plaid_asset", "flinks_and_welspot", "loan_offers", "multi_offers", "multi_agreements", "plaid_cra_base", "payment_screen", "prove", "plaid_cra_income", "prequalification_offer", "mobile_otp", "decision_logic", "plaid_cra_partner", "payment_schedule_widget", "product_order_widget", "payroll_selection_widget", "chirp_verification"]

    inputData = inputData.filter(item => !array.includes(item.id));
    inputData = inputData.filter(item => !["Checkbox", "Toplabel"].includes(item.name));


    let group_labels = []
    inputData.forEach((input) => {
        if (input["group_label"] && !group_labels.includes(input["group_label"])) {
            group_labels.push(input["group_label"])
        }
    })

    var dummy = {}
    const group1 = {
        type: 'Group',
        elements: [],
    };

    if (group_labels.length === 0) {
        let horizontalLayout = {
            type: 'HorizontalLayout',
            elements: [],
        };
        inputData.forEach(item => {

            if (horizontalLayout.elements.length === 2) {
                group1.elements.push(horizontalLayout)

                horizontalLayout = {
                    type: 'HorizontalLayout',
                    elements: [],
                };
            }
            // Add the first element
            const control1 = {
                type: 'Control',
                scope: `#/properties/${item.id}`,
            };

            if (item.label) {
                control1.label = item.label;
            }
            if (item.input_label) {
                control1.label = item.input_label;
            }

            if (item.dependentField && item.dependentFieldOption) {
                control1.rule = {
                    "effect": "SHOW",
                    "condition": {
                        "scope": `#/properties/${item.dependentField}`,
                        "schema": {
                            "const": `${item.dependentFieldOption}`
                        }
                    }
                }
            }
            if (item && "format" in item && item.format === "date" && item.id !== "procedure_date") {
                control1.options = {
                    "format": "date",
                    "clearLabel": "Clear it!",
                    "cancelLabel": "Abort",
                    "okLabel": "Do it",
                    "dateFormat": "MM/DD/YYYY",
                    "dateSaveFormat": "YYYY/MM/DD",
                }
            }

            horizontalLayout.elements.push(control1)

        })
        group1.elements.push(horizontalLayout)
    }

    var cnt = 1
    group_labels.forEach((label, index) => {

        const group = {
            type: 'Group',
            elements: [],
        };

        let horizontalLayout = {
            type: 'HorizontalLayout',
            elements: [],
        };

        let horizontalLayout1 = {
            type: 'HorizontalLayout',
            elements: [],
        };

        inputData.forEach(item => {

            group["label"] = label


            if (label === item["group_label"]) {
                if (horizontalLayout.elements.length === 2) {
                    group.elements.push(horizontalLayout)

                    horizontalLayout = {
                        type: 'HorizontalLayout',
                        elements: [],
                    };
                }
                // Add the first element
                const control1 = {
                    type: 'Control',
                    scope: `#/properties/${label}/properties/${item.id}`,
                };

                if (item.label) {
                    control1.label = item.label;
                }
                if (item.input_label) {
                    control1.label = item.input_label;
                }
                if (item.dependentField && item.dependentFieldOption) {
                    control1.rule = {
                        "effect": "SHOW",
                        "condition": {
                            "scope": `#/properties/${item.dependentField}`,
                            "schema": {
                                "const": `${item.dependentFieldOption}`
                            }
                        }
                    }
                }
                if (item && "format" in item && item.format === "date" && item.id !== "procedure_date") {
                    control1.options = {
                        "format": "date",
                        "clearLabel": "Clear it!",
                        "cancelLabel": "Abort",
                        "okLabel": "Do it",
                        "dateFormat": "MM/DD/YYYY",
                        "dateSaveFormat": "YYYY/MM/DD",
                    }
                }

                horizontalLayout.elements.push(control1)


            } else if (!item['group_label'] && index === 0) {

                if (horizontalLayout1.elements.length === 2) {
                    group1.elements.push(horizontalLayout1)

                    horizontalLayout1 = {
                        type: 'HorizontalLayout',
                        elements: [],
                    };
                }
                // Add the first element
                const control1 = {
                    type: 'Control',
                    scope: `#/properties/${item.id}`,
                };


                if (item.label) {
                    control1.label = item.label;
                }
                if (item.input_label) {
                    control1.label = item.input_label;
                }
                if (item.dependentField && item.dependentFieldOption) {
                    control1.rule = {
                        "effect": "SHOW",
                        "condition": {
                            "scope": `#/properties/${item.dependentField}`,
                            "schema": {
                                "const": `${item.dependentFieldOption}`
                            }
                        }
                    }
                }
                if (item && "format" in item && item.format === "date" && item.id !== "procedure_date") {
                    control1.options = {
                        "format": "date",
                        "clearLabel": "Clear it!",
                        "cancelLabel": "Abort",
                        "okLabel": "Do it",
                        "dateFormat": "MM/DD/YYYY",
                        "dateSaveFormat": "YYYY/MM/DD",
                    }
                }

                horizontalLayout1.elements.push(control1)
            }


        })
        group.elements.push(horizontalLayout)
        if (cnt === 1) {
            group1.elements.push(horizontalLayout1)
            cnt = 2
        }
        dummy[label] = group
    })


    dummy = {...dummy, ...group1}
    var looped = false
    for (let key in dummy) {
        if (group_labels.includes(key)) {
            schema.elements.push(dummy[key])
        } else if (!looped) {
            schema.elements.push(group1)
            looped = true
        }
    }

    let has_coborrower = false
// check for coborrower
    inputData.forEach(item => {

        if (item["id"] === "has_coborrower") {
            has_coborrower = true
        }

        if (item["id"] === "has_coborrower" && !db_schema.schema.properties.hasOwnProperty('has_coborrower')) {
            has_coborrower = true
            schema.elements.push(coborrower_ui_schema)
            return
        }
    })

    if (!has_coborrower) {
        schema.elements = schema.elements.filter(function (dict) {
            return !isEqual(dict, coborrower_ui_schema);
        });
    }

    return schema;
};

export const getTestModeCookieValue = () => {

    // check for test mode in url as well.
    const urlParams = new URLSearchParams(window.location.search);
    const testModeParam = urlParams.get('test_mode');
    if (testModeParam === '1' || testModeParam === 'true') {
        return true;
    }
    if (testModeParam === '0' || testModeParam === 'false') {
        return false;
    }

    // Check for test_mode in cookies
    const value = document.cookie.split('; ').find(row => row.startsWith('test_mode='));
    return true ? value && value.split('=')[1] : false;
};