
import React, { useEffect, useState } from 'react';

const GoolgeGeoLocationWidget = ({
    apiKey,
    apiUrl,
    setFormData,
    formData
}) => {
    const [showZipAlert, setShowZipAlert] = useState(false);

    useEffect(() => {
        const geoZipCode = formData['zip_code'];

        if (!geoZipCode) {
            setShowZipAlert(false);
            return;
        }

        if (geoZipCode?.length > 4) {
            setShowZipAlert(false);
            const uri = `${apiUrl}api/geocode/json?address=${geoZipCode}&key=${apiKey}`;

            fetch(uri)
                .then(response => response.json())
                .then((data) => {
                    const locArray = data['results'][0]['address_components'];

                    const cityComponent = locArray.find((component) =>
                        component.types.includes("locality")
                    );
                    const cityName = cityComponent?.long_name || "Unknown";

                    const stateComponent = locArray.find((component) =>
                        component.types.includes("administrative_area_level_1")
                    );
                    const stateName = stateComponent?.short_name || "Unknown";

                    setFormData((prevData) => ({
                        ...prevData,
                        zip_code: geoZipCode,
                        city: cityName,
                        state: stateName,
                    }));
                })
                .catch(error => console.log(error));
        } else {
            setShowZipAlert(true);
        }

    }, [formData['zip_code']]);

    return (
        <div className='google_place_widget'  style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
            <div className="col-12" style={{ textAlign: "initial" }}>
                <div
                    className="col-6"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        width: "100%",
                    }}
                >
                    <div className='col-6' style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <label style={{
                            fontWeight: "bold",
                            color: "var(--text-tertiary, #4A5365)",
                            fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
                            fontSize: "var(--Font-Size-text-xs, 12px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-Height-text-xs, 16px)",
                        }}>
                            ZIP <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="number"
                            placeholder="12345"
                            className="form-control"
                            style={{
                                padding: "8px",
                                borderRadius: "var(--Radius-sm, 6px)",
                                border: "1px solid var(--border-primary, #EAECF0)",
                                background: "#FFF",
                                marginTop: 0,
                            }}
                            value={formData['zip_code'] || ''}
                            onChange={(e) => setFormData({ ...formData, zip_code: e.target.value })}
                        />

                        {showZipAlert ?
                            <span id='zipTagInfo' style={{
                                color: "var(--text-placeholder, #BE3730)",
                                fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
                                fontSize: "var(--Font-Size-text-xs, 12px)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "var(--Line-Height-text-xs, 16px)",
                            }}>Enter a 5-digit zip code</span>
                            :
                            <span id='zipTagInfo' style={{
                                color: "var(--text-placeholder, #9AA1B2)",
                                fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
                                fontSize: "var(--Font-Size-text-xs, 12px)",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "var(--Line-Height-text-xs, 16px)",
                            }}>Enter zip code to update</span>
                        }
                    </div>
                </div>

                <div
                    className="col-6 pt-2"
                    style={{
                        display: "flex",
                        gap: "16px",
                        marginTop: "16px",
                        width: "100%",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            flex: 1,
                        }}>
                        <label style={{
                            fontWeight: "bold",
                            color: "var(--text-tertiary, #4A5365)",
                            fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
                            fontSize: "var(--Font-Size-text-xs, 12px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-Height-text-xs, 16px)",
                        }}>City</label>
                        <input
                            type="text"
                            placeholder="Los Angeles"
                            className="form-control"
                            style={{
                                padding: "8px",
                                width: "100%",
                                borderRadius: "var(--Radius-sm, 6px)",
                                border: "1px solid var(--border-primary, #EAECF0)",
                                background: "#FFF",
                                marginTop: 0,
                            }}
                            value={formData['city'] || ''}
                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            flex: 1,
                        }}
                    >
                        <label style={{
                            fontWeight: "bold",
                            color: "var(--text-tertiary, #4A5365)",
                            fontFamily: 'var(--Font-Family-family, "Open Sauce Sans")',
                            fontSize: "var(--Font-Size-text-xs, 12px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-Height-text-xs, 16px)",
                        }}>State</label>
                        <input
                            type="text"
                            placeholder="CA"
                            className="form-control"
                            style={{
                                padding: "8px",
                                width: "100%",
                                borderRadius: "var(--Radius-sm, 6px)",
                                border: "1px solid var(--border-primary, #EAECF0)",
                                background: "#FFF",
                                marginTop: 0,
                            }}
                            value={formData['state'] || ''}
                            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoolgeGeoLocationWidget;
