import {useState, useEffect} from 'react';
import * as React from "react";
import axios from 'axios';
import LoadingSpinner from './loading_spinner';
import { getTestModeCookieValue } from '../../utils/global_function';
import BuilderForm from './BuilderForm';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const combinedCredentials = window.api_username + ":" + window.api_password;
const ApiCredentials = btoa(combinedCredentials);
const test_mode_val = getTestModeCookieValue()

function PreviewWizard({label}) {
    const [builder_schema, setBuilderSchema] = useState({})

    /* eslint-disable-next-line  */
    const [formData, setFormData] = useState([]);
    /* eslint-disable-next-line  */
    const [requiredfields, setRequiredFields] = useState([]);
    const [offerwidget, setOfferWidget] = useState("")
    const [agreementwidget, setAgreementWidget] = useState(false)
    const [reviewwidget, setReviewWidget] = useState(false)
    const [approvewidget, setApproveWidget] = useState(false)
    const [declinewidget, setDeclineWidget] = useState(false)
    const [bookedwidget, setbookedwidget] = useState(false)
    /* eslint-disable-next-line  */
    const [ischeckbox, setCheckbox] = useState(false)
    const [controls, setControls] = useState([]);
    const [Topcontrols, setTopControls] = useState([]);
    const [documentControl, setDocumentControl] = useState([]);
    /* eslint-disable-next-line  */
    const [buttonClicked, setButtonClicked] = useState(false);
    /* eslint-disable-next-line  */
    const [welspotFormData, setwelspotFormData] = useState({});
    // eslint-disable-next-line
    const [flinkwidget, setFlinkWidget] = useState(false);
    const [loanoffers, setLoanOffers] = useState(false);
    const [multiOffers, setMultiOffers] = useState(false);
    const [multiagreements, setMultiAgreements] = useState(false);
    const [currentLabel, setcurrentLabel] = useState(label);
    const [PrequalificationOffer, setPrequalificationOffer] = useState("")
    /* eslint-disable-next-line  */
    const [showErrors, setShowErrors] = useState(false);
    /* eslint-disable-next-line  */
    const [formErrors, setFormErrors] = useState({});


    const fetchData = async (tab_label=null) => {
        if(tab_label && tab_label === currentLabel){
            return
        }
        if(!tab_label){
            tab_label=currentLabel
        }
        setcurrentLabel(tab_label)
        // setFlinkWidget(false)
        setOfferWidget(false)
        setAgreementWidget(false)
        setReviewWidget(false)
        setApproveWidget(false)
        setDeclineWidget(false)
        setbookedwidget(false)
        setLoanOffers(false)
        setMultiOffers(false)
        setMultiAgreements(false)
        setTopControls([])
        setControls([])
        setBuilderSchema({})
        try {
            const response = await axios.get(`/api/v1/get_schema/?product_referral=${window.product_referral}&label=${tab_label}&tenant_code=${window.tenant_code}&test_mode=${test_mode_val}&is_preview=${true}`,
                {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                        },
                });

            var resp = response.data.results.template_tab.builder_schema[0]


            // for (const key in patientData) {
            //     if(resp.schema && resp.schema.properties)
            //     {
            //         for (let key1 in resp.schema.properties)
            //         {
            //             if (typeof resp.schema.properties[key1] === 'object' && resp.schema.properties[key1] !== null && resp.schema.properties[key1].properties) {
            //                if (resp.schema.properties[key1].properties.hasOwnProperty(key))
            //                 {
            //                     if (!patient_relation_data[key1]) {
            //                         patient_relation_data[key1] = {};
            //                     }
            //                     patient_relation_data[key1][key] = patientData[key];
            //                 }
            //             }
            //             else if (resp.schema.properties.hasOwnProperty(key)) {
            //                 patient_relation_data[key] = patientData[key];
            //             }
            //         }
            //     }
            //     setpatientRelation(patient_relation_data)
            // }

            if (resp.widgets) {
                // if ("plaid_asset" in resp.widgets)
                // {
                //     window.plaid_asset(localStorage.getItem("session_id"), window.tenant_code, window.product_type, tab_label);
                // }
                // if ("plaid_auth" in resp.widgets)
                // {
                //     window.plaid_auth(localStorage.getItem("session_id"), window.tenant_code, window.product_type, response.data.results.engine[0].next_step);
                // }
                // if ("flinks_and_welspot" in resp.widgets)
                // {
                //     window.flinks_connect(localStorage.getItem("session_id"), window.tenant_code, window.product_type, response.data.results.engine[0].next_step);
                //     setFlinkWidget(true)
                // }
                if ("offer_agreement" in resp.widgets) {
                    setOfferWidget(resp.widgets.offer_agreement)
                }
                if ("prequalification_offer" in resp.widgets) {
                    setPrequalificationOffer(resp.widgets.prequalification_offer)
                }
                if ("agreement" in resp.widgets) {
                    setAgreementWidget(true)
                }
                if ("review" in resp.widgets) {
                    setReviewWidget(true)
                }
                if ("approve" in resp.widgets) {
                    setApproveWidget(true)
                }
                if ("decline" in resp.widgets) {
                    setDeclineWidget(true)
                }
                if ("booked" in resp.widgets) {
                    setbookedwidget(true)
                }
                if ("loan_offers" in resp.widgets)
                {
                    setLoanOffers(true)
                }
                if ("multi_offers" in resp.widgets)
                {
                    setMultiOffers(true)
                }
                if ("multi_agreements" in resp.widgets)
                {
                    setMultiAgreements(resp.widgets.multi_agreements)
                }
            }
            let request_doc_ids = []
            if (response.data.results.template_tab.controls) {
                setControls(response.data.results.template_tab.controls)

                let template_page_control = response.data.results.template_tab.controls
                for (let index = 0; index<template_page_control.length; index++)
                {
                    if(template_page_control[index]["request_doc_id"] && template_page_control[index]["request_doc_id"] !== "")
                    {
                        request_doc_ids.push(template_page_control[index]["request_doc_id"])
                    }
                }
            }
            if (response.data.results.template_tab.Topcontrols) {
                setTopControls(response.data.results.template_tab.Topcontrols)
            }




            //todo : api call to save tab
            axios.get(`/api/v1/get_document_group/?request_doc_ids=${JSON.stringify(request_doc_ids)}&test_mode=${test_mode_val}`,
                {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                // setLoading(false);
                setDocumentControl(response.data.results)

            }).catch(function (error) {
                setDocumentControl(false)
                return false
            });

            setBuilderSchema(response.data.results.template_tab.builder_schema[0])
            // Handle the response data here
        } catch (error) {
            console.log("Error fetching data:", error);
            // window.show_alert(error.response.data.message)
        }
    };



    // Call fetchData on component mount
    useEffect(() => {
        fetchData();
        try {
            document.getElementById('#/properties/password-input').type = 'password';
        } catch {
            console.log('password input not found')
        }
        if (label === "review") {
            console.log('review')
            setReviewWidget(true)
        } else if (label === "approve") {
            setApproveWidget(true)
        }
        else if (label === "decline") {
            setDeclineWidget(true)
        }
        else if (label === "booked") {
            setbookedwidget(true)
        }

    // eslint-disable-next-line
    }, []);




    useEffect(() => {
        var elements = document.getElementsByClassName("check-control-iframe");
        console.log(elements)
        var myFunction = function () {
            var attribute = this.getAttribute("data-doc-id");
            alert(attribute);
        };

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', myFunction, false);
        }

    }, []);

    function generateRandomId() {
        // This is a simple example, and you might want to use a more robust random number generation method
        return Math.random().toString(36).substring(7);
    }



    function upload_request_doc(event)
    {
        const changedElement = event.target;
        // Get the parent element
        let file_name = changedElement.parentNode.querySelector(".file_name");
        file_name.innerText = event.target.files[0].name

        let label = changedElement.parentNode.querySelector("label")
        label.classList.add("uploading")

        var formData = new FormData()
        formData.append('files', event.target.files[0]);
        formData.append('app_doc_id', label.getAttribute("data-id"))
        formData.append('test_mode', test_mode_val)

        try {

            axios.post(`/application/doc_upload_ajax/${localStorage.getItem("session_id")}/`,
            formData, {
                    headers: {
                        Authorization: "Basic " + ApiCredentials
                    },
                }
            ).then(function (response) {
                window.show_success("Uploaded successfully")
                label.classList.remove("uploading")

            }).catch(function (error) {
                window.show_alert("Something went wrong. Please try again");
                return false
            });

        } catch (error) {
            window.show_alert("Something went wrong. Please try again");
            return false
        }
    }


    function selectCard(cardNumber, event) {
        event.preventDefault();
        // Remove 'selected' class from all cards
        document.querySelectorAll('.card').forEach(card => {
            card.classList.remove('selected');
        });

        // Add 'selected' class to the clicked card
        document.querySelector(`#Loanoffers:nth-child(${cardNumber}) .card`).classList.add('selected');
    }

    function selectofferAgreement(event, offer_type) {
        event.preventDefault();

        if(offer_type && offer_type === 'loan') {
            Array.from(document.querySelectorAll('.personal_loan_div .selectedoffer')).forEach(
                (el) => el.classList.remove('selectedoffer')
            );
        }
        // Check if the button has the selectedoffer class
        if (event.target.classList.contains('selectedoffer')) {
            // If it has, remove the selectedoffer class
            event.target.classList.remove('selectedoffer');
        } else {
            // If it doesn't have, add the selectedoffer class
            event.target.classList.add('selectedoffer');
        }
    }


    function handleChange(e) {
        const fileInputValues = {};
        document.querySelectorAll('.file_name').forEach((input) => {
            fileInputValues[input.getAttribute("name")] = input.innerText;
          });

        try {
            document.getElementById('#/properties/password-input').type = 'password';
        } catch {
            console.log('password input not found')
        }

        setFormData(e.data)


        var required_data = []
        setRequiredFields(required_data)

        var continue_btn = document.getElementById("continue_submit_btn")
        if (e?.errors?.length > 0 && !offerwidget && !multiagreements && builder_schema?.fields && Object.keys(builder_schema.fields).length > 0){
            if (continue_btn)
            {
                continue_btn.setAttribute("disabled",true)
            }
        } else {
            if (continue_btn)
            {
                continue_btn.removeAttribute("disabled",true)
            }
        }

        try {

            Object.keys(fileInputValues).forEach((fieldName) => {
                const inputValue = fileInputValues[fieldName];
                document.querySelector(`span[name=${fieldName}]`).innerText = inputValue;
              });
        } catch (error) {
            console.log('Something went wrong')
        }

        setTimeout(() => {
            try {

                var elements = document.querySelectorAll('.MuiIconButton-root');

                // Convert the NodeList to an array and iterate through each element to remove it
                Array.from(elements).forEach(function(element) {
                    if (!element.classList.contains("MuiAutocomplete-popupIndicator"))
                        element.style.display = 'none';
                });

                document.getElementById("#/properties/password").querySelector(".MuiFormHelperText-root").style.display = "none";
                var paragraphElement = document.createElement("p");

                // Set the text content of the element for password
                paragraphElement.textContent = "Eight or more characters, including upper and lowercase letters and at least one number";
                paragraphElement.id = "paragraph-hint"

                if (!document.getElementById("paragraph-hint"))
                {
                    document.getElementById("#/properties/password").appendChild(paragraphElement)
                }
            } catch {
                console.log('cross mark not found')
            }
        }, 1);
    }


    return (
        <>
            <div className="container">
                <div className="mdl-card mdl-shadow--2dp">
                    <div className="mdl-card__supporting-text">
                        <div className="mdl-stepper-horizontal-alternative">
                            {window.template_dicts.map((item, index) => {
                                return (
                                    <div id="" onClick={() => fetchData(item.title)}
                                         className={"mdl-stepper-step editable-step cursor-pointer " + (item.title === currentLabel ? "active-step" : "")}>
                                        <div className="mdl-stepper-circle">
                                            <span>{index}</span>
                                        </div>
                                        <div className="mdl-stepper-title">{item.label}</div>
                                        <div className="mdl-stepper-bar-left"></div>
                                        <div className="mdl-stepper-bar-right"></div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="multisteps-form mb-5">
                    <div className="d-flex justify-content-center align-items-center px-0">
                        <div className="col-12 col-lg-8 d-flex justify-content-center align-items-center " style={{margin: "0 24px"}}>
                            <div className="card w-90 p-md-3 p-sm-4 border mt-2 pagebuilder_card h-100">
                                <div className="pt-3 px-3" style={{paddingBottom: "24px"}}>
                                    <form id="form-step">
                                        <div id="form-step-1">
                                            <div id="spinner_div" style={{display: 'none'}}><LoadingSpinner/></div>

                                            <div id="form_div" className="App">
                                                {Topcontrols.length > 0 ? (
                                                    <div className="col-12 checkbox-controls"
                                                         id="label_controls">
                                                        {Topcontrols.map(({
                                                                              id,
                                                                              name,
                                                                              text,
                                                                              type,
                                                                              required,
                                                                              label_font_size,
                                                                              label_alignment
                                                                          }, index) => {
                                                            if (type === "label" || type === "toplabel") {
                                                                return (
                                                                    <div key={index} className="row w-100 mx-auto">
                                                                        <div className="p-0" style={{textAlign:label_alignment, marginBottom: "20px"}} >
                                                                            <h3
                                                                                name="label_control"
                                                                                style={{fontSize: label_font_size + "px"}}
                                                                                id={'label_control' + index}
                                                                                type="label"
                                                                                data-text={text}
                                                                                label-font-size={label_font_size}
                                                                                label-alignment= {label_alignment}
                                                                                required={required}
                                                                                className="col-auto m-0 drag-drop-element"
                                                                                htmlFor={'label_control' + index}
                                                                                dangerouslySetInnerHTML={{__html: text}}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            else  if (type === "rich_text_editor" || type === "rich_text_editor_top_control") {
                                                                return (
                                                                    <div className='rich-text-editor-control mt-3' key={index} dangerouslySetInnerHTML={{ __html: text }}
                                                                    />
                                                                );
                                                            }
                                                            return null; // Return null for other cases
                                                        })}
                                                    </div>
                                                ) : null}

                                                {['review', 'decline', 'approve', 'booked'].indexOf(label) < 0 && builder_schema && builder_schema.fields ?
                                                    <BuilderForm builderSchema={builder_schema} onChange={handleChange}
                                                                 showErrors={showErrors} formErrors={formErrors} setFormErrors={setFormErrors}
                                                                 formData={formData}
                                                                 loadDataBtnStatus={buttonClicked}></BuilderForm>
                                                    : <div></div>
                                                }

                                                {
                                                    offerwidget.length > 0 ?
                                                        <div>
                                                            <div className="row my-3">
                                                                <div className="col-12">
                                                                    <iframe title="offer_widget_title" id=""
                                                                            className="shadow" height="500px"
                                                                            width="100%"
                                                                            src={window.offer_doc_url}></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="row g-mb-20 mt-2">

                                                                <div
                                                                    className="input-group name-div mb-4 mt-5 form-group w-50">
                                                                    <label className=""
                                                                           htmlFor='terms-checkbox1'>Enter your name for
                                                                        signatures &nbsp;<span>*</span></label>
                                                                    <input type="text"
                                                                           className="form-control multisteps-form__input signup"
                                                                           placeholder=""
                                                                           name="terms-checkbox1"
                                                                           id="terms-checkbox1"></input>
                                                                </div>
                                                            </div>

                                                        </div> : <div></div>
                                                }
                                                {
                                                    PrequalificationOffer.length > 0 ?
                                                        <div>
                                                            <div className="row my-3">
                                                                <div className="col-12">
                                                                    <iframe title="offer_widget_title" id="" className="shadow" height="500px" width="100%"
                                                                            src={window.prequal_offer_doc_url}></iframe>
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }

                                                {
                                                    multiOffers ?
                                                        <div>
                                                            <div className='container border p-0 pb-5'>
                                                                <div className="modal-header p-3 px-5"
                                                                     style={{"background": "#ebeef4"}}><h4>Bank
                                                                    Account</h4></div>
                                                                <div className="row px-5"
                                                                     style={{textAlign: 'initial'}}>
                                                                    <div className="col-md-6 mb-4" id="MultiLoanOffer"
                                                                         key={0}>
                                                                        <div
                                                                            className="border rounded mt-lg-5 border-primary"
                                                                            data-loan-details="">
                                                                            <div className="card-body mt-2">
                                                                                <div
                                                                                    className="text-bold text-center fs-4 text-primary"
                                                                                    style={{
                                                                                        fontWeight: 'bold',
                                                                                        fontSize: 'larger'
                                                                                    }}>
                                                                                    Bank Offer
                                                                                </div>
                                                                                <div className="mt-3 text-dark mb-lg-4">
                                                                                    <div className='col-lg-12'><strong
                                                                                        style={{float: 'left'}}>APY: </strong><span
                                                                                        className="ms-2">5%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex mt-4">
                                                                                <button style={{
                                                                                    float: 'right',
                                                                                    border: '1px solid'
                                                                                }}
                                                                                        className="btn btn-primary-sm btn-primary  w-90 mx-auto text-capitalize"
                                                                                        type="button"
                                                                                        onClick={(event) => selectofferAgreement(event, 'bank')}>Select
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className='container border p-0 pb-5 personal_loan_div'>
                                                                <div className="modal-header p-3 px-5"
                                                                     style={{"background": "#ebeef4"}}><h4>Personal
                                                                    Loan</h4></div>
                                                                <div className="row px-5"
                                                                     style={{textAlign: 'initial'}}>
                                                                    {window.offer_data ? window.offer_data.map((data, index) => {
                                                                        const data1 = data.replace(/\n\s*/g, '')
                                                                        const final_data = JSON.parse(data1)
                                                                        console.log("data", final_data)


                                                                        if (final_data["is_loan_amount"]) {
                                                                            return null;
                                                                        }

                                                                        let columnSize;
                                                                        if (window.offer_data.length === 1) {
                                                                            columnSize = "col-md-6 mb-4";
                                                                        } else if (window.offer_data.length === 2) {
                                                                            columnSize = "col-md-6 mb-4";
                                                                        } else {
                                                                            columnSize = "col-md-4 mb-4";
                                                                        }

                                                                        return (
                                                                            <>

                                                                                <div className={columnSize}
                                                                                     id="MultiLoanOffer" key={index}>
                                                                                    <div
                                                                                        className="border rounded mt-lg-5 border-primary"
                                                                                        data-loan-details={final_data ? JSON.stringify(final_data) : {}}>
                                                                                        <div className="card-body mt-2">
                                                                                            <div
                                                                                                className="text-bold text-center fs-4 text-primary"
                                                                                                style={{
                                                                                                    fontWeight: 'bold',
                                                                                                    fontSize: 'larger'
                                                                                                }}>
                                                                                                Loan Offer {index + 1}
                                                                                            </div>
                                                                                            <div
                                                                                                className="mt-3 text-dark mb-lg-4">
                                                                                                <div
                                                                                                    className='col-lg-12'>
                                                                                                    <strong
                                                                                                        style={{float: 'left'}}>Approved
                                                                                                        Amount
                                                                                                        : </strong><span
                                                                                                    className="ms-2">{final_data["approved_amount"]}</span>
                                                                                                </div>

                                                                                                <div
                                                                                                    className='col-lg-12'>
                                                                                                    <strong
                                                                                                        style={{float: 'left'}}>APR
                                                                                                        : </strong><span
                                                                                                    className="ms-2">{final_data["output"] && final_data["output"]["apr"] ? parseFloat(final_data["output"]["apr"]) * 100 : ""}%</span>
                                                                                                </div>

                                                                                                <div
                                                                                                    className='col-lg-12'>
                                                                                                    <strong
                                                                                                        style={{float: 'left'}}>Loan
                                                                                                        Term: </strong><span
                                                                                                    className="ms-2">{final_data["output"] && final_data["output"]["loan_term"] ? final_data["output"]["loan_term"] : ""} </span>
                                                                                                </div>

                                                                                                <div
                                                                                                    className='col-lg-12'>
                                                                                                    <strong
                                                                                                        style={{float: 'left'}}>Monthly
                                                                                                        Payment: </strong><span
                                                                                                    className="ms-2">{final_data["output"] && final_data["output"]["monthly_payment"] ? final_data["output"]["monthly_payment"] : ""}</span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex mt-4">
                                                                                            <button style={{
                                                                                                float: 'right',
                                                                                                border: '1px solid'
                                                                                            }}
                                                                                                    className="btn btn-primary-sm btn-primary  w-90 mx-auto text-capitalize"
                                                                                                    data-loan-details={final_data ? JSON.stringify(final_data) : {}}
                                                                                                    onClick={(event) => selectofferAgreement(event, 'loan')}>Select
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }) : ''}
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }

                                                {multiagreements ?
                                                    <div>
                                                        <div className="row mt-3">
                                                            <div className="col-12">
                                                                <iframe id="multiagreements_iframe"
                                                                        className="shadow drag-drop-element"
                                                                        title="Multi agreement" height="500px"
                                                                        width="100%"
                                                                        src={window.pdf_url + '#toolbar=0&navpanes=0&view=fitH'}></iframe>
                                                            </div>
                                                        </div>

                                                        <div className="row g-mb-20 mt-2">

                                                            <div
                                                                className="input-group name-div mb-4 mt-5 form-group w-50">
                                                                <label className=""
                                                                       htmlFor='terms-checkbox1'>Enter your name for
                                                                    signature &nbsp;<span>*</span></label>
                                                                <input type="text"
                                                                       className="form-control multisteps-form__input signup"
                                                                       placeholder=""
                                                                       name="terms-checkbox1"
                                                                       id="terms-checkbox1"></input>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }


                                                {
                                                    agreementwidget ? <>
                                                        <div
                                                            className="d-flex justify-content-center align-items-baseline mt-3 ms-5">
                                                            <div className="col-1 z-index-1">
                                                                <input type="checkbox" id="agreement_checkbox"
                                                                       placeholder=""
                                                                       className='mt-n1 me-3 drag-drop-element'></input>
                                                            </div>
                                                            <div className="col-3 ms-n5">
                                                                <p>{window.agreement_text}</p>
                                                            </div>
                                                            <div className="col-6 ms-n3 d-flex justify-content-between"
                                                                 style={{marginTop: "-3px"}}>
                                                                {window.consent1_url ? <a className='text-primary'
                                                                                          href={window.consent1_url}>{window.consent1_title}</a> : <></>}
                                                                {window.consent2_url ? <a className='text-primary'
                                                                                          href={window.consent2_url}>{window.consent2_title}</a> : <></>}
                                                                {window.consent3_url ? <a className='text-primary'
                                                                                          href={window.consent3_url}>{window.consent3_title}</a> : <></>}
                                                                {window.consent4_url ? <a className='text-primary'
                                                                                          href={window.consent4_url}>{window.consent4_title}</a> : <></>}
                                                                {window.consent5_url ? <a className='text-primary'
                                                                                          href={window.consent5_url}>{window.consent5_title}</a> : <></>}
                                                            </div>
                                                        </div>
                                                        {ischeckbox ?
                                                            <div className='mt-n3 mb-5'><span className='text-danger'>Please accept the terms and conditions</span>
                                                            </div> : <span></span>}</> : <div></div>
                                                }
                                                {
                                                    reviewwidget ?
                                                        <div className="col-12 col-lg-8 mx-auto my-3">
                                                            <div className="">
                                                                <div
                                                                    className="card-body d-flex justify-content-center">
                                                                    <form id="form-step-9" className="form-step"
                                                                          data-process-status="Income"
                                                                          data-process-percent="100">

                                                                        <header className='text-center'>
                                                                            <i className="fa fa-clock fs-18 mr-3"
                                                                               aria-hidden="true"
                                                                               style={{
                                                                                   "color": " #f2ca16",
                                                                                   "font-size": "5rem"
                                                                               }}></i>
                                                                            <h2 className="header-title text-center mt-4">In
                                                                                Review</h2>
                                                                        </header>
                                                                        <div
                                                                            className="row mb-5 text-center d-flex mx-auto">
                                                                            {window.review_text.length > 0 ? window.review_text : 'Your application is in review. We will get back to you.'}
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }
                                                {
                                                    declinewidget ?
                                                        <div className="col-12 col-lg-8 mx-auto my-3">
                                                            <div className="">
                                                                <div
                                                                    className="card-body d-flex justify-content-center">
                                                                    <form id="form-step-9" className="form-step"
                                                                          data-process-status="Income"
                                                                          data-process-percent="100">

                                                                        <header className='text-center'>
                                                                            <i className="fa fa-circle-xmark fs-18 mr-3"
                                                                               aria-hidden="true"
                                                                               style={{
                                                                                   "color": "red",
                                                                                   "font-size": "5rem"
                                                                               }}></i>

                                                                            <h2 className="header-title text-center mt-4">Declined</h2>
                                                                        </header>
                                                                        <div
                                                                            className="row mb-5 text-center d-flex mx-auto">
                                                                            {window.decline_text.length > 0 ? window.decline_text : 'Sorry! Your application is declined.'}
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }
                                                {
                                                    bookedwidget ?
                                                        <div className="col-12 col-lg-8 mx-auto my-3">
                                                            <div className="">
                                                                <div
                                                                    className="card-body d-flex justify-content-center">
                                                                    <form id="form-step-9" className="form-step"
                                                                          data-process-status="Income"
                                                                          data-process-percent="100">

                                                                        <header className='text-center'>
                                                                            <i className="fa fa-clock fs-18 mr-3"
                                                                               aria-hidden="true"
                                                                               style={{
                                                                                   "color": " #f2ca16",
                                                                                   "font-size": "5rem"
                                                                               }}></i>

                                                                            <h2 className="header-title text-center mt-4 d-flex mx-auto">Booked</h2>
                                                                        </header>
                                                                        <div className="row mb-5 text-center">
                                                                            Your application is Booked
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }
                                                {
                                                    approvewidget ?
                                                        <div className="col-12 col-lg-8 mx-auto my-3">
                                                            <div className="">
                                                                <div
                                                                    className="card-body d-flex justify-content-center">
                                                                    <form id="form-step-9" className="form-step"
                                                                          data-process-status="Income"
                                                                          data-process-percent="100">

                                                                        <header className='text-center'>
                                                                            <i className="fa fa-check-circle fs-18 mr-3"
                                                                               aria-hidden="true"
                                                                               style={{
                                                                                   "color": "green",
                                                                                   "font-size": "5rem"
                                                                               }}></i>
                                                                            <h2 className="header-title text-center mt-4">Congrats!</h2>
                                                                        </header>
                                                                        <div
                                                                            className="row mb-5 text-center d-flex mx-auto">
                                                                            {window.approve_text.length > 0 ? window.approve_text : 'Your application is in review. We will get back to you.'}
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div> : <div></div>
                                                }
                                                {
                                                    loanoffers ?
                                                        <div className='container'>
                                                            <div className="row" style={{textAlign: 'initial'}}>


                                                                {window.loan_offers && window.loan_offers['Loan Details'] ? Object.keys(window.loan_offers['Loan Details'][0]).map((offerKey, index) => {

                                                                    const offerDetails = window.loan_offers['Loan Details'][0] && window.loan_offers['Loan Details'][0][offerKey] ? window.loan_offers['Loan Details'][0][offerKey]['Loan Details'] : {};

                                                                    const loan_details = window.loan_offers['Loan Details'][0][offerKey] ? (window.loan_offers['Loan Details'][0][offerKey]) : {}

                                                                    let columnSize;
                                                                    if (Object.keys(window.loan_offers['Loan Details'][0]).length === 1) {
                                                                        columnSize = "col-md-12 mb-4";
                                                                    } else if (Object.keys(window.loan_offers['Loan Details'][0]).length === 2) {
                                                                        columnSize = "col-md-6 mb-4";
                                                                    } else {
                                                                        columnSize = "col-md-4 mb-4";
                                                                    }

                                                                    return (
                                                                        <>

                                                                            <div className={columnSize} id="Loanoffers"
                                                                                 key={index}>
                                                                                <div
                                                                                    className="border rounded mt-lg-5 border-primary"
                                                                                    data-loan-details={window.loan_offers['Loan Details'][0][offerKey] ? JSON.stringify(window.loan_offers['Loan Details'][0][offerKey]) : {}}>
                                                                                    <div className="card-body mt-2">
                                                                                        <div
                                                                                            className="text-bold text-center fs-4 text-primary"
                                                                                            style={{
                                                                                                fontWeight: 'bold',
                                                                                                fontSize: 'larger'
                                                                                            }}>
                                                                                            Loan Offer {index + 1}
                                                                                        </div>
                                                                                        <div
                                                                                            className="mt-3 text-dark mb-lg-4">
                                                                                            <div className='col-lg-12'>
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Term
                                                                                                    Length
                                                                                                    : </strong><span
                                                                                                className="ms-2">{offerDetails['Term Length']} months</span>
                                                                                            </div>

                                                                                            <div className='col-lg-12'>
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Loan
                                                                                                    Limit: </strong><span
                                                                                                className="ms-2">{offerDetails['Loan Limit'][Object.keys(offerDetails['Loan Limit'])[0]]}</span>
                                                                                            </div>

                                                                                            <div className='col-lg-12'>
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Interest
                                                                                                    Rate
                                                                                                    : </strong><span
                                                                                                className="ms-2">{offerDetails['Interest Rate'][Object.keys(offerDetails['Interest Rate'])[0]]}</span>
                                                                                            </div>

                                                                                            <div className='col-lg-12'>
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Procedure
                                                                                                    Amount : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Procedure Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Down
                                                                                                    Payment Amount
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Down Payment Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Financed
                                                                                                    Amount : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Financed Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Principal
                                                                                                    Amount : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Principal Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Interest
                                                                                                    Amount : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Interest Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Convenience
                                                                                                    Fee : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Convenience Fee"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Total
                                                                                                    Loan Amount
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Loan Details"]["Total Loan Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Payment
                                                                                                    Count : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Scheduled Payment Details"]["Payment Count"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Payment
                                                                                                    Frequency
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Scheduled Payment Details"]["Payment Frequency"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Payment
                                                                                                    Amount : </strong>
                                                                                                <span className="ms-2"
                                                                                                      style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Scheduled Payment Details"]["Payment Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Loan
                                                                                                    Payment Count
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Loan Payment Count"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Loan
                                                                                                    Payment Amount
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Loan Payment Amount"] : ""} </span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-lg-12 text-left">
                                                                                                <strong
                                                                                                    style={{float: 'left'}}>Total
                                                                                                    Initial Cost
                                                                                                    : </strong> <span
                                                                                                className="ms-2"
                                                                                                style={{fontWeight: '100'}}> {Object.keys(loan_details).length !== 0 ? loan_details["Initial Cost Details"]["Total Initial Cost"] : ""} </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex mt-4">
                                                                                        <button style={{
                                                                                            float: 'right',
                                                                                            border: '1px solid'
                                                                                        }}
                                                                                                className="btn btn-primary-sm
                                                                                                btn-primary  w-90 mx-auto text-capitalize"
                                                                                                onClick={(event) => selectCard(index + 1, event)}>Select
                                                                                        </button>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                }) : ''}
                                                            </div>
                                                        </div> : <div></div>
                                                }
                                                {
                                                    controls.length > 0 ?
                                                        <div
                                                            className="col-12 col-lg-12 mx-auto mb-5 checkbox-controls p-3"
                                                            id="checkbox_controls">
                                                                {/* eslint-disable-next-line */}
                                                            {
                                                                controls.map(({
                                                                                  id,
                                                                                  name,
                                                                                  text,
                                                                                  type,
                                                                                  required,
                                                                                  label_font_size,
                                                                                  request_doc_id,
                                                                                  label_alignment
                                                                              }, index) => {

                                                                    const uniqueId = generateRandomId();
                                                                    if (type === "document_upload") {
                                                                        const matchingDocuments = documentControl.filter(docItem => docItem.request_doc_id === request_doc_id);
                                                                        return (
                                                                            <div className="row mt-3 mb-5" key={uniqueId}>
                                                                                <div
                                                                                    className="row mt-3 justify-content-center">
                                                                                    {matchingDocuments.map(({
                                                                                                                id,
                                                                                                                name,
                                                                                                                description
                                                                                                            }, docIndex) => (
                                                                                        <div className="row mt-3"
                                                                                             key={`${uniqueId}_${docIndex}`}>


                                                                                            <div className="row">
                                                                                                <div
                                                                                                    className="col-8 ps-4 text-start">
                                                                                                    <label data-id={id}
                                                                                                           htmlFor={id}
                                                                                                           className="">
                                                                                                        <div
                                                                                                            className="text-dark text-bold">
                                                                                                            {name}
                                                                                                            <span
                                                                                                                className="">
                                                                <i className="fa-solid fa-circle-question doc-help ms-2"
                                                                   data-bs-toggle="tooltip" data-bs-placement="right"
                                                                   title={description}></i>
                                                            </span>
                                                                                                            <span hidden
                                                                                                                  className="">
                                                                <i className="fa-regular fa-circle-check text-success ms-2"></i>
                                                            </span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="upload_complete">
                                                                                                            <span
                                                                                                                className="file_name"
                                                                                                                name={"request_doc_id" + id.toString()}></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="file-upload-progress">
                                                                                                            <div
                                                                                                                className="file-upload-progress-value"></div>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-4 text-end">
                                                                                                    <label data-doc-id={id} htmlFor={id} className="upload-btn">
                                                                                                        <p style={{color: "#6f4bd1", textDecoration: "underline", fontSize: "1rem", fontWeight: "bold", marginBottom: 0, padding: "0.2em 0.4em", cursor: "pointer"}}>Upload</p>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <input type="file"
                                                                                                       onChange={upload_request_doc}
                                                                                                       hidden
                                                                                                       data-doc-id={id}
                                                                                                       id={id}/>
                                                                                                <hr className="bg-dark"/>
                                                                                            </div>


                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    else if (type === "checkbox") {

                                                                        return (
                                                                            <div className="mt-1 ">
                                                                                <label className='row'
                                                                                       style={{textAlign: 'left'}}>
                                                                                    <input type="checkbox"
                                                                                           id={'checkbox_control_' + index}
                                                                                           placeholder=""
                                                                                           name={'checkbox_control_' + index}
                                                                                           required={required}
                                                                                           data-text={text}
                                                                                           className='drag-drop-element col-2'
                                                                                           style={{
                                                                                               "height": "20px",
                                                                                               'width': '5%',
                                                                                           }}></input>
                                                                                    <span className="col-10 ms-1 p-0" style={{fontSize: "0.9rem"}}
                                                                                          dangerouslySetInnerHTML={{__html: text}}
                                                                                          htmlFor={'checkbox_control_' + index}></span>
                                                                                </label>


                                                                            </div>
                                                                        );
                                                                    } else if (type === "label") {
                                                                        return (
                                                                            <div className="mb-1" style={{textAlign:label_alignment}}>
                                                                            <span
                                                                                name="label_control"
                                                                                style={{fontSize: label_font_size + "px"}}
                                                                                id={'label_control' + index}
                                                                                type="label"
                                                                                data-text={text}
                                                                                label-font-size={label_font_size}
                                                                                label-alignment={label_alignment}
                                                                                required={required}
                                                                                className="col-lg-10 m-0 drag-drop-element ms-3"
                                                                                htmlFor={'label_control' + index}
                                                                                dangerouslySetInnerHTML={{__html: text}}></span>

                                                                            </div>
                                                                        );
                                                                    }
                                                                    else  if (type === "rich_text_editor" || type === "rich_text_editor_control") {
                                                                        return (
                                                                            <div className='rich-text-editor-control mt-3' key={index} dangerouslySetInnerHTML={{ __html: text }}
                                                                            />
                                                                        );
                                                                    }
                                                                    else {
                                                                        // If none of the conditions match, return null to avoid the map error
                                                                        return null;
                                                                    }
                                                                })}
                                                        </div>
                                                        :
                                                        <div></div>
                                                }
                                                {
                                                    flinkwidget ? <div></div>
                                                        :
                                                        ['review', 'decline', 'approve', 'booked'].indexOf(label) < 0 && (loanoffers || multiOffers) ?
                                                            <div className='d-flex mx-auto mt-5' style={{
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <button className="btn btn-primary-sm btn-primary" type="button">
                                                                    Continue
                                                                </button>
                                                                <button className='btn btn-error btn-error-sm ms-3' type="button">Decline All
                                                                </button>
                                                            </div>
                                                            :
                                                            ['review', 'decline', 'approve', 'booked'].indexOf(label) < 0 ?
                                                                <div className='d-flex'>
                                                                    <button className="btn btn-primary-sm btn-primary mx-auto mt-4" type="button">
                                                                        Continue
                                                                    </button>
                                                                </div>
                                                                : (
                                                                    ['approve', 'booked'].indexOf(label) >= 0 ? (
                                                                        <div className='d-flex'>
                                                                            <button
                                                                                className="btn btn-primary-sm btn-primary mx-auto mt-4"
                                                                                type="button">
                                                                                Go to Application Portal
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='d-flex'>
                                                                            <button
                                                                                className="btn btn-primary-sm btn-primary d-flex mx-auto"
                                                                                type="button">
                                                                                Done
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                )}
                                            </div>

                                            <div className="modal fade come-from-modal right" id="modal_control_doc">
                                                <div className="modal-dialog"
                                                     style={{"max-width": "50vw", height: "700px"}}>
                                                    <div className="modal-content p-0 m-0" style={{height: "100%"}}>

                                                        <iframe id="control_doc_iframe" title='modal_control_doc_iframe'
                                                                src="" height="100%" width="100%"></iframe>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );


}

export default PreviewWizard;