import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import AuthBackground from '../ReusableComponents/AuthBackground/AuthBackground';
import CardComponent from '../ReusableComponents/CardComponent/CardComponent';
import IdCard from '../ReusableComponents/IdCard/IdCard';
import Spinner from '../ReusableComponents/Spinner/Spinner';
import Notification from '../ReusableComponents/Notification/Notification';
import './SignupForm.css';
import { saveToSessionStorage, getFromSessionStorage} from '../Utils/utils';


function formatPhoneNumber(phone) {
  return phone?.replace(/\D/g, '');
}

const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue || '';
};

const SignupForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    country_code: '1',
    country: "us"
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  // State for notification
  const [notification, setNotification] = useState({
    message: '',
    type: '',
    visible: false,
  });

  const [loading, setLoading] = useState(false);

  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);

  const [termsAccepted, setTermsAccepted] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };


  // Display notification
  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, visible: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValue(name, value, { shouldValidate: true });
  };


  useEffect(() => {
    if (formData.first_name && formData.last_name && formData.company_name && formData.email && formData.mobile && formData.country_code && formData.country) {
      saveToSessionStorage('signupFormData', {
        first_name: formData.first_name,
        last_name: formData.last_name,
        company_name: formData.company_name,
        email: formData.email,
        mobile: formatPhoneNumber(formData.mobile),
        country_code: formData.country_code,
        country: formData.country
      });
    }
  }, [formData]);


  // Function to handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Manual client-side validation using react-hook-form errors
      const csrfToken = getCookie('csrftoken');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      };

      const input = document.querySelector('#mobile_id');
      const mobileNumber = window.intlTelInputGlobals.getInstance(input);

      if (!mobileNumber.isValidNumber()) {
        // showNotification('Invalid mobile number. Please check the format and country code.', 'error');
        setIsValidMobileNumber(false);
        setLoading(false);
        return;
      } else {
        setIsValidMobileNumber(true);
      }

      // Add necessary data to FormData
      const formDataObj = {
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        email: data.email,
        mobile: formatPhoneNumber(data.mobile),
        country_code: formData.country_code,
        country: formData.country,
      };

      // Submit the form data using axios
      const response = await axios.post('/signup/v1/signup/', formDataObj,
        config
      );

      // Handle response
      if (response.status === 201) {
        showNotification(response.data.message, "success");
        setFormData(formDataObj);
        saveToSessionStorage('session_id', response.data.session_id);
        navigate('/email_otp');
      }
    } catch (error) {
      if (error.response.status === 400) {
        showNotification('Email exists. Try using a different email.', 'error');
      } else {
        showNotification('API request failed. Please try again.', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const autofillFields = document.querySelectorAll('input');
    autofillFields.forEach((field) => {
      if (field.value) {
        setValue(field.name, field.value, { shouldValidate: true });
      }
    });
  }, [setValue]);

  // Effect to initialize intl-tel-input
  useEffect(() => {
    const input = document.querySelector('#mobile_id');
    const mobileNumber = window.intlTelInput(input, {
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.18/build/js/utils.js',
      strictMode: true,
      separateDialCode: true,
      autoPlaceholder: "polite",
      formatOnDisplay: true,
      autoPlaceholder: 'Mobile Number',
      containerClass: 'w-100',
    });

    // Retrieve and apply country code from session storage
    const storedCountryCode = sessionStorage.getItem('countryCode');
    const storedCountry = sessionStorage.getItem('country');
    if (storedCountryCode && storedCountry) {
      const countryData = mobileNumber?.p.find(country => (country.dialCode === storedCountryCode) && (country.iso2 === storedCountry));
      if (countryData) {
        mobileNumber.setCountry(countryData.iso2);
      }
    }

    // Format number on input change
    input.addEventListener('input', () => {
      const formattedNumber = window.intlTelInputUtils.formatNumber(
        input.value,
        mobileNumber.getSelectedCountryData().iso2,
        window.intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      const updatedMobile = formattedNumber.replace(new RegExp(`^\\+?${mobileNumber.getSelectedCountryData().dialCode}\\s*`), '').trim();
      setFormData(prevFormData => ({
        ...prevFormData,
        mobile: formatPhoneNumber(updatedMobile),
        country_code: mobileNumber.getSelectedCountryData().dialCode,
        country: mobileNumber.getSelectedCountryData().iso2,
      }));

      setValue('mobile', updatedMobile, { shouldValidate: true });
    });

    // Handle country change event
    input.addEventListener('countrychange', () => {

      const updatedCountryCode = mobileNumber.getSelectedCountryData().dialCode;
      const updatedCountry = mobileNumber.getSelectedCountryData().iso2;
      sessionStorage.setItem('countryCode', updatedCountryCode);
      sessionStorage.setItem('country', updatedCountry);
      const mobileWithoutCountryCode = input.value.replace(`+${updatedCountryCode}`, '').trim();
      setFormData(prevFormData => ({
        ...prevFormData,
        mobile: formatPhoneNumber(mobileWithoutCountryCode),
        country_code: updatedCountryCode,
        country: updatedCountry,
      }));

      const formattedNumber = window.intlTelInputUtils.formatNumber(
        input.value,
        updatedCountry,
        window.intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      const cleanedNumber = formattedNumber.replace(new RegExp(`^\\+?${updatedCountryCode}\\s*`), '');
      input.value = cleanedNumber;

      setValue('mobile', cleanedNumber, { shouldValidate: true });
    });

    return () => {
      mobileNumber.destroy();
    };
  }, [setValue]);

  useEffect(() => {
    const savedFormData = getFromSessionStorage('signupFormData');
    if (savedFormData) {
      Object.entries(savedFormData).forEach(([key, value]) => {
        setValue(key, value, { shouldValidate: true });
      });
      setFormData(savedFormData);
    }
  }, [setValue]);


  return (
    <AuthBackground>
      <div className="main-content min-vh-100 d-flex align-items-center">
        <div className="container-fluid">
          <div className="d-flex flex-wrap justify-content-evenly">

            {/* Id Card */}
            <div className="d-flex justify-content-center align-items-center col-sm-8 col-lg-5 col-xl-4 col-11">
              <IdCard formData={formData} />
            </div>

            {/* Form Card - Signup Form */}
            <CardComponent className="mt-0 form-card">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center flex-column text-center bg-white gap-2 card-header">
                  <h3 className="main-title text-center mb-0">Create your account</h3>
                  <p className="sub-heading text-center mb-0 fw-normal" style={{ opacity: '0.8' }}>
                    Get started with your business details
                  </p>
                </div>
                <div className="">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="text-start" id="form-signup">
                      {/* Form Fields */}
                      {/* First Name */}
                      <div className="input-group mb-4">
                        <label>First Name <span>*</span></label>
                        <input
                          type="text"
                          maxLength="16"
                          className={`form-control p-2 border rounded ${(errors.first_name) ? 'is-invalid' : ''}`}
                          placeholder="First name"
                          {...register('first_name', { required: 'First name is required', maxLength: {
                            value: 15,
                            message: 'First name cannot exceed 15 letters.',
                          } })}
                          onChange={handleInputChange}
                        />
                        {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                      </div>
                      {/* Last Name */}
                      <div className="input-group mb-4">
                        <label>Last Name <span>*</span></label>
                        <input
                          type="text"
                          maxLength="16"
                          className={`form-control p-2 border rounded ${(errors.last_name) ? 'is-invalid' : ''}`}
                          placeholder="Last name"
                          {...register('last_name', { required: 'Last name is required', maxLength: {
                            value: 15,
                            message: 'Last name cannot exceed 15 letters.',
                          } })}
                          onChange={handleInputChange}
                        />
                        {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                      </div>
                      {/* Company Name */}
                      <div className="input-group mb-4">
                        <label>Company Name <span>*</span></label>
                        <input
                          maxLength="61"
                          type="text"
                          className={`form-control p-2 border rounded ${(errors.company_name) ? 'is-invalid' : ''}`}
                          placeholder="Company name"
                          {...register('company_name', { required: 'Company name is required', maxLength: {
                            value: 60,
                            message: 'Company name cannot exceed 60 letters.',
                          } })}
                          onChange={handleInputChange}
                        />
                        {errors.company_name && <p className="text-danger">{errors.company_name.message}</p>}
                      </div>
                      {/* Email */}
                      <div className="input-group mb-4">
                        <label>Company Email <span>*</span></label>
                        <input
                          maxLength="51"
                          type="email"
                          className={`form-control p-2 border rounded ${(errors.email) ? 'is-invalid' : ''}`}
                          placeholder="Email"
                          {...register('email', {
                            required: 'Email is required',
                            maxLength: {
                              value: 50,
                              message: 'Email cannot exceed 50 characters.',
                            },
                            pattern: {
                              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: 'Enter a valid company email (e.g., example@lendapi.com)',
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                      </div>
                      {/* Mobile */}
                      <div className="input-group mb-4">
                        <label>Mobile <span>*</span></label>
                        <input
                          id="mobile_id"
                          type="text"
                          className={`form-control p-2 border rounded ${(errors.mobile) ? 'is-invalid' : ''}`}
                          placeholder="Mobile number"
                          {...register('mobile',
                          { required: 'Mobile number is required',
                          // pattern: {
                          //   value: /^[0-9]{10}$/,
                          //   message: 'Invalid mobile number, must be 10 digits.',
                          // },
                          // maxLength: {
                          //   value: 10,
                          //   message: 'Mobile number cannot exceed 10 digits.',
                          // },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.mobile && <p className="text-danger">{errors.mobile.message}</p>}
                        {!isValidMobileNumber && <p className="text-danger">Invalid mobile number. Please check the format and country code.</p>}
                      </div>

                      {/* Terms and Conditions */}
                      <div className="d-flex flex-column flex-wrap gap-1 align-items-center justify-content-center w-100">
                        <div className="form-check d-flex align-items-center justify-content-center">
                          <input
                            className="form-check-input"
                            checked={termsAccepted}
                            type="checkbox"
                            id="terms-checkbox"
                            {...register('terms', { required: 'You must agree to the terms and conditions' })}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label ms-2 mb-0" htmlFor="terms-checkbox">
                            I agree to the <a href="https://lendapi.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://lendapi.com/privacy-notice" target="_blank" rel="noreferrer">Privacy Notice</a>
                          </label>
                        </div>
                        {errors.terms && <p className="text-danger">{errors.terms.message}</p>}
                      </div>
                      {/* Submit Button */}
                      <button type="submit" disabled={loading} className="btn btn-primary btn-md btn-block my-3 w-100 mt-4" id="submit">
                        {loading ? "Creating Account..." : "Create Account"}
                      </button>

                      <div className="mt-2">
                        <p className="text-sm fw-normal text-center mb-0">
                          Already have an account?
                          <Link to="/login" className="text-info ms-1">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper">
          <Spinner animation="border" />
        </div>
      )}

      <Notification
        message={notification.message}
        type={notification.type}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </AuthBackground>
  );
};

export default SignupForm;
